import { StompService, StompClient } from '../../main/services/stompService';
import { Observable, of } from 'rxjs';
import { InquireContractsRequest } from '../models/contracts';
import RestService from '../../main/services/restService';

export default class ContractsService extends StompService {
  private restService: RestService;

  constructor() {
    super(StompClient);
    this.restService = new RestService();
  }

  public sendContractsInquiry(
    message: InquireContractsRequest
  ): Observable<any> {
    return of(
      this.sendMessage('/app/im/contracts', JSON.stringify(message))
    );
  }

  public subscribeContractMapWs(): Observable<any> {
    return this.subscribe('/user/topic/im/contracts/map');
  }

  public subscribeContractsStateChange(): Observable<any> {
    return this.subscribe('/user/topic/im/sub/contracts');
  }

  public subscribePersistentContracts(): Observable<any> {
    return this.subscribe('/user/topic/im/sub/contracts/persistent');
  }

  public subscribeContractMap(): Observable<any> {
    return this.restService.restFetch('/data/contracts');
  }
}
