import { IFavorite } from '../models/favorite';
import { LogAction } from '../../../main/middlewares/logger';
import { LogLevel } from '../../logger/models/logger';
import { I18n } from 'react-redux-i18n';

export enum ActionTypes {
  LOAD = '[Favorite] Load',
  TOGGLE = '[Favorite] Toggle',
  TOGGLE_FAVORITE_VISIBILITY = '[Favorite] Set visible/hidden favorite in sidemenu'
}

export interface FavoriteLoadAction {
  type: ActionTypes.LOAD;
  payload: IFavorite[];
}

export interface FavoriteToggleAction extends LogAction {
  type: ActionTypes.TOGGLE;
  payload: { favorite: IFavorite, isFavorite: boolean };
}

export function toggleFavoriteVisibility(favoriteId: string, isVisible: boolean): ViewToggleFavoriteVisibilityAction {
  return {
    type: ActionTypes.TOGGLE_FAVORITE_VISIBILITY,
    payload: { favoriteId, isVisible }
  };
}

export function load(favorites: IFavorite[]): FavoriteLoadAction {
  return {
    type: ActionTypes.LOAD,
    payload: favorites
  };
}

export function toggleFavorite(favorite: IFavorite, isFavorite: boolean): FavoriteToggleAction {
  const entities = (favorite.entities as any[]).map(entity => entity.instrumentTitle || entity.title).join(', ');
  return {
    type: ActionTypes.TOGGLE,
    payload: { favorite, isFavorite },
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t(isFavorite ? 'favorite.log.add' : 'favorite.log.remove', { name: favorite.name, entities })
  };
}

export interface ViewToggleFavoriteVisibilityAction {
  type: ActionTypes.TOGGLE_FAVORITE_VISIBILITY;
  payload: { favoriteId: string, isVisible: boolean };
}

export type Action =
  | FavoriteLoadAction
  | FavoriteToggleAction
  | ViewToggleFavoriteVisibilityAction;