import { Action } from 'redux';
import { Store } from 'redux';
import { State } from '../reducers/rootReducer';
import * as Authentication from '../../authentication/actions/authentication';
import * as Connection from '../../authentication/actions/connection';
import { clear, sendLogToServer } from '../../shared/logger/actions/logger';
import { getClientLogLevel } from '../../requests/selectors/requests';
import { convertLogToNotification } from '../../shared/logger/helper/logger';

export interface LogAction extends Action {
    type: any;
    logLevel: any;
    logMessage: string;
    confirmationRequired?: boolean;
}

export const loggerMiddleWare = (store: Store<State>) => (next: any) => (action: any) => {
    if (action.type === Connection.ActionTypes.HEARTBEAT_RECEIVED || action.type === Connection.ActionTypes.HEARTBEAT) {
        // do nothing
        // console.log(action, store.getState());
    } else if (action.type === Authentication.ActionTypes.AUTHENTICATION_REQUEST) {
        console.log({type: Authentication.ActionTypes.AUTHENTICATION_REQUEST, payload: {...action.payload, password: 'password is hidden'}}, store.getState());
    } else if (action.type === Connection.ActionTypes.CONNECTION_START) {
        console.log({...action, payload: {...action.payload, password: 'password is hidden'}}, store.getState());
    } else {
        console.log(action, store.getState());
    }
    return next(action);
};

export const serverLoggingMiddleware: any =  (store: Store<State>) => (next: any) => (action: any) => {
    if (action.logLevel) {
        // Clear log
        if (action.type === Authentication.ActionTypes.AUTHENTICATION_REQUEST) {
            store.dispatch(clear());
        }
        store.dispatch(sendLogToServer({
            message: action.logMessage,
            level: action.logLevel
        }));
        const clientLogLevel = getClientLogLevel(store.getState());
        if (action.logLevel <= clientLogLevel) {
            store.dispatch(convertLogToNotification(action));
        }
    }
    return next(action);
};