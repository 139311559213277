import { config } from '../../main/config';
import {
  ITable,
  ITableColumn,
  ITableSort,
  TableAction,
  ITableSearchTag,
  ITableRow
} from '../../shared/ui/models/table';
import { ComponentType } from '../../shared/ui/models/component';
import { BulkOrderRowPopover } from '../components/BulkOrderPopover';

export class BulkTable implements ITable {
  static index = 1;

  id: string;
  parentId: string;
  type: ComponentType = ComponentType.BulkOrder;
  actions: TableAction[] = [TableAction.SEARCH];
  columns: ITableColumn[] = [];
  sorting: ITableSort[] = [];
  hiddenColumnNames: string[] = [];
  hiddenRows: any[] = [];
  tags: ITableSearchTag[] = [];
  showHeadlines: boolean = true;
  showingHidden: boolean = false;
  cellActionMapping = {};
  cellActions = {};
  cellActionClick = '';
  filters = {};
  pinnedRows = [];

  constructor(parentId: string, customId?: string) {
    this.id = customId
      ? customId
      : new Date().getUTCMilliseconds().toString() + BulkTable.index++;
    this.parentId = parentId;
    this.columns = config.ui.basketOrderTable.columns;
    this.hiddenColumnNames = config.ui.basketOrderTable.hiddenColumnNames;
    this.sorting = config.ui.basketOrderTable.defaultSorting;
    this.cellActionMapping = config.ui.basketOrderTable.cellActions;
    this.cellActions = {
      deleteOrder: (rowIndex: string, columnKey: string, tableComponent: any, row: ITableRow, event: any) => {
        tableComponent.props.onClickCell(rowIndex, columnKey);
        event.stopPropagation();
      },
      showPopover: (rowIndex: string, columnKey: string, tableComponent: any, row: ITableRow, event: any) => {
        tableComponent.hidePopover();
        tableComponent.showPopover(BulkOrderRowPopover, row, event);
        event.stopPropagation();
      },
    };
    this.cellActionClick = config.ui.basketOrderTable.cellActionClick;
  }
}
