import * as React from 'react';
import { State } from '../../../main/reducers/rootReducer';
import { connect } from 'react-redux';
import NotificationsConfigFormComponent from './NotificationsConfigForm';
import { getNotificationConfig } from '../selectors/notifications';
import { saveConfig } from '../actions/notifications';
import ModalWindow from '../../modalWindow/components/modalWindow';

export interface NotificationsConfigProps {
  notificationsConfig: any;
  modalVisible: boolean;
  saveConfig: (config: any) => void;
  closeModal: () => void;
}

export interface NotificationsConfigState {
  modalVisible: boolean;
}

export class NotificationsConfigModalComponent extends React.Component<
  NotificationsConfigProps,
  NotificationsConfigState
  > {
  constructor(props: NotificationsConfigProps) {
    super(props);
    this.state = {
      modalVisible: props.modalVisible
    };
    this.hideModal = this.hideModal.bind(this);
  }

  hideModal() {
    this.props.closeModal();
  }

  handleSubmit(settings: any) {
    this.props.saveConfig(settings);
    this.hideModal();
  }

  render() {
    return (
      <div className="meet-notifications__modal">
        <ModalWindow
          id="notifications"
          onModalClose={() =>
            this.hideModal()
          }
          isOpen={this.props.modalVisible}
          title={'notifications.title'}
        >
          <NotificationsConfigFormComponent
            config={this.props.notificationsConfig}
            onSubmit={settings => this.handleSubmit(settings)}
          />
        </ModalWindow>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  notificationsConfig: getNotificationConfig(state)
});

const mapDispatchToProps = {
  saveConfig: saveConfig
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsConfigModalComponent);
