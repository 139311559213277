import { State } from '../../main/reducers/rootReducer';
import { createSelector } from 'reselect';
import { IDock } from '../../shared/dock/models/dock';

export const getDashboardGridState = (state: State) => state.dashboard.grid;

export const getDashboardQuadrantsState = createSelector(
  getDashboardGridState,
  s => s.quadrants
);

export const getDashboardQuadrantsIds = createSelector(
  getDashboardQuadrantsState,
  s => s.ids
);

export const getDashboardQuadrantsEntities = createSelector(
  getDashboardQuadrantsState,
  s => s.entities
);

export const getDashboardQuadrants = createSelector(
  [getDashboardQuadrantsIds, getDashboardQuadrantsEntities],
  (ids, entities) => {
    return ids.map(id => entities[id]);
  }
);

export const getDocksToDashboardQuadrants = createSelector(
  [getDashboardQuadrantsIds, getDashboardQuadrantsEntities],
  (ids, entities) => {
    
    return ids.reduce((acc: {[id: string]: IDock | undefined}, id) => {
      acc[id] = entities[id].dock;
      return acc;
    }, {});
  }
);

export const getDashboardQuadrantsIsLocked = createSelector(
  getDashboardGridState,
  s => s.locked
);

export const getCollidingQuadrants = createSelector(
  getDashboardGridState,
  s => s.quadrants.ids.map(id => s.quadrants.entities[id]).filter(q => q.isColliding)
);

export const getDashboardQuadrantAxises = createSelector(
  getDashboardGridState,
  s => s.axises
);

export const getQuadrantLayout = createSelector(
  getDashboardGridState,
  s => s.layout
);

export const getActiveDashboarQuadrants = createSelector(
  getDashboardGridState,
  s => s.quadrants.ids.filter(id => s.layout?.indexOf(id) > -1).map(id => s.quadrants.entities[id])
);

export const getAxisX = createSelector(
  getDashboardGridState,
  s => s.axises.x
);

export const getAxisY = createSelector(
  getDashboardGridState,
  s => s.axises.y
);

export const getQuadrantGridActive = createSelector(
  getDashboardGridState,
  s => s.active
);

// export const getDocksToDashboardQuadrants = createSelector(
//   getDashboardGridState,
//   s => {
//     const quadrantToDock = s.quadrants.ids.map((key: string) => s.quadrants.entities[key]).reduce((acc: {[key: string]: Dock}, q) => {
//       acc[q.id] = q.dock;
//       return acc;
//     }, {});
//     return quadrantToDock;
//   }
// );
