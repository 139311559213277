
import { getUploadedBulkOrders, getUploadErrors, isParsingCurrentlyInProgess } from '../selectors/bulkOrders';
import { connect } from 'react-redux';
import { State } from '../../main/reducers/rootReducer';
import { BulkErrorsTable } from '../components/BulkErrorsTable';

const mapStateToProps = (state: State) => ({
    bulkOrders: getUploadedBulkOrders(state),
    errors: getUploadErrors(state),
    isParsing: isParsingCurrentlyInProgess(state)
});

const mapDispatchToProps = {

};

export default connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
)(BulkErrorsTable);