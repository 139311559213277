import { State } from '../../../main/reducers/rootReducer';
import { createSelector } from 'reselect';

export const getPasswordState = (state: State) => state.password;

export const getMessage = createSelector(
    getPasswordState,
    s => s.message
);

export const hasFailed = createSelector(
    getPasswordState,
    s => s.failed
);

export const getPasswordComplexity = createSelector(
    getPasswordState,
    s => s.passwordComplexity
);

export const getPasswordComplexityMessage = createSelector(
    getPasswordState,
    s => s.passwordComplexityErrorMessage
);