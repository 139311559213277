import { LogAction } from '../../main/middlewares/logger';
import { LogLevel } from '../../shared/logger/models/logger';
import { I18n } from 'react-redux-i18n';
import { BusinessUnit } from '../models/businessUnits';

export enum ActionTypes {
    LOAD_BUSINESS_UNITS_SUCCESS = '[BusinessUnits] Load success',
    LOAD_BUSINESS_UNITS_FAILURE = '[BusinessUnits] Load failure',
}

export interface BusinessUnitsLoadActionSuccess extends LogAction {
    type: ActionTypes.LOAD_BUSINESS_UNITS_SUCCESS;
    payload: {[key: string]: BusinessUnit};
}

export interface BusinessUnitLoadActionFailure extends LogAction {
    type: ActionTypes.LOAD_BUSINESS_UNITS_FAILURE;
    payload: string;
}

export type Action =
  | BusinessUnitLoadActionFailure
  | BusinessUnitsLoadActionSuccess;

export function businessUnitsLoadSuccess(payload: {[key: string]: BusinessUnit}) {
    let me = I18n.t('businessUnit.log.Business units successfully loaded');
    return {
        type: ActionTypes.LOAD_BUSINESS_UNITS_SUCCESS,
        payload: payload,
        logLevel: LogLevel.DEBUG,
        logMessage: me
    };
}

export function businessUnitsLoadFailure(error: Error) {
    return {
        type: ActionTypes.LOAD_BUSINESS_UNITS_FAILURE,
        payload: error,
        logLevel: LogLevel.ERROR,
        logMessage: I18n.t('businessUnit.log.loadFailure')
    };
}