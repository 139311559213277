import { State } from '../../main/reducers/rootReducer';
import { createSelector } from 'reselect';
import { argsSelectorCreator } from '../../shared/utils/selectors/agrSelector';
import { ITable } from '../../shared/ui/models/table';
import { BasketOrder } from '../models/model';
import { sortTable } from '../../shared/utils/helper/sorter';

export const getBulkOrderUploadState =  (state: State) => state.bulkOrders;

export const getUploadedBulkOrdersMap = createSelector(
  getBulkOrderUploadState,
  s => s.bulkOrders
);

export const getUploadedBulkOrders = createSelector(
  getBulkOrderUploadState,
  s => s.ids.map(id => s.bulkOrders[id])
);

export const getUploadErrors = createSelector(
  getBulkOrderUploadState,
  s => s.errors
);

export const isParsingCurrentlyInProgess = createSelector(
  getBulkOrderUploadState,
  s => s.parsingRunning
);

export const getFirstSubmitTime = createSelector(
  getBulkOrderUploadState,
  s => s.firstSubmitTime
);

const bulkRowsFn = (basketOrders: any[], basketTable: ITable) => {
  if (basketTable) {
    const _basketOrders = basketOrders.map((order: BasketOrder) => {
      return basketTable.columns.reduce(
        (result: any, col) => {
          return { ...result, [col.name]: order[col.originalName] };
        },
        { 
          id: order.correlationId
        }
      ); // id should be always present in the row
    });

    return sortTable(_basketOrders, basketTable.sorting, basketTable.hiddenColumnNames);
  }
  return [];
};

export const getBulkOrderRows = argsSelectorCreator(
  [getUploadedBulkOrders, (state: State, table: ITable | undefined) => table],
  bulkRowsFn
);

export const getSearchPhrase = createSelector(getBulkOrderUploadState, s => s.phrase);

export const getUploadedBulkOrderById = argsSelectorCreator(
  [getUploadedBulkOrdersMap, (state: State, id: string) => id],
  (orders: {[id: string]: BasketOrder}, id: string) => orders[id]
   
);