import { StompService, StompClient } from '../../../main/services/stompService';
import { ChangePasswordRequest } from '../models/passwordChange';
import { Observable, of } from 'rxjs';

export default class PasswordService extends StompService {
    constructor() {
        super(StompClient);
    }

    public changePassword(message: ChangePasswordRequest): Observable<any> {
        return of(
            this.sendMessage('/app/im/password/change', JSON.stringify(message))
        );
    }

    public subscribePasswordChanges() {
        return this.subscribe('/user/topic/im/password/response');
    }
}