export interface IValidationResult {
    value: any;
    message: string | null;
    valid: boolean;
}

export class ValidationResult implements IValidationResult {
    value: any;
    message: string | null;
    valid: boolean;

    public constructor(value: any, valid: boolean, message: string | null) {
        this.value = value;
        this.message = message;
        this.valid = valid;
    }
}

export interface Validation {
    translationPrefix: string;
    rules: {[name: string]: ((value: any, data: any) => ValidationResult)[]}; 
}