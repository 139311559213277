export const convertRgba2Hex = (
  rgba: string
): { color: string; alpha: number } => {
  const _rgba = rgba.match(
    /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
  );
  if (_rgba && _rgba.length === 5) {
    const color =
      '#' +
      ('0' + parseInt(_rgba[1], 10).toString(16)).slice(-2) +
      ('0' + parseInt(_rgba[2], 10).toString(16)).slice(-2) +
      ('0' + parseInt(_rgba[3], 10).toString(16)).slice(-2);
      
    const alpha = parseFloat(_rgba[4]) * 100;
    return { color, alpha };
  } else {
    return { color: '', alpha: 0 };
  }
};

export const convertHex2Rgba = (hex: string, alpha: number) => {
  var r, g, b;
  hex = hex.replace('#', '');
  if (3 === hex.length) {
    r = hex.charAt(0);
    g = hex.charAt(1);
    b = hex.charAt(2);
  } else if (4 === hex.length) {
    r = hex.charAt(0);
    g = hex.charAt(1);
    b = hex.charAt(2);
  } else if (6 === hex.length) {
    r = hex.substring(0, 2);
    g = hex.substring(2, 4);
    b = hex.substring(4, 6);
  } else if (8 === hex.length) {
    r = hex.substring(0, 2);
    g = hex.substring(2, 4);
    b = hex.substring(4, 6);
  } else {
    return '';
  }

  if (1 === r.length) {
    r += r;
  }
  if (1 === g.length) {
    g += g;
  }
  if (1 === b.length) {
    b += b;
  }
  
  r = parseInt(r, 16);
  g = parseInt(g, 16);
  b = parseInt(b, 16);
  return 'rgba(' + r + ',' + g + ',' + b + ',' + alpha + ')';
};
