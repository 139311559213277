import { ITab } from '../models/tab';
import { ComponentType } from '../models/component';
import { LogAction } from '../../../main/middlewares/logger';
import { LogLevel } from '../../logger/models/logger';
import { I18n } from 'react-redux-i18n';

export enum ActionTypes {
  LOAD = '[UI][Tab] Load',
  CREATE = '[UI][Tab] Create',
  MOVE = '[UI][Tab] Move',
  REMOVE = '[UI][Tab] Remove',
  TRIGGER_FILTER = '[UI][Tab] Trigger filter',
  TRIGGER_SEARCH = '[UI][Tab] Trigger search',
  CHANGE_TAB = '[UI][Tab] Change selected tab'
}

export interface TabLoadAction {
  type: ActionTypes.LOAD;
  payload: ITab[];
}

export interface TabCreateAction {
  type: ActionTypes.CREATE;
  payload: ITab;
}

export interface TabMoveAction extends LogAction {
  type: ActionTypes.MOVE;
  payload: { ids: string[]; dockId: string; toDockId: string };
}

export interface TabRemoveAction extends LogAction {
  type: ActionTypes.REMOVE;
  payload: { id: string; dockId: string; type: ComponentType, isAnalayticsReset?: boolean };
}

export interface TabTriggerFilterAction {
  type: ActionTypes.TRIGGER_FILTER;
  payload: { id: string; displayFilter: boolean };
}

export interface TabTriggerSearchAction {
  type: ActionTypes.TRIGGER_SEARCH;
  payload: { id: string; displaySearch: boolean };
}

export interface TabChangeAction {
  type: ActionTypes.CHANGE_TAB;
  payload: { selectedTabId: string; unselectedTabId: string };
}

export function load(tabs: ITab[]): TabLoadAction {
  return {
    type: ActionTypes.LOAD,
    payload: tabs
  };
}

export function create(tab: ITab): TabCreateAction {
  return {
    type: ActionTypes.CREATE,
    payload: tab
  };
}

export function move(
  tabIds: string[],
  dockId: string,
  toDockId: string
): TabMoveAction {
  return {
    type: ActionTypes.MOVE,
    payload: { ids: tabIds, dockId: dockId, toDockId: toDockId },
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('tab.move', {fromDock: dockId, toDock: toDockId})
  };
}

export function remove(
  tabId: string,
  dockId: string,
  type: ComponentType,
  isAnalayticsReset?: boolean
): TabRemoveAction {
  return {
    type: ActionTypes.REMOVE,
    payload: { id: tabId, dockId: dockId, type: type,  isAnalayticsReset: isAnalayticsReset},
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('tab.remove', {dock: dockId})
  };
}

export function triggerFilter(
  id: string,
  displayFilter: boolean
): TabTriggerFilterAction {
  return {
    type: ActionTypes.TRIGGER_FILTER,
    payload: { id, displayFilter }
  };
}

export function triggerSearch(
  id: string,
  displaySearch: boolean
): TabTriggerSearchAction {
  return {
    type: ActionTypes.TRIGGER_SEARCH,
    payload: { id, displaySearch }
  };
}

export function changeTab(
  selectedTabId: string,
  unselectedTabId: string
): TabChangeAction {
  return {
    type: ActionTypes.CHANGE_TAB,
    payload: {
      selectedTabId: selectedTabId,
      unselectedTabId: unselectedTabId
    }
  };
}

export type Action =
  | TabLoadAction
  | TabCreateAction
  | TabMoveAction
  | TabRemoveAction
  | TabTriggerFilterAction
  | TabTriggerSearchAction
  | TabChangeAction;
