export class Debouncer {
    timeout: any;
    constructor() {
        this.debounce = this.debounce.bind(this);
    }
    public debounce (func: Function, wait: number, immediate?: boolean) {
        const args = arguments;
        const self = this;
        
        var later = function() {
            self.timeout = null;
            if (!immediate) {
                func.apply(self, args);
            }
        };

        var callNow = immediate && !this.timeout;
            
        this.clear();
        if (callNow) {
            func.apply(self, args);
        } else {
            self.timeout = setTimeout(later, wait);
        }
    }

    public clear() {
        const self = this;
        if (self.timeout) {
            clearTimeout(self.timeout);
        }
    }
}