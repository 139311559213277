import { Action, Middleware } from 'redux';
import { ActionTypes } from '../../shared/messenger/actions/messenger';
import { Store } from 'redux';
import { State } from '../reducers/rootReducer';
import { OrderbooksState } from '../../orderbook/reducers/combinedReducer';
interface CustomAction extends Action {
  payload: any;
  correlationId: string;
}

export const correlationMiddleware: Middleware =  (store: Store<State & OrderbooksState>) => (next: any) => (action: any) => {
  if (action && action.correlationId) {
    store.dispatch({
      type: ActionTypes.SAVE_CORRELATION_ID,
      payload: action.correlationId
    });
  }
  return next(action);
};
