import * as React from 'react';

import {
  ITableRow,
  TableAction
} from '../../../models/table';
import { Translate } from 'react-redux-i18n';
import { MemoTranslate } from '../../../../../shared/i18n/components/memoTranslate';

const SelectActionButtons = React.memo(({
  isAllowed,
  isActionTriggeredTable,
  triggeredRows,
  selection,
  onTrigger,
  onUntrigger,
  locale
}: any) => {
  if (!isAllowed) {
    return null;
  }
  
  return triggeredRows.length && !selection.length && !isActionTriggeredTable ? (
    <div className="ml-auto">
      <button
        className="btn btn-secondary w200 mr-1"
        onClick={onTrigger}
      >
        <MemoTranslate value={`table.${locale}.triggered`}/>
      </button>
    </div>
  ) : !selection.length && isActionTriggeredTable ? (
    <div className="ml-auto">
      <button
        className="btn btn-secondary w200 mr-1"
        onClick={onUntrigger}
      >
        <MemoTranslate value={`table.${locale}.untriggered`}/>
      </button>
    </div>
  ) : null;
});

const SelectActionInputs = React.memo(({
  isAllowed,
  isActionTriggeredTable, // hidden/pinned
  selection,
  onTrigger,
  onUnTriggerAllRows,
  onUntrigger,
  locale,
  icon
}: any) => {
  if (!isAllowed || selection.length < 1) {
    return null;
  }

  const triggerId = 'triggered ' + locale;
  const untriggerId = 'un' + triggerId;
  const untriggerAllId = 'all-' + untriggerId;
  return isActionTriggeredTable ? (
    <div className={`trigger-actions d-flex ${locale}`}>
      <div className="form-check">
        <input
          type="checkbox"
          id={untriggerId}
          name={untriggerId}
          className="form-check-input"
          onChange={onUntrigger}
          checked={false}
        />
        {icon ? <i className={`oi oi-${icon}`} onClick={onUntrigger}/> : null}
        <label className="form-check-label" htmlFor={untriggerId}>
          <Translate value={`table.${locale}.untrigger`}/>
        </label>
      </div>
      <div className="form-check" style={{ display: onUnTriggerAllRows ? 'block' : 'none' }}>
        <input
          type="checkbox"
          id={untriggerAllId}
          name={untriggerAllId}
          className="form-check-input"
          onChange={onUnTriggerAllRows}
          checked={false}
        />
        {icon ?  <i className={`oi oi-${icon}`} onClick={onUnTriggerAllRows}/> : null}
        <label className="form-check-label" htmlFor={untriggerAllId}>
          <Translate value={`table.${locale}.untriggerAll`} />
        </label>
      </div>
    </div>
  ) : (
    <div className={onTrigger ? `trigger-actions d-flex ${locale}` : ` ${locale}`} style={{ display: onTrigger ? 'block' : 'none' }}>
      <div className="form-check">
        <input
          type="checkbox"
          id={triggerId}
          name={triggerId}
          className="form-check-input"
          onChange={onTrigger}
          checked={false}
        />
          {icon ? <i className={`oi oi-${icon}`} onClick={onTrigger} /> : null}
        <label className="form-check-label" htmlFor={triggerId}>
          <Translate value={`table.${locale}.trigger`} />
        </label>
      </div>
    </div>
  );
});

interface TableActionsProps {
  actions: TableAction[];
  hiddenMode: boolean;
  selection: number[];
  hiddenRows: number[];
  pinnedRows: number[];
  selectedRows: ITableRow[];
  rows: ITableRow[];
  onHideSelectedRows: (selectedRows: number[]) => void;
  onShowSelectedRows: (selectedRows: number[]) => void;
  onPinSelectedRows: (selectedRows: number[]) => void;
  onUnpinSelectedRows: (selectedRows: number[]) => void;
  onSearchTags: (tags: any[]) => void;
  onShowAllRows: () => void;
  showHidden: () => void;
  showUnhidden: () => void;
}
interface TableActionsState {}

export default class UITableActions extends React.Component<
  TableActionsProps,
  TableActionsState
> {
  constructor(props: TableActionsProps) {
    super(props);

    this.onHideSelected = this.onHideSelected.bind(this);
    this.onHideOthers = this.onHideOthers.bind(this);
    this.onShowSelected = this.onShowSelected.bind(this);
    this.onUnpinSelected = this.onUnpinSelected.bind(this);
    this.onPinSelected = this.onPinSelected.bind(this);
    this.onShowHidden = this.onShowHidden.bind(this);
    this.onShowUnhidden = this.onShowUnhidden.bind(this)
    this.onShowAllRows = this.onShowAllRows.bind(this);
  }

  onHideSelected(event: any) {
    const { selectedRows } = this.props;
    this.props.onHideSelectedRows(selectedRows.map(r => r.$index));
  }

  onShowSelected(event: any) {
    const { selectedRows } = this.props;
    this.props.onShowSelectedRows(selectedRows.map(r => r.$index));
  }

  onPinSelected(event: any) {
    const { selectedRows } = this.props;
    this.props.onPinSelectedRows(selectedRows.map(r => r.$index));
  }

  onUnpinSelected(event: any) {
    const { selectedRows } = this.props;
    this.props.onUnpinSelectedRows(selectedRows.map(r => r.$index));
  }

  onHideOthers(event: any) {
    const { selection, rows } = this.props;

    const nonSelectedRows = rows.filter((row, index) => {
      return selection.indexOf(index) === -1;
    }).map(r => r.$index);

    this.props.onHideSelectedRows(nonSelectedRows);
  }

  onShowAllRows() {
    this.props.onShowAllRows();
  } 

  onShowHidden() {
    this.props.showHidden();
  }

  onShowUnhidden() {
    this.props.showUnhidden();
  }

  render() {
    const {
      actions,
      selection,
      hiddenRows,
      hiddenMode,
      pinnedRows
    } = this.props;
    const pinnedRowsSelected = selection && pinnedRows && selection.some(r => pinnedRows.indexOf(r) >= 0);
    const hiddenRowsSelected = selection && hiddenRows && selection.some(r => hiddenRows.indexOf(r) >= 0);
    return (
      <div className="uitable__actions">
        <SelectActionInputs
          isAllowed={actions.indexOf(TableAction.HIDDING_ROWS) !== -1 && !pinnedRowsSelected}
          isActionTriggeredTable={hiddenMode}
          selection={selection}
          onTrigger={this.onHideSelected}
          onUnTriggerAllRows={this.onShowAllRows}
          onUntrigger={this.onShowSelected}
          locale="hide"
        />
        <SelectActionButtons
          isAllowed={actions.indexOf(TableAction.HIDDING_ROWS) !== -1}
          isActionTriggeredTable={hiddenMode}
          triggeredRows={hiddenRows}
          selection={selection}
          onTrigger={this.onShowHidden}
          onUntrigger={this.onShowUnhidden}
          locale="unhide"
        />
        <SelectActionInputs
          isAllowed={actions.indexOf(TableAction.PINNING_ROWS) !== -1}
          isActionTriggeredTable={pinnedRowsSelected}
          selection={selection}
          onTrigger={hiddenRowsSelected ? null : this.onPinSelected}
          onUnTriggerAllRows={null}
          onUntrigger={this.onUnpinSelected}
          locale="pin"
          icon="pin"
        />
        {/* <SelectActionButtons
          isAllowed={actions.indexOf(TableAction.PINNING_ROWS) !== -1}
          isActionTriggeredTable={pinnedRowsSelected}
          triggeredRows={pinnedRows}
          selection={selection}
          onTrigger={this.onPinSelected}
          onUntrigger={this.onUnpinSelected}
          locale="pin"
        /> */}
      </div>
    );
  }
}
