import { Instrument } from '../../instruments/models/instruments';

export enum ActionTypes {
  PROCESS_INSTRUMENTS_SUCCESS = '[Instruments] Load success'
}

export interface InstrumentsProcessSuccess {
  type: ActionTypes.PROCESS_INSTRUMENTS_SUCCESS;
  payload: { [key: string]: Instrument };
}

export type Action = InstrumentsProcessSuccess;

export function instrumentsProcessSuccess(payload: {
  [key: string]: Instrument;
}): InstrumentsProcessSuccess {
  return {
    type: ActionTypes.PROCESS_INSTRUMENTS_SUCCESS,
    payload: payload
  };
}
