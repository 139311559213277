import UITable from '../../shared/ui/components/table/components/uiTableComponent';
import { getTableForTab } from '../../shared/ui/selectors/ui';
import { ComponentType } from '../../shared/ui/models/component';
import { State } from '../../main/reducers/rootReducer';
import { getPriceAlarmTableRows } from '../selectors/priceAlarms';
import {
  triggerColumnNames,
  triggerHeadlines,
  sort,
  triggerSearchTags,
  pinRows,
  unpinRows
} from '../../shared/ui/actions/table';
import { triggerFilter } from '../../shared/ui/actions/tab';
import { connect } from 'react-redux';

const mapStateToProps = (state: State, props: any) => ({
  table: getTableForTab(state, props.parentId, ComponentType.PriceAlarm),
  rows: getPriceAlarmTableRows(
    state,
    getTableForTab(state, props.parentId, ComponentType.PriceAlarm)
  )
});

const mapDispatchToProps = {
  sort: sort,
  triggerColumnNames: triggerColumnNames,
  triggerHeadlines: triggerHeadlines,
  triggerFilter: triggerFilter,
  triggerSearchTags: triggerSearchTags,
  pinRows: pinRows,
  unpinRows: unpinRows
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(UITable);