import { Product } from '../models/contracts';
import { I18n } from 'react-redux-i18n';
import { LogLevel } from '../../shared/logger/models/logger';
import { LogAction } from '../../main/middlewares/logger';

export enum ActionTypes {
  LOAD_PRODUCTS = '[Products] Load',
  LOAD_PRODUCTS_SUCCESS = '[Products] Load success',
  PROCESS_PRODUCTS_SUCCESS = '[Products] Load success',
  SUBSCRIBE_PRODUCTS_RESPONSE = '[Products] Received SubscribeProductsResponse'
}

export interface ProductsProcessSuccess {
  type: ActionTypes.PROCESS_PRODUCTS_SUCCESS;
  payload: { [key: string]: Product };
}

export interface ProductsLoad {
  type: ActionTypes.LOAD_PRODUCTS;
}

export interface ProductsLoadSuccess {
  type: ActionTypes.LOAD_PRODUCTS_SUCCESS;
  payload: {[id: string]: Product};
}

export interface SubscribeProductsResponse extends LogAction {
  type: ActionTypes.SUBSCRIBE_PRODUCTS_RESPONSE;
  product: Product;
  action: string;
}

export function productsProcessSuccess(payload: {
  [key: string]: Product;
}): ProductsProcessSuccess {
  return {
    type: ActionTypes.PROCESS_PRODUCTS_SUCCESS,
    payload: payload
  };
}

export function loadProducts(): ProductsLoad {
  return {
    type: ActionTypes.LOAD_PRODUCTS
  };
}

export function loadProductsSuccess(payload: {[id: string]: Product}): ProductsLoadSuccess {
  return {
    type: ActionTypes.LOAD_PRODUCTS_SUCCESS,
    payload: payload
  };
}

export function productsResponse(product: Product, action: string): SubscribeProductsResponse {
  return {
    type: ActionTypes.SUBSCRIBE_PRODUCTS_RESPONSE,
    product: product,
    action: action,
    logMessage: I18n.t('product.log.subscribeResponse'),
    logLevel: LogLevel.INFO
  };
}

export type Action = ProductsProcessSuccess
| ProductsProcessSuccess
| SubscribeProductsResponse;
