
import { ActionTypes, Action } from '../../main/actions/settings';
import { LogLevel } from '../../shared/logger/models/logger';

export interface State {
    [key: string]: any;
}

export const initialState: State = {
    clientLogLevel: LogLevel.INFO
};

export function reducer(state: State = initialState, action: Action) {
    switch (action.type) {
        case ActionTypes.LOAD_CONFIGURATION: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
}
