import { Instrument } from '../models/instruments';
import { LogAction } from '../../main/middlewares/logger';
import { LogLevel } from '../../shared/logger/models/logger';
import { I18n } from 'react-redux-i18n';

export enum ActionTypes {
    LOAD_INSTRUMENTS_SUCCESS = '[Instruments] Load success',
    LOAD_INSTRUMENTS_FAILURE = '[Instruments] Load failure',
    SEARCH_INSTRUMENTS = '[Instruments] Search',
    SEARCH_INSTRUMENTS_RESULTS = '[Instruments] Search result'
}

export interface InstrumentsLoadActionSuccess extends LogAction {
    type: ActionTypes.LOAD_INSTRUMENTS_SUCCESS;
    payload: {[key: string]: Instrument};
}

export interface InstrumentsLoadActionFailure extends LogAction {
    type: ActionTypes.LOAD_INSTRUMENTS_FAILURE;
    payload: string;
}

export type Action =
  | InstrumentsLoadActionSuccess
  | InstrumentsLoadActionFailure;

export function instrumentsLoadSuccess(payload: {[key: string]: Instrument}) {
    let me = I18n.t('instrument.log.Instruments successfully loaded');
    return {
        type: ActionTypes.LOAD_INSTRUMENTS_SUCCESS,
        payload: payload,
        logLevel: LogLevel.DEBUG,
        logMessage: me
    };
}

export function instrumentsLoadFailure(error: Error) {
    return {
        type: ActionTypes.LOAD_INSTRUMENTS_SUCCESS,
        payload: error,
        logLevel: LogLevel.ERROR,
        logMessage: I18n.t('instrument.log.loadFailure')
    };
}