import { connect } from 'react-redux';
import { State } from '../../../main/reducers/rootReducer';
import { getLogRows } from '../selectors/logger';
import { ComponentType } from '../../../shared/ui/models/component';
import { getTableForTab } from '../../../shared/ui/selectors/ui';
import {
  triggerColumnNames,
  triggerHeadlines,
  sort,
  triggerSearchTags
} from '../../../shared/ui/actions/table';
import { triggerFilter } from '../../../shared/ui/actions/tab';

import UITable from '../../ui/components/table/components/uiTableComponent';

const mapStateToProps = (state: State, props: any) => ({
  table: getTableForTab(state, props.parentId, ComponentType.Log),
  rows: getLogRows(
    state,
    getTableForTab(state, props.parentId, ComponentType.Log)
  )
});

const mapDispatchToProps = {
  sort: sort,
  triggerColumnNames: triggerColumnNames,
  triggerHeadlines: triggerHeadlines,
  triggerFilter: triggerFilter,
  triggerSearchTags: triggerSearchTags
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(UITable);