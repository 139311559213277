import { BasketOrder, BasketOrderStatus } from '../models/model';
import { v1 } from 'uuid';
import * as Basket from '../actions/bulkOrders';
import * as Connection from '../../authentication/actions/connection';
import * as Authentication from '../../authentication/actions/authentication';
import { I18n } from 'react-redux-i18n';

export interface State {
    bulkOrders: {[id: string]: BasketOrder};
    ids: string[];
    errors: { [row: number]: string[] };
    parsingRunning: boolean;
    parsingFailedError: any;
    phrase: string;
    firstSubmitTime: number;
}

export const initialState: State = {
    bulkOrders: {},
    ids: [],
    errors: {},
    parsingRunning: false,
    parsingFailedError: null,
    phrase: '',
    firstSubmitTime: -1
};

export function reducer(
    state: State = initialState,
    action: Basket.Action | Connection.Action | Authentication.Action
) {
    switch (action.type) {
        case Basket.ActionTypes.BASKET_ORDER_SUCCESS: {
            let correlationId = action.id;
            let updatedOrders = state.ids
                .map(id => state.bulkOrders[id])
                .reduce(
                    (   
                        orders: {[id: string]: BasketOrder}, 
                        order: BasketOrder
                    ) => {
                        orders[order.correlationId] = (order.correlationId === correlationId ?
                            Object.assign({}, order, {
                                status: BasketOrderStatus.SUCCESS,
                                statusMessage: action.logMessage
                            }) : order
                        );

                        return orders;
            }, {});
            return {
                ...state,
                bulkOrders: updatedOrders
            };
        }
        case Basket.ActionTypes.BASKET_ORDER_BULK_SUCCESS: {
            const ackCorrelationIds = action.acks.map(ack => ack.correlationId);
           
            const updatedOrders = state.ids
                .map(id => state.bulkOrders[id])
                .reduce(
                    (   
                        orders: {[id: string]: BasketOrder}, 
                        order: BasketOrder
                    ) => {
                        orders[order.correlationId] = (ackCorrelationIds.indexOf(order.correlationId) > -1 ?
                            Object.assign({}, order, {
                                status: BasketOrderStatus.SUCCESS,
                                statusMessage: 'bulk.order.success'
                            }) : order
                        );

                        return orders;
            }, {});
            
            return {
                ...state,
                bulkOrders: updatedOrders
            };
        }
        case Basket.ActionTypes.BASKET_ORDER_FAILURE: {
            let correlationId = action.id;
            let updatedOrders = state.ids
                .reduce(
                    (
                        orders: {[id: string]: BasketOrder}, 
                        id: string
                    ) => {
                    const order = state.bulkOrders[id];    
                    orders[id] = (order.correlationId === correlationId ?
                        Object.assign({}, order, {
                            statusMessage: action.message,
                            status: BasketOrderStatus.FAILED
                        }) : order
                    );
                    return orders;
            }, {});
            return {
                ...state,
                bulkOrders: updatedOrders
            };
        }
        case Basket.ActionTypes.BASKET_ORDER_BULK_FAILURE: {
            const ackCorrelationIds = action.acks.map(ack => ack.correlationId);
            const ackMessages = action.acks.reduce((acc: any, ack: any) => {
                acc[ack.correlationId] = ack.message;
                return acc;
            }, {});
            const updatedOrders = state.ids
                .reduce(
                    (
                        orders: {[id: string]: BasketOrder}, 
                        id: string
                    ) => {
                    const order = state.bulkOrders[id];    
                    orders[id] = (ackCorrelationIds.indexOf(order.correlationId) > -1 ?
                        Object.assign({}, order, {
                            statusMessage: ackMessages[order.correlationId].message,
                            status: BasketOrderStatus.FAILED
                        }) : order
                    );
                    return orders;
            }, {});
            
            return {
                ...state,
                bulkOrders: updatedOrders
            };
        }
        case Basket.ActionTypes.PARSE_REQUEST: {
            return {...initialState};
        }
        case Basket.ActionTypes.PARSE_SUCCESS: {
            const parsedOrders = action.orders ? action.orders : [];
            let newOrders = parsedOrders
                .reduce(
                    (
                        orders: {[id: string]: BasketOrder},
                        order: BasketOrder
                    ) => {
                    const correlationId = v1();
                    orders[correlationId] = 
                        Object.assign({}, order, {
                            correlationId: correlationId,
                            statusMessage: 'bulk.order.notEntered',
                            $index: correlationId
                        });
                    return orders;    
            }, {});
            const parsingErrors = action.rowsWithError;
            return {
                ...state,
                bulkOrders: newOrders,
                errors: parsingErrors,
                parsingRunning: false,
                parsingFailedError: null,
                ids: state.ids.concat(Object.keys(newOrders))
            };
        }
        case Basket.ActionTypes.PARSE_FAILURE: {
            return {
                ...state,
                parsingFailedError: action.logMessage,
                parsingRunning: false
            };
        }
        case Basket.ActionTypes.SELECT_UPLOADED_ORDER: {
            if (action.key !== 'delete') {
                return state;
            }
            const bulkOrders = state.bulkOrders;
            let correlationId: string = action.id;
            const remaining: {[id: string]: BasketOrder} = {};
            for (let i = 0; i < state.ids.length; i++) {
                if (bulkOrders[state.ids[i]].correlationId !== correlationId) {
                    remaining[state.ids[i]] = bulkOrders[state.ids[i]];
                }
            }
            return {
                ...state,
                bulkOrders: remaining,
                ids: Object.keys(remaining)
            };
        }
        // case Connection.ActionTypes.CONNECTION_LOST:
        case Connection.ActionTypes.DISCONNECT:
        case Authentication.ActionTypes.AUTHENTICATION_LOGOUT:
        case Basket.ActionTypes.CLEAR_BASKET_ORDERS: {
            return initialState;
        }
        case Basket.ActionTypes.SUBMIT_BULK_ORDERS: {
            const timeStamp = new Date().getTime();
            const pendingOrders = action.orders ? action.orders : [];
            const pending: string[] = pendingOrders.map(order => order.correlationId);
            let newOrders = state.ids.reduce(
                    (   
                        orders: {[id: string]: BasketOrder}, 
                        id: string
                    ) => {
                    const order = state.bulkOrders[id];    
                    orders[id] = (pending.indexOf(order.correlationId) !== -1 ?
                        Object.assign({}, order, {
                            status: BasketOrderStatus.PENDING,
                            statusMessage: I18n.t('bulk.order.pending')
                        }) : order
                    );
                    return orders;
            }, {});
            return {
                ...state,
                bulkOrders: newOrders,
                firstSubmitTime: timeStamp
            };
        }
        case Basket.ActionTypes.MODIFY_UPLOADED_ORDER: {
            const order = action.payload;

            return {
                ...state,
                bulkOrders: {
                    ...state.bulkOrders,
                    [order.correlationId]: {...order}
                }
            };
        }
        default:
            return state;
    }
}
