import { connect } from 'react-redux';
import { subscribeOrderbooks, unsubscribeOrderbooks } from '../actions/orderForm';
import SanityCheckElement from '../components/sanityCheck';
import { State } from '../../main/reducers/rootReducer';
import { getSanityLimits } from '../selectors/orders';
import { getOrderbookPrices } from '../../orderbook/selectors/orderbooks';
import orderBookStore from '../../orderbook/store/orderbooks';
import { getLastPrices } from '../../trade/selectors/trade';

const mapStateToProps = (state: State, props: any) => {
  return {
    prices: getOrderbookPrices(orderBookStore.getState())[props.contract.id],
    sanityLimits: getSanityLimits(state, props.contract ? props.contract.productId : null),
    lastPrices: getLastPrices(state)[props.contract.id]
  };
};
  
const mapDispatchToProps = {
    subscribe: subscribeOrderbooks,
    unsubscribe: unsubscribeOrderbooks
};
  
export default connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(SanityCheckElement);