import { StompService, StompClient } from '../../main/services/stompService';
import { Observable, of } from 'rxjs';
import { GenericRequest } from '../../main/models/application';

export default class BusinessUnitPortfolioService extends StompService {
    constructor() {
      super(StompClient);
    }

    public sendBusinessUnitInquiry(message: GenericRequest): Observable<any> {
        return of(
          this.sendMessage('/app/im/generic', JSON.stringify(message))
        );
    }

    public subscribeBusinessUnits(): Observable<any> {
        return this.subscribe('/user/topic/im/sub/businessunits');
    }
}