import * as Requests from '../actions/requests';
import { IRequest, QuoteRequestFormData } from '../models/request';
import * as formReducer from './quoteRequestForm';
export interface State {
  ids: number[];
  entities: { [id: number]: IRequest };
  error: any;
  form: formReducer.FormState;
}

export const initialState: State = {
  ids: [],
  entities: {},
  error: null,
  form: formReducer.initialState
};

export function reducer(state: State = initialState, action: Requests.Action) {
  switch (action.type) {
    case Requests.ActionTypes.LOAD: {
      return initialState;
    }

    case Requests.ActionTypes.LOAD_SUCCESS: {
      const requests = action.payload;
      const newRequests = requests.filter(request => !state.entities[request.id]);
      const newRequestIds = newRequests.map(request => request.id);
      const newRequestntities = requests.reduce(
        (entities: { [id: string]: IRequest }, request: IRequest) => {
          return Object.assign(entities, {
            [request.id]: Object.assign(request,  {
              $index: request.id
            })
          });
        },
        {}
      );
      return {
        ...state,
        ids: [...state.ids, ...newRequestIds],
        entities: {
          ...state.entities,
          ...newRequestntities
        },
        error: null
      };
    }

    case Requests.ActionTypes.QUOTE_REQUEST_REMOVE: {
      const removed = action.payload.map(req => req.id);
      const remaining = state.ids.filter(id => removed.indexOf(id) === -1);
      
      const newEntities = remaining.reduce(
        (entities: { [id: string]: IRequest }, id: number) => {
          entities[id] = state.entities[id];
          return entities;
        },
        {}
      );
      return {
        ...state,
        ids: remaining,
        entities: newEntities,
        error: null
      };
    }

    case Requests.ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        error: action.error
      };
    }
    case Requests.ActionTypes.QUOTE_REQUEST_FORM_SHOW:
    case Requests.ActionTypes.QUOTE_REQUEST_FORM_HIDE: {
      return {
        ...state,
        form: formReducer.reducer(state.form, action)
      };
    }
    default:
      return state;
  }
}
