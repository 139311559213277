import { DraggerComp } from '../components/Dragger';
import { State } from '../../../main/reducers/rootReducer';
import { connect } from 'react-redux';
import { isProfileModificationAllowed } from '../../../authentication/selectors/authetication';

const mapStateToProps = (state: State, props: any) => ({
  modificationEnabled: isProfileModificationAllowed(state)
});

const mapDispatchToProps = {
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(DraggerComp);
