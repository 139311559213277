import { Log } from '../models/logger';
import * as LogActions from '../actions/logger';
import * as Settings from '../../settings/actions/settings';

export interface State {
    logs: Log[];
    version: string;
}

export const initialState: State = {
    logs: [],
    version: ''
};

export function reducer(state: State = initialState, action: LogActions.Action | Settings.Action) {
    switch (action.type) {
        case LogActions.ActionTypes.SEND_LOG_TO_SERVER: {
            return {
                ...state,
                logs: state.logs.concat([{...action.payload, $index: action.payload.id}])
            };
        }
        case LogActions.ActionTypes.CLEAR: {
            return {
                ...state,
                logs: []
            };
        }
        case Settings.ActionTypes.LOAD_VERSION: {
            return {
                ...state,
                version: action.version
            };
        }
        default:
            return state;
    }
}
