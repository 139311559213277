import { State } from '../../../main/reducers/rootReducer';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { ActionTypes, load } from '../actions/favorite';
import { toggleFavorite as toggleDockFavorite } from '../../dock/actions/dock';
import * as Rx from 'rxjs';
import * as Settings from '../../../main/actions/settings';
import { getAvailableFavorites } from '../selectors/favorite';
import {
  LoadSettingsResponse,
  SaveSettingsRequest
} from '../../../main/models/application';
import { getDockById } from '../../../dashboard/selectors/dashboard';
import { filter, map, switchMap, catchError } from 'rxjs/operators';
import FavoriteService from '../services/favorite';
import { receiveMessage } from '../../messenger/actions/messenger';

const favoritesService = new FavoriteService();

export const saveFavorites: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.TOGGLE || action.type === ActionTypes.TOGGLE_FAVORITE_VISIBILITY),
    map(actions => actions.payload),
    switchMap(data => {
      const favoritesToSave = getAvailableFavorites(state.value);
      let dock: any = undefined;
      if (data.favorite) {
        dock = getDockById(state.value, data.favorite.entityId);
      }
      return favoritesService.saveFavorites(<SaveSettingsRequest> {
          settings: {
            favoritesJson: JSON.stringify(favoritesToSave)
          }
        }).pipe(
          switchMap((content: LoadSettingsResponse) => 
            dock ? Rx.merge(
              Rx.of(Settings.loadSettings(content)),
              Rx.of(toggleDockFavorite(dock.id, data.isFavorite ? data.favorite.name : ''))
            ) : Rx.of(Settings.loadSettings(content))
          ),
          catchError((error: Error) => Rx.of(receiveMessage('', new Error('favorite.log.saveFailure'), true)))
        );
    })
  );
};

export const favoritesLoad: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Settings.ActionTypes.LOAD_SETTINGS),
    map(action => action.payload),
    switchMap((content: LoadSettingsResponse) => {
      if (!!content && !!content.settings && !!content.settings.favoritesJson) {
        return Rx.of(
          load(JSON.parse(content.settings.favoritesJson))
        );
      }
      return Rx.empty();
    })
  );
};

export const favoriteEpic = combineEpics(saveFavorites, favoritesLoad);
