import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import * as Rx from 'rxjs';
import { State } from '../../main/reducers/rootReducer';
import {
  ActionTypes as Authentication
} from '../../authentication/actions/authentication';
import {
  ActionTypes as Connection
} from '../../authentication/actions/connection';
import {
  ActionTypes, loadProductsSuccess, loadProducts, productsResponse
} from '../actions/products';
import ProductService from '../services/products';
import { isProductDataLoaded } from '../selectors/orderbooks';
import { filter, map, catchError, takeUntil, switchMap, mergeMap } from 'rxjs/operators';
import orderBookStore from '../store/orderbooks';
import { receiveMessage } from '../../shared/messenger/actions/messenger';
import { warn } from '../../shared/logger/actions/logger';
import { I18n } from 'react-redux-i18n';
import {
  ActionTypes as Products
} from '../../orderbook/actions/products';

const productService = new ProductService();

export const loadProductsMap: any = (
  actions$: ActionsObservable<any>
) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.LOAD_PRODUCTS),
    switchMap((act) => {
      const orderbookState = orderBookStore.getState();
      if (!isProductDataLoaded(orderbookState)) {
        return productService
          .subscribeProductMap().pipe(
          map((content: any) => {
            return orderBookStore.dispatch(loadProductsSuccess(content.productMap));
          }),
          takeUntil(actions$.pipe(filter(action => action.type === Connection.DISCONNECT  || action.type === Connection.CONNECTION_LOST)))
        );
      }
      return Rx.empty();
    }),
    catchError(error => {
      return Rx.of(receiveMessage('', 'product.log.loadFailure', true));
    })
  );
};

export const connection: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.AUTHENTICATION_SUCCESS || action.type === Authentication.RELOGIN_SUCCESS),
    map(action => action.payload),
    mergeMap((data: any) => {
      return Rx.of(
        orderBookStore.dispatch(loadProducts())).pipe(
          takeUntil(
            actions$.pipe(filter(action => action.type  === Authentication.AUTHENTICATION_LOGOUT))
          )
      );
    })
  );
};

export const subscribeProductsResponse: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.AUTHENTICATION_SUCCESS || action.type === Authentication.RELOGIN_SUCCESS),
    map(action => action.payload),
    switchMap(() => {
      return productService
        .subscribeProductsResponse().pipe(
        mergeMap((content: any) => {
          return Rx.of(orderBookStore.dispatch(productsResponse(content.product, content.action)));
        }),
        catchError(error => {
          return Rx.of(warn(I18n.t('error.productsLoad')));
        }),
        takeUntil(
          actions$.pipe(filter(action => action.type === Connection.DISCONNECT  || action.type === Connection.CONNECTION_LOST))
        ));
    })
  );
};

export const productsEpic = combineEpics(connection, loadProductsMap, subscribeProductsResponse);
