import { QuoteRequestFormData, QuoteRequestMode } from '../models/request';
import { Action, ActionTypes } from '../actions/requests';

export interface FormState {
    selectedContractId: string;
    visible: boolean;
    data: QuoteRequestFormData;
}

export const initialState: FormState = {
    selectedContractId: '',
    visible: false,
    data: <QuoteRequestFormData> {
        quantity: 0,
        contractId: '',
        quoteRequestId: 0,
        buy: true,
        mode: QuoteRequestMode.ENTER,
        validFrom: null,
        validTo: null,
        reference: '',
        comment: ''
    }
};

export function reducer(state: FormState = initialState, action: Action) {
    switch (action.type) {
        case ActionTypes.QUOTE_REQUEST_FORM_SHOW: {
            return {
                ...state,
                data: { 
                    ...state.data, 
                    ...action.payload 
                },
                selectedContractId: action.payload.contractId,
                visible: true
            };
        }
        case ActionTypes.QUOTE_REQUEST_FORM_HIDE: {
            return {
                ...state,
                data: { ...initialState.data},
                visible: false
            };
        }
        default: {
            return state;
        }
    }
}