export interface IRequest {
  [key: string]: any;
  $index: number;
  id: number;
  requesterId: string;
  requesterName: string;
  requesterGroupId: string;
  requesterGroupName: string;
  requesterRoleId: string;
  buy: boolean;
  requesterBusinessUnitId: string;
  requesterPortfolioId: string;
  requesterLocation: string;
  requesterStrategy: string;
  quote: number;
  riskPremium: number;
  traderId: string;
  traderName: string;
  traderGroupId: string;
  traderGroupName: string;
  expiry: number;
  traderBusinessUnitId: string;
  traderPortfolioId: string;
  traderLocation: string;
  traderStrategy: string;
  validFrom: string;
  validTo: string;
  reference: string;
  comment: string;
  deleteOnDisconnect: boolean;
  state: QuoteRequestState;
  isOwn: boolean;
  contractId: string;
  contractTitle: string;
  quantity: number;
  meetState: string;
}

export interface QuoteRequestFormData {
  contractId: string;
  buy: boolean;
  mode: QuoteRequestMode;
  quantity: number;
  quoteRequestId: number;
  reference: string;
  comment: string;
  validFrom: Date | null;
  validTo: Date | null;
  quote: number;
  expiry: number;
  businessUnit: string | null;
  portfolio: number | null;
  associatedContracts: string[];
}

export enum QuoteRequestMode {
  ENTER, CANCEL, ANSWER
}

export enum QuoteRequestState {
  ACTIVE = 'ACTIVE',
  ANSWERED = 'ANSWERED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  TIMEOUT = 'TIMEOUT',
  DELETED = 'DELETED',
  PRICED = 'PRICED'
}

export interface EnterQuoteRequestRequest {
  correlationId: string;
  buy: boolean;
  validFrom: Date | null;
  validTo: Date | null;
  reference: string;
  comment: string;
  quoteRequestProfile: QuoteRequestProfile; 
  portfolioId?: string;
  userRoleId?: string;
  businessUnitId?: string;
  deleteOnDisconnect?: boolean;
}

export interface AnswerQuoteRequestRequest {
  correlationId: string;
  id: number;
  quote: number;
  riskPremium: number;
  expiry: number;
  traderBusinessUnitId?: string;
  traderPortfolioId?: string; 
}

export interface QuoteRequestProfile {
  quoteRequestProfileEntry: QuoteRequestProfileEntry[];
}

export interface QuoteRequestProfileEntry {
  contractId: string;
  quantity: number;
}

export interface DeleteQuoteRequestRequest {
  correlationId: string;
  id: number;
}

export interface AcceptQuoteRequestRequest {
  id: number;
  businessUnitId: string;
  portfolioId: string;
  correlationId: string;
}

export interface RejectQuoteRequestRequest {
  id: number;
  correlationId: string;
}