import {
  ActionsObservable,
  combineEpics,
  StateObservable
} from 'redux-observable';
import * as Rx from 'rxjs';
import InstrumentsService from '../services/instruments';
import {
  ActionTypes,
  instrumentsLoadSuccess
} from '../actions/instruments';
import { State } from '../../main/reducers/rootReducer';
import * as models from '../models/instruments';
import { ActionTypes as Contracts } from '../../orderbook/actions/contracts';
import { getAllInstruments } from '../selectors/instruments';
import { isInstrumentDataLoaded } from '../../orderbook/selectors/orderbooks';
import { filter, map, switchMap, mergeMap } from 'rxjs/operators';
import orderBookStore from '../../orderbook/store/orderbooks';
import {
  ActionTypes as Products
} from '../../orderbook/actions/products';

const instrumentsService = new InstrumentsService();

export const loadInstrumentsMap: any = (
  actions$: ActionsObservable<any>
) => {
  return actions$.pipe(
    filter(action => action.type === Contracts.LOAD_CONTRACTS),
    map(action => action.payload),
    mergeMap(() => {
      if (!isInstrumentDataLoaded(orderBookStore.getState())) {
        return instrumentsService.subscribeInstrumentsMap().pipe(
          map((content: any) => {
            orderBookStore.dispatch(
              instrumentsLoadSuccess(content.instrumentMap)
            );
            return instrumentsLoadSuccess(content.instrumentMap);
          })
        );
      }
      return Rx.empty();
    })
  );
};

export const instrumentsEpic = combineEpics(
  loadInstrumentsMap
);
