import { TranslationObjects, I18n } from 'react-redux-i18n';
import { LogAction } from '../../../main/middlewares/logger';
import { LogLevel } from '../../logger/models/logger';

export enum ActionTypes {
    LOAD_TRANSLATIONS_ERROR = '[Translations] Load error',
    LOAD_TRANSLATIONS_SUCCESS = '@@i18n/LOAD_TRANSLATIONS',
    SET_APP_LOCALE = '@@i18n/SET_LOCALE',
    LOAD_TRANSLATIONS = '[Translations] Load translations',
    LOAD_LOCALES = '[Translations] Load available locales'
  }

export interface TranslationsLoadError extends LogAction {
    type: ActionTypes.LOAD_TRANSLATIONS_ERROR;
    payload: any;
}

export interface TranslationsLoadSuccess extends LogAction {
    type: ActionTypes.LOAD_TRANSLATIONS_SUCCESS;
    translations: TranslationObjects;
}

export interface ApplicationLocale extends LogAction {
    type: ActionTypes.SET_APP_LOCALE;
    locale: string;
}

export interface TranslationsLoad {
    type: ActionTypes.LOAD_TRANSLATIONS;
    payload: string;
}

export interface LocalesLoadSuccess extends LogAction {
    type: ActionTypes.LOAD_LOCALES;
    payload: string[];
}

export function loadTranslationsSuccess(payload: TranslationObjects): TranslationsLoadSuccess {
    return {
        type: ActionTypes.LOAD_TRANSLATIONS_SUCCESS,
        translations: payload,
        logLevel: LogLevel.INFO,
        logMessage: 'Translations successfully loaded.'
    };
}

export function setApplicationLocale(payload: string): ApplicationLocale {
    return {
        type: ActionTypes.SET_APP_LOCALE,
        locale: payload,
        logLevel: LogLevel.INFO,
        logMessage: 'Setting locale to ' + payload
    };
}

export function loadTranslationsError(payload: any): TranslationsLoadError {
    return {
        type: ActionTypes.LOAD_TRANSLATIONS_ERROR,
        payload: payload,
        logLevel: LogLevel.ERROR,
        logMessage: 'Loading translations failed.'
    };
}

export function loadTranslations(payload: string): TranslationsLoad {
    return {
        type: ActionTypes.LOAD_TRANSLATIONS,
        payload: payload
    };
}

export function loadLocalesSuccess(payload: string[]): LocalesLoadSuccess {
    return {
        type: ActionTypes.LOAD_LOCALES,
        payload: payload,
        logLevel: LogLevel.INFO,
        logMessage: 'Locales successfully loaded: ' + payload
    };
}

export type Action =
  | TranslationsLoadError
  | ApplicationLocale
  | TranslationsLoadSuccess
  | TranslationsLoad
  | LocalesLoadSuccess;