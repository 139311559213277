import { SanityCheck, DeviationMode, PriceDeviationType } from "../models/sanityChecks";
import OrderFormData from "../models/formData";
import { I18n } from "react-redux-i18n";

const isSane = (enteredValue: number, orderbookValue: number, sanityCheck: SanityCheck, buy: boolean): boolean => {
  if (sanityCheck.priceDeviationMode === DeviationMode.PERCENTAGE) {
    const percentage = (enteredValue / orderbookValue) * 100;
    return ((100 - sanityCheck.priceDeviationValue) < percentage && percentage < (100 + sanityCheck.priceDeviationValue));
  } else {
    return Math.abs(enteredValue - orderbookValue) < sanityCheck.priceDeviationValue;
  }
}

export const checkSanity = (
  formData: OrderFormData, 
  prices: any, 
  sanityChecks: SanityCheck[]
  ): {isSanePrice: boolean, isSaneQty: boolean, warnings: string[]} => {
  let isSanePrice = true;
  let isSaneQty = true;
  let warningStrings: string[] = [];
  if (!sanityChecks) {
    return {
      isSanePrice,
      isSaneQty,
      warnings: warningStrings
    };
  }

  for (let i = 0; i < sanityChecks.length; i++) {
    const {sanePrice, saneQty, warnings} = checkSanityRule(formData, prices, sanityChecks[i]);
    isSanePrice = isSanePrice && sanePrice;
    isSaneQty = isSaneQty && saneQty;
    warningStrings = warningStrings.concat(warnings);
  }

  return {
    isSanePrice,
    isSaneQty,
    warnings: warningStrings.filter((value, index, arr) => arr.indexOf(value) === index)
  }
}

function orderMatcher(buy: boolean, orderbookPrice: any, price: number, quantity: number) {
  let remainingQuantity = 0;
  if (quantity > 0 && ((buy && price >= orderbookPrice.price) || (!buy && price <= orderbookPrice.price))) {
    remainingQuantity = quantity - orderbookPrice.quantity;
    return remainingQuantity < 0  ? 0 : remainingQuantity;
  }
  return -1;
}

function lastPriceComparison(prices: any, formData: OrderFormData, sanityCheck: SanityCheck) {
  if (prices.trades && prices.trades.length > 0) {
    const priceToCompare = prices.trades[0].execPrice; 
    return (!!sanityCheck.priceDeviationValue 
      ? isSane(formData.price, priceToCompare, sanityCheck, formData.buy) 
      : true);
  }
  return true;
}

function checkSanityRule(formData: OrderFormData, 
  prices: any, 
  sanityCheck: SanityCheck) {

    let sanePrice = true;
    let saneQty = true;
    let warnings = [];

    const side = formData.buy ? 'bidPrices' : 'askPrices';
    const oppositeSide = formData.buy ? 'askPrices' : 'bidPrices';

    let sideEmpty = false;
    let oppositeSideEmpty = false;

    // no price (Best Bid on buy/ Best Ask on sell) is available for the contract
    if (!prices.orders || !prices.orders[side] || prices.orders[side].length === 0) {
        if (sanityCheck.emptyOrderbook) {
          sanePrice = false;
          warnings.push(I18n.t('sanityChecks.emptyOrderbook'));
        }
        sideEmpty = true;
    }

    // no price on opposite side is available for the contract
    if (!prices.orders || !prices.orders[oppositeSide] || prices.orders[oppositeSide].length === 0) {
        oppositeSideEmpty = true;
    } else {
      const priceToCompare = prices.orders[oppositeSide][0].price;
      
      if (sanityCheck.invertedPrice) {
        if (formData.buy && formData.price > priceToCompare) {
          sanePrice = false;
          warnings.push(I18n.t('sanityChecks.invertedPriceBid'));
        } else if (!formData.buy && formData.price < priceToCompare) {
          sanePrice = false;
          warnings.push(I18n.t('sanityChecks.invertedPriceAsk'));
        }
      }
    
      if (sanityCheck.matchesOwnOrders) {
        const orderbook = prices.orders[oppositeSide];
        let remainingQuantity = parseFloat(''+formData.quantity);
        for (let i = 0; i < orderbook.length; i++) {
          remainingQuantity = orderMatcher(formData.buy, orderbook[i], formData.price, remainingQuantity);
          if (remainingQuantity >= 0) {
            if (orderbook[i].order.ownOrder) {
              sanePrice = false;
              warnings.push(I18n.t('sanityChecks.matchesOwnOrder'));
              break;
            }
          } else {
            break;
          }
        }
      }
    }
    // min/max quantity
    if (sanityCheck.minQuantityWarning && sanityCheck.minQuantity !== undefined && formData.quantity < sanityCheck.minQuantity) {
      saneQty = false;
      warnings.push(I18n.t('sanityChecks.minQuantity', {value: sanityCheck.minQuantity}));
    }
    if (sanityCheck.maxQuantityWarning && sanityCheck.maxQuantity !== undefined && formData.quantity > sanityCheck.maxQuantity) {
      saneQty = false;
      warnings.push(I18n.t('sanityChecks.maxQuantity', {value: sanityCheck.maxQuantity}));
    }
    // order value
    if (sanityCheck.orderValueWarning && sanityCheck.orderValue !== undefined) {
      const orderValue = formData.price * formData.quantity;
      if (orderValue > sanityCheck.orderValue) {
        sanePrice = false,
        saneQty = false,
        warnings.push(I18n.t('sanityChecks.orderValue', {value: sanityCheck.orderValue}));
      }
    }
    
    // price deviation
    const sign = (sanityCheck.priceDeviationMode === DeviationMode.ABSOLUTE ? '' : '%');
    switch (sanityCheck.priceDeviation) {
      
      case PriceDeviationType.LAST_PRICE : {
        const isSaneComparedToLastPrice = lastPriceComparison(prices, formData, sanityCheck);
        if (!isSaneComparedToLastPrice) {
          sanePrice = false;
          warnings.push(I18n.t('sanityChecks.lastPriceNotSane', {value: sanityCheck.priceDeviationValue + sign}));
        }
      } break;
      case PriceDeviationType.MID_PRICE : {
        if (!sideEmpty && !oppositeSideEmpty) {
          const priceToCompare = (prices.orders[oppositeSide][0].price + prices.orders[side][0].price) / 2;
          const isSaneComparedToMidPrice = isSane(formData.price, priceToCompare, sanityCheck, formData.buy);
          if (!isSaneComparedToMidPrice) {
            sanePrice = false;
            warnings.push(I18n.t('sanityChecks.midPriceNotSane', {value: sanityCheck.priceDeviationValue + sign}));
          }
        } else {
          const isSaneComparedToLastPrice = lastPriceComparison(prices, formData, sanityCheck);
          if (!isSaneComparedToLastPrice) {
            sanePrice = false;
            warnings.push(I18n.t('sanityChecks.lastPriceNotSane', {value: sanityCheck.priceDeviationValue + sign}));
          }
        }
      } break;
      case PriceDeviationType.ORDER_PRICE : {
        if (!sideEmpty) {
          const priceToCompare = prices.orders[side][0].price;
          const isSaneComparedToOrderPrice = isSane(formData.price, priceToCompare, sanityCheck, formData.buy);
          if (!isSaneComparedToOrderPrice) {
            sanePrice = false;
            warnings.push(I18n.t('sanityChecks.orderPriceNotSane', {value: sanityCheck.priceDeviationValue + sign}));
          }
        }
      } break;
      default: ;
    }
    
    return {
      sanePrice,
      saneQty,
      warnings
    }
  }