import { ValidationResult } from './model';
import { I18n } from 'react-redux-i18n';

export function numberValidation(originalValue: string, data: any): ValidationResult {
    let value = (originalValue + '').replace(',', '.').replace(/[^\-0-9.]/g, '');
    const pointPosition = value.indexOf('.');
    if (pointPosition > -1 && value.indexOf('.', pointPosition + 1) > 0) {
        value = value.substring(0, value.indexOf('.', pointPosition) - 1);
    }
    if (value.length > 9) { // Java Side restriction as range of Integer (-2147483648 - 2147483647) which is used in the EIM
        return new ValidationResult('', false, I18n.t('validation.notAnInteger', { input: originalValue }));
    }

    if (value.localeCompare(originalValue) !== 0) {
        return new ValidationResult(value, false, I18n.t('validation.notANumber', { input: originalValue }));
    }
    if ((value !== '' && value !== '-') && (isNaN(parseFloat(value)) || !value.match(/(^\-?)([0-9]*)(\.?)([0-9]+)?$/g))) {
        value = value.substring(0, value.length - 1);
        return new ValidationResult(value, false, I18n.t('validation.notANumber', { input: originalValue }));
    }
    return new ValidationResult(value, true, null);
}

export function decimalValidation(value: string, maxDecimals: number) {
    if (maxDecimals === 0 && value.includes('.')) {
        value = value.substring(0, value.length - 1);
        return new ValidationResult(value, false, I18n.t('validation.noDecimalsAllowed', { input: value }));
    }
    var decimals: number = decimalPlaces(value);
    if (decimals > maxDecimals) {
        value = value.substring(0, value.length - (decimals - maxDecimals));
        return new ValidationResult(value, false, I18n.t('validation.tooManyDecimals', { input: value, decimals: maxDecimals }));
    }
    return new ValidationResult(value, true, null);
}

function decimalPlaces(num: string): number {
    var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) { return 0; }
    return Math.max(
        0,
        // Number of digits right of decimal point.
        (match[1] ? match[1].length : 0)
        // Adjust for scientific notation.
        - (match[2] ? +match[2] : 0));
}

export function requiredValidation(value: string, data: any): ValidationResult {
    if (value === null || value === undefined || value === '') {
        return new ValidationResult(value, false, 'error.required');
    }
    return new ValidationResult(value, true, null);
}

export function largerThanZero(value: any, data: any): ValidationResult {
    if (parseFloat(value) <= 0) {
        return new ValidationResult(0, false, I18n.t('error.required'));
    }
    return new ValidationResult(value, true, null);
}