import { IFavorite } from '../models/favorite';
import * as Favorite from '../actions/favorite';
import { config } from '../../../main/config';

export interface State {
  [id: string]: IFavorite;
}

export const initialState: State = {};

export function reducer(state: State = initialState, action: Favorite.Action) {
  switch (action.type) {

    case Favorite.ActionTypes.LOAD: {
      const newFavoriteEntities = action.payload.reduce(
        (entities: { [id: string]: IFavorite }, favorite: IFavorite) => {
          return Object.assign(entities, {
            [favorite.name]: { ...favorite, isNew: false }
          });
        },
        {}
      );
      return { ...state, ...newFavoriteEntities };
    }

    case Favorite.ActionTypes.TOGGLE: {
      const { favorite, isFavorite } = action.payload;

      const oldFavorites = isFavorite
        ? Object.keys(state)
          .map(id => state[id])
          .map(f => ({ ...f, isNew: false }))
          .reduce((acc: any, f: IFavorite) => {
            acc[f.name] = f;
            return acc;
          }, {})
        : state;

      let newFavoriteEntities = { ...oldFavorites };

      if (isFavorite) {
        const visibleCount = Object.keys(oldFavorites)
          .map(id => oldFavorites[id])
          .reduce((acc: number, f: IFavorite) => acc + (f.isVisible ? 1 : 0), 0);
        favorite.isNew = true;
        favorite.isVisible = visibleCount < config.sidebarFavoritesListLimit;
        newFavoriteEntities[favorite.name] = favorite;
      } else {
        delete newFavoriteEntities[favorite.name];
      }

      return newFavoriteEntities;
    }

    case Favorite.ActionTypes.TOGGLE_FAVORITE_VISIBILITY: {
      const { favoriteId, isVisible } = action.payload;

      return Object.keys(state).reduce(
        (acc: { [id: string]: IFavorite }, key: string) => {
          const favorite = state[key];
          return {
            ...acc,
            [key]: favorite.id === favoriteId ? {
              ...favorite,
              isVisible
            } : favorite
          };
        }, {});
    }

    default:
      return state;
  }
}
