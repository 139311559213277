import * as React from 'react';
import { State } from '../../../main/reducers/rootReducer';
import { connect } from 'react-redux';
import ModalWindow from '../../modalWindow/components/modalWindow';
import { Translate, I18n } from 'react-redux-i18n';
import { passwordChange, clearPasswordChangeInformation } from '../actions/passwordChange';
import { v1 } from 'uuid';
import { getMessage, hasFailed, getPasswordComplexity, getPasswordComplexityMessage } from '../selectors/passwordChange';

export interface PasswordProps {
  modalVisible: boolean;
  resetPasswordNeeded: boolean;
  changePassword: (
    oldPassword: string,
    newPassword: string,
    correlationId: string
  ) => void;
  closeModal: () => void;
  clearPasswordChange: () => void;
  message: string | null;
  failed: boolean;
  passwordComplexity: RegExp;
  passwordComplexityErrorMessage: string;
}

export interface PasswordState {
  oldPassword: string;
  newPassword: string;
  repeatedPassword: string;
  isButtonTimeoutRunning: boolean;
  falseRepetition: boolean;
  invalidRegex: boolean;
}

export class PasswordChangeModalComponent extends React.Component<
  PasswordProps,
  PasswordState
  > {
  private timeouts: any;
  private oldInput: any;
  private newInput: any;
  private repeatInput: any;

  constructor(props: PasswordProps) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      repeatedPassword: '',
      isButtonTimeoutRunning: false,
      falseRepetition: false,
      invalidRegex: false
    };

    this.timeouts = [];
    this.oldInput = React.createRef();
    this.newInput = React.createRef();
    this.repeatInput = React.createRef();
    this.hideModal = this.hideModal.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.reactivateSubmitButtonAfterTimeout = this.reactivateSubmitButtonAfterTimeout.bind(
      this
    );
    this.clearTimeouts = this.clearTimeouts.bind(this);
  }

  hideModal() {
    this.props.closeModal();
  }

  handlePasswordChange(event: any) {
    const { passwordComplexity } = this.props;
    event.preventDefault();
    this.clearTimeouts();
    if (this.state.newPassword.localeCompare(this.state.repeatedPassword) !== 0) {
      this.setState(prevState => {
        return {
          ...prevState,
          isButtonTimeoutRunning: false,
          falseRepetition: true,
          invalidRegex: false
        };
      });
    } else {
      const unencryptedOld = this.state.oldPassword;
      const unencryptedNew = this.state.newPassword;
      const correlationId = v1();
      this.setState(prevState => {
        return {
          ...prevState,
          isButtonTimeoutRunning: true,
          falseRepetition: false,
          invalidRegex: false
        };
      });
      this.timeouts = [
        ...this.timeouts,
        setTimeout(() => this.reactivateSubmitButtonAfterTimeout(), 2000)
      ];
      if (passwordComplexity.test(unencryptedNew)) {
        this.props.changePassword(unencryptedOld, unencryptedNew, correlationId);
      } else {
        this.setState(prevState => {
          return {
            ...prevState,
            isButtonTimeoutRunning: false,
            invalidRegex: true,
            falseRepetition: false
          };
        });
      }
    }
  }

  handleInputChange(event: any) {
    event.preventDefault();
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  }

  reactivateSubmitButtonAfterTimeout() {
    if (this.state.isButtonTimeoutRunning) {
      this.setState(prevState => {
        return {
          ...prevState,
          isButtonTimeoutRunning: false
        };
      });
    }
  }

  clearTimeouts() {
    this.timeouts.map((t: any) => clearTimeout(t));
  }

  componentWillUnmount() {
    this.hideModal();
  }

  render() {
    const { modalVisible, failed, message, resetPasswordNeeded, passwordComplexityErrorMessage } = this.props;
    const form = this.state;
    const disableSubmit = form.isButtonTimeoutRunning && !message;
    const notificationType = failed || this.state.falseRepetition || this.state.invalidRegex ? 'danger' : 'info';
    const defaultComplexitiyMessageExists: boolean = passwordComplexityErrorMessage !== null && passwordComplexityErrorMessage.length > 0;

    if (!modalVisible) {
      this.setState(prevState => {
        return {
          oldPassword: '',
          newPassword: '',
          repeatedPassword: '',
          isButtonTimeoutRunning: false,
          falseRepetition: false
        };
      });
      this.clearTimeouts();
      this.props.clearPasswordChange();
    }
    return (
      <div className="meet-settings__modal">
        <ModalWindow
          id={resetPasswordNeeded ? 'password-reset' : 'password-change'}
          onModalClose={() => this.hideModal()}
          isOpen={modalVisible}
          title={resetPasswordNeeded ? 'password.resetTitle' : 'password.title'}
        >
          <form onSubmit={this.handlePasswordChange}>
            <div
              className={'alert alert-' + notificationType}
              role="alert"
              style={{ display: message || this.state.falseRepetition || (this.state.invalidRegex && !defaultComplexitiyMessageExists) ? 'block' : 'none' }}
            >
              {this.state.falseRepetition ? I18n.t('password.faultyRepetition') : this.state.invalidRegex ? I18n.t('password.invalidRegEx', { regex: this.props.passwordComplexity }) : message}
            </div>
            <div
              className={'alert alert-' + notificationType}
              role="alert"
              style={{ display: this.state.invalidRegex && defaultComplexitiyMessageExists ? 'block' : 'none' }} 
              dangerouslySetInnerHTML={{ __html: this.props.passwordComplexityErrorMessage }}
            />
            <div className="form-group">
              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  <Translate value="password.oldPassword" />
                </label>
                <div className="col-sm-5 input-group">
                  <input
                    ref={this.oldInput}
                    name="oldPassword"
                    type="password"
                    value={form.oldPassword}
                    id=".oldPassword"
                    placeholder={I18n.t('password.oldPassword')}
                    className="form-control"
                    onChange={this.handleInputChange}
                    autoFocus={true}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  <Translate value="password.newPassword" />
                </label>
                <div className="col-sm-5 input-group">
                  <input
                    ref={this.newInput}
                    name="newPassword"
                    type="password"
                    value={form.newPassword}
                    id=".newPassword"
                    placeholder={I18n.t('password.newPassword')}
                    className="form-control"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-5 col-form-label">
                  <Translate value="password.repeatPassword" />
                </label>
                <div className="col-sm-5 input-group">
                  <input
                    ref={this.repeatInput}
                    name="repeatedPassword"
                    type="password"
                    value={form.repeatedPassword}
                    id=".repeatedPassword"
                    placeholder={I18n.t('password.repeatPassword')}
                    className="form-control"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end w-100">
              <input
                type="submit"
                className="btn btn-approval w150"
                value={I18n.t('password.submit')}
                disabled={disableSubmit}
              />
            </div>
          </form>
        </ModalWindow>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  message: getMessage(state),
  failed: hasFailed(state),
  passwordComplexity: getPasswordComplexity(state),
  passwordComplexityErrorMessage: getPasswordComplexityMessage(state)
});

const mapDispatchToProps = {
  changePassword: passwordChange,
  clearPasswordChange: clearPasswordChangeInformation
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(PasswordChangeModalComponent);
