import { StompService, StompClient } from '../../main/services/stompService';
import { Observable, of } from 'rxjs';
import { GenericRequest } from '../../main/models/application';
import { EnterQuoteRequestRequest, AnswerQuoteRequestRequest, DeleteQuoteRequestRequest, RejectQuoteRequestRequest, AcceptQuoteRequestRequest } from '../models/request';

export default class RequestsService extends StompService {
  
  constructor() {
    super(StompClient);
  }
  public sendRequestsInquiry(message: GenericRequest): Observable<any> {
    return of(
      this.sendMessage('/app/im/generic', JSON.stringify(message))
    );
  }

  public subscribeRequests(): Observable<any> {
    return this.subscribe('/user/topic/im/sub/quoterequests');
  }

  public inquireRequests(): Observable<any> {
    return this.subscribe('/user/topic/im/quoterequests');
  }

  public subscribeQuoteRequestAnswers(): Observable<any> {
    return this.subscribe('/user/topic/im/answer/quoterequests');
  }

  public sendEnterQuoteRequest(message: EnterQuoteRequestRequest): Observable<any> {
    return of(
      this.sendMessage('/app/im/quoterequests/enter', JSON.stringify(message))
    );
  }

  public answerQuoteRequest(message: AnswerQuoteRequestRequest): Observable<any> {
    return of(
      this.sendMessage('/app/im/quoterequests/answer', JSON.stringify(message))
    );
  }

  public sendCancelQuoteRequest(message: DeleteQuoteRequestRequest): any {
    return of(
      this.sendMessage('/app/im/quoterequests/delete', JSON.stringify(message))
    );
  }

  public sendDeclineQuoteRequest(message: RejectQuoteRequestRequest): any {
    return of(
      this.sendMessage('/app/im/quoterequests/decline', JSON.stringify(message))
    );
  }

  public sendAcceptQuoteRequest(message: AcceptQuoteRequestRequest): any {
    return of(
      this.sendMessage('/app/im/quoterequests/accept', JSON.stringify(message))
    );
  }
}
