import { State } from '../../../main/reducers/rootReducer';
import { createSelector } from 'reselect';

export const getMessangerState = (state: State) => state.messager;
export const getMessagesEntities = createSelector(
  getMessangerState,
  s => s.messages
);

export const getMessages = createSelector(
  [getMessagesEntities, (state: State, ids: string[]) => ids],
  (entites, ids) =>
    ids.reduce((acc: any, id: string) => {
      return entites[id] ? acc.concat(entites[id]) : acc;
    }, [])
);
