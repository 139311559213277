import { config } from '../../main/config';
import {
  ITable,
  ITableColumn,
  ITableSort,
  TableAction,
  ITableSearchTag,
  ITableRow,
  prepareColumns
} from '../../shared/ui/models/table';
import { ComponentType } from '../../shared/ui/models/component';
import { FilterType } from '../../shared/ui/models/tableSpecificFilters';
import { MeetQuoteRequestTableRowPopover } from '../components/quoteRequestPopover';

export class RequestTable implements ITable {
  static index = 1;

  id: string;
  parentId: string;
  type: ComponentType = ComponentType.Request;
  actions: TableAction[] = [
    TableAction.SEARCH,
    TableAction.DOWNLOAD_CSV,
    TableAction.HIDDING_ROWS,
    TableAction.PINNING_ROWS
  ];
  columns: ITableColumn[] = [];
  sorting: ITableSort[] = [];
  hiddenColumnNames: string[] = ['id', 'state', 'validTo', 'comment', 'reference'];
  hiddenRows: any[] = [];
  tags: ITableSearchTag[] = [];
  showHeadlines: boolean = true;
  showingHidden: boolean = false;
  filters = {...{[FilterType.HIDE_INACTIVE_QUOTE_REQUESTS]: false}};
  cellActionMapping = {};
  cellActions = {};
  cellActionClick = '';
  pinnedRows = [];

  constructor(parentId: string, customId?: string) {
    this.id = customId
    ? customId
    : new Date().getUTCMilliseconds().toString() + RequestTable.index++;
    this.parentId = parentId;

    this.columns = config.ui.requestTable.columns.map(c => { return {...c}; });
    this.sorting = config.ui.requestTable.defaultSorting;

    if (customId) {
      this.hiddenColumnNames = prepareColumns(config.ui.requestTable, this.columns, true);
      this.sorting = config.ui.requestTable.defaultSorting;
    } else {
      this.hiddenColumnNames = prepareColumns(config.ui.requestTable, this.columns, false);
      this.sorting = config.ui.requestTable.defaultSorting;
    }
  
    this.cellActionMapping = config.ui.requestTable.cellActions;
    this.cellActionClick = config.ui.requestTable.cellActionClick;
    this.cellActions = {
      showPopover: (rowIndex: string, columnKey: string, tableComponent: any, row: ITableRow, event: any) => {
        tableComponent.hidePopover();
        tableComponent.showPopover(MeetQuoteRequestTableRowPopover, row, event);
      },
    };
  
  }
}
