import { LogLevel } from '../models/logger';
import { NotificationType, Notification } from '../../notifications/models/notification';
import { LogAction } from '../../../main/middlewares/logger';
import { createNotification } from '../../notifications/actions/notifications';

export function convertSeverityToLogLevel(severity: String) {
    var convertedLevel = LogLevel.ERROR;
    if (!severity) {
        return convertedLevel;
    }
    if (severity === 'WARNING') {
        return LogLevel.WARN;
    }
    return LogLevel[convertedLevel];
}

export function convertNotificationTypeToLogLevel(notificationType: NotificationType) {
    var convertedLevel = LogLevel.ERROR;
    if (notificationType === NotificationType.INFO) {
        convertedLevel = LogLevel.INFO;
    }
    if (notificationType === NotificationType.WARNING) {
        convertedLevel = LogLevel.WARN;
    }
    return convertedLevel;
}

export function convertLogLevelToNotificationType(logLevel: LogLevel) {
    let notificationType = NotificationType.INFO;
    if (logLevel >= LogLevel.INFO) {
        notificationType = NotificationType.INFO;
    } else if (logLevel >= LogLevel.WARN) {
        notificationType = NotificationType.WARNING;
    } else {
        notificationType = NotificationType.DANGER;
    }
    return notificationType;
}

export function convertLogToNotification(log: LogAction) {
    return createNotification(new Notification(convertLogLevelToNotificationType(log.logLevel), log.logMessage, log.confirmationRequired));
}