import { ActionTypes, Action } from '../actions/notifications';
import * as Authentication  from '../../../authentication/actions/authentication';
import {
  INotification,
  NotificationCoditionInstrument
} from '../models/notification';

export interface State {
  ids: string[];
  entities: { [id: string]: INotification };
  config: { [key: string]: any };
}

export const initialState: State = {
  ids: [],
  entities: {},
  config: {
    condition1: {
      checked: false,
      amount: 20
    },
    condition2: {
      checked: false,
      depth: 100
    },
    condition3: {
      checked: false,
      percentage: 150,
      minutes: 10,
      selectedInstrument: NotificationCoditionInstrument.ALL
    },
    condition4: {
      checked: false
    }
  }
};

export function reducer(state: State = initialState, action: Action | Authentication.Action) {
  switch (action.type) {
    case ActionTypes.LOAD_CONFIG: {
      return {
        ...state,
        config: action.payload
      };
    }

    case ActionTypes.CREATE: {
      const notification: INotification = action.payload;

      return {
        ...state,
        ids: [...state.ids, notification.id],
        entities: {
          ...state.entities,
          [notification.id]: notification
        }
      };
    }

    case ActionTypes.SAVE_CONFIG: {

      return {
        ...state,
        config: action.payload
      };
    }

    case ActionTypes.REMOVE: {
      const { [action.payload]: deletedValue, ...newState } = state.entities;
      return {
        ...state,
        ids: state.ids.filter(id => id !== action.payload),
        entities: newState
      };
    }

    case Authentication.ActionTypes.AUTHENTICATION_REQUEST: {
      return initialState;
    }

    default:
      return state;
  }
}
