import { createStore, AnyAction, Store, applyMiddleware } from 'redux';
import { connectionReducer, ConnectionState, state } from '../reducers/connectionQueue';

import { loggerMiddleWare } from '../../main/middlewares/logger';

export function configureStore() {
  const middleware = [/*loggerMiddleWare*/];
  if (process.env.NODE_ENV !== 'production') {
    const store = createStore(state, applyMiddleware(...middleware));
    return store;
  } else {
    const store = createStore(connectionReducer);
    return store;
  }  
}

const connectionStore: Store<ConnectionState, AnyAction> = configureStore();

export default connectionStore;