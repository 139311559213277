import { connect } from 'react-redux';
import {
    triggerColumnNames,
    triggerHeadlines,
    sort,
    hideRows,
    showRows,
    triggerSearchTags,
    pinRows,
    unpinRows
} from '../../shared/ui/actions/table';
import { triggerFilter } from '../../shared/ui/actions/tab';

import UITable from '../../shared/ui/components/table/components/uiTableComponent';
import { ComponentType } from '../../shared/ui/models/component';
import { getTableForTab } from '../../shared/ui/selectors/ui';
import { State } from '../../main/reducers/rootReducer';
import { getBulkOrderRows } from '../selectors/bulkOrders';

const mapStateToProps = (state: State) => ({
    table: getTableForTab(state, ComponentType.BulkOrder, ComponentType.BulkOrder),
    rows: getBulkOrderRows(
        state,
        getTableForTab(state, ComponentType.BulkOrder, ComponentType.BulkOrder)
    )
});

const mapDispatchToProps = {
    sort: sort,
    triggerColumnNames: triggerColumnNames,
    triggerHeadlines: triggerHeadlines,
    triggerFilter: triggerFilter,
    triggerSearchTags: triggerSearchTags,
    hideRows: hideRows,
    showRows: showRows,
    pinRows: pinRows,
    unpinRows: unpinRows
};

export default connect<any, any, any, any>(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { pure: true }
)(UITable);
