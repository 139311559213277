import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { ActionTypes as AppActionTypes } from '../../../authentication/actions/connection';
import I18nService from '../translations';
import * as Rx from 'rxjs';
import { loadTranslationsError, loadTranslationsSuccess, ActionTypes, setApplicationLocale, loadLocalesSuccess } from '../actions/translations';
import { ActionTypes as AuthActionTypes } from '../../../authentication/actions/authentication';
import { Store } from 'redux';
import { State } from '../../../main/reducers/rootReducer';
import { I18n, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import { Dispatch } from 'redux';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import store from '../../../main/store/store';

const i18nService = new I18nService();

export const loadDefault: any = (
    actions$: ActionsObservable<any>,
    state: StateObservable<State>
  ) => {
    return actions$.pipe(
      filter(action => action.type === AppActionTypes.APPLICATION_START),
      switchMap(() => {
        
        return i18nService
          .requestDefaultTranslations().pipe(
          map((content) => {
            syncTranslationWithStore(store);
            return loadTranslationsSuccess(content);
            
          }));
      }),
      catchError(error => {
        return  Rx.of(loadTranslationsError(error));
      })
    );
  };

export const loadDefaultAfterReconnect: any = (
    actions$: ActionsObservable<any>,
    state: StateObservable<State>
  ) => {
    return actions$.pipe(
      filter(action => action.type === AuthActionTypes.AUTHENTICATION_SUCCESS),
      switchMap(() => {
        if (Object.keys(state.value.i18n.translations).length === 0 ) {
          return i18nService
            .requestDefaultTranslations().pipe(
              map((content) => {
                syncTranslationWithStore(store);
                return loadTranslationsSuccess(content);
                
              }),
              catchError(error => {
                return  Rx.of(loadTranslationsError(error));
              })
            );
        }
        return Rx.empty();
      })
    );
  }; 
  
export const loadLocalesAfterReconnect: any = (
    actions$: ActionsObservable<any>,
    state: StateObservable<State>
  ) => {
    return actions$.pipe(
      filter(action => action.type === AuthActionTypes.AUTHENTICATION_SUCCESS),
      switchMap(() => {
        if (store.getState().i18n.locales.length === 0) {
          return i18nService
            .requestAvailableLocales().pipe(
              map((content) => {
                return loadLocalesSuccess(content);
              }),
              catchError(error => {
                return  Rx.of(loadTranslationsError(error));
              })
            );
        }
        return Rx.empty();
      })
    );
  };   

export const loadAvailable: any = (
    actions$: ActionsObservable<any>,
    state: StateObservable<State>
  ) => {
    return actions$.pipe(
      filter(action => action.type === AppActionTypes.APPLICATION_START),
      switchMap(() => {
        return i18nService
          .requestAvailableLocales().pipe(
            map((content) => {
              return loadLocalesSuccess(content);
            })
          );
      }),
      catchError(error => {
        return  Rx.of(loadTranslationsError(error));
      })
    );
  };  

export const loadTranslations: any = (
    actions$: ActionsObservable<any>,
    state: StateObservable<State>
  ) => {
    return actions$.pipe(
      filter(action => action.type === ActionTypes.LOAD_TRANSLATIONS),
      map(action => action.payload),
      switchMap((locale) => {
        return i18nService
          .requestTranslations(locale).pipe(
            map((content) => {
              syncTranslationWithStore(store);
              return loadTranslationsSuccess(content);
              
            })
          );
      })
    );
  };  

export const translationsLoaded: any = (
    actions$: ActionsObservable<any>,
    state: StateObservable<State>
  ) => {
    return actions$.pipe(
      filter(action => action.type === ActionTypes.LOAD_TRANSLATIONS_SUCCESS),
      map(action => action.translations),
      switchMap((action) => {
        return Rx.of(setApplicationLocale(Object.keys(action)[0]));
      })
    );
  };

export const updateComponents: any = (
    actions$: ActionsObservable<any>,
    state: StateObservable<State>
  ) => {
    return actions$.pipe(
      filter(action => action.type === ActionTypes.SET_APP_LOCALE),
      switchMap((action) => {
        setLocale(action.locale)(<Dispatch<any>> ((payload) => null )); // trigger update components action of i18nify (library used behind react-redux-i18n)
        return Rx.empty();
    })
    );
  };

export const i18nEpic = combineEpics(
  loadDefault, 
  translationsLoaded, 
  updateComponents, 
  loadTranslations, 
  loadAvailable, 
  loadLocalesAfterReconnect, 
  loadDefaultAfterReconnect
);