import { State } from '../../../main/reducers/rootReducer';
import { argsSelectorCreator } from '../../utils/selectors/agrSelector';
import { IFavorite } from '../models/favorite';

export const getFavoriteState = (state: State) => state.favorites;

export const getAvailableFavorites = argsSelectorCreator(getFavoriteState, (favorites) => favorites ? (<any> Object).values(favorites) : []);

// export const getFavoriteNames = argsSelectorCreator(
//     getAvailableFavorites, (favorites: IFavorite[]) => {
//         const favs = favorites ? favorites.map(f => f.name) : [];
//         return favs;
//     }
// );