import {
  ActionsObservable,
  combineEpics,
  StateObservable
} from 'redux-observable';
import { StompService, StompClient } from '../../../main/services/stompService';
import { ActionTypes as Connection } from '../../../authentication/actions/connection';
import { ActionTypes as SettingsActions } from '../../../main/actions/settings';
import { ActionTypes as Authentication } from '../../../authentication/actions/authentication';
import { State } from '../../../main/reducers/rootReducer';
import * as Notifications from '../actions/notifications';
import { loadConfig, addLogInformationToServerNotification } from '../actions/notifications';
import * as Rx from 'rxjs';
import { timer } from 'rxjs';
import {
  SaveSettingsRequest,
  LoadSettingsResponse
} from '../../../main/models/application';
import { switchMap, filter, map, catchError, takeUntil, mergeMap, delay } from 'rxjs/operators';
import { INotification, UpdateTokenRequest, NotificationResponse } from '../models/notification';
import { config } from '../../../main/config';
import { formatParameters } from '../helper/helper';

const stompService = new StompService(StompClient);

export const configSave: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Notifications.ActionTypes.SAVE_CONFIG),
    map(action => action.payload),
    switchMap((conf: any) => {
      stompService.saveSettings(<SaveSettingsRequest> {
        settings: {
          notificationsJson: JSON.stringify(conf)
        }
      });
      return Rx.empty();
    })
  );
};

export const configLoad: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === SettingsActions.LOAD_SETTINGS),
    map(action => action.payload),
    switchMap((content: LoadSettingsResponse) => {
      if (
        !!content &&
        !!content.settings &&
        !!content.settings.notificationsJson
      ) {
        return Rx.of(
          loadConfig(JSON.parse(content.settings.notificationsJson))
        );
      }
      return Rx.empty();
    })
  );
};

export const subscribeNotifications: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_SUCCESS),
    switchMap(() => {
      return stompService.subscribe('/user/topic/notifications').pipe(
        map((content: NotificationResponse) => {
          return addLogInformationToServerNotification(formatParameters(content));
        }),
        catchError(error => {
          return Rx.of(null);
        }),
        takeUntil(
          actions$.pipe(
            filter(
              action => action.type === Authentication.AUTHENTICATION_LOGOUT
            )
          )
        )
      );
    })
  );
};

export const removeNotification: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Notifications.ActionTypes.CREATE),
    map(action => action.payload),
    mergeMap((notification: INotification) => {
      /// + 300 for animation delay
      if (notification.confirmationRequired) {
        return Rx.empty();
      }
      return timer(config.notifications.removeTimer + 300).pipe(
        map((_notifivation) => {
          return Notifications.removeNotification(notification.id);
        })
      );
    })
  );
};

export const updateNotificationToken: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Notifications.ActionTypes.NOTIFICATION_TOKEN_RECEIVED),
    map(action => action.payload),
    switchMap((token: string) => {
      localStorage.setItem('notification-token', token);
      stompService.updateNotificationToken(<UpdateTokenRequest> {
        token: token
      });
      return Rx.empty();
    })
  );
};

export const notificationsEpic = combineEpics(
  configSave,
  configLoad,
  subscribeNotifications,
  removeNotification,
  updateNotificationToken
);

