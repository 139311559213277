import * as React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import ValidationService from '../../validation/service';
import { ValidationResult, Validation } from '../../validation/model';
import { requiredValidation, numberValidation } from '../../validation/validators';
import { LogLevel } from '../../logger/models/logger';

const Input = ({ parameter, onChange, name, formId }: any) => {
    switch (parameter.type) {
        case 'number':
        case 'decimal':
            return (
                <input
                    name={name}
                    onChange={(e: any) => onChange(e, parameter.type)}
                    type="text"
                    value={parameter.value}
                />
            );
        case 'radio':
            return (
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <label
                        className={`btn option1 ${parameter.option1.localeCompare(parameter.value) === 0 ? `active` : `inactive`}`}
                    >
                        <input
                            type="radio"
                            name={name}
                            id="option1"
                            autoComplete="off"
                            checked={parameter.option1.localeCompare(parameter.value) === 0}
                            onClick={(e: any) => onChange(e, parameter.type, parameter.option1)}
                        />
                        <Translate value={formId + `.` + parameter.option1} />
                    </label>
                    <label
                        className={`btn option2 ${parameter.option2.localeCompare(parameter.value) === 0 ? `active` : `inactive`}`}
                    >
                        <input
                            type="radio"
                            name={name}
                            id="option2"
                            autoComplete="off"
                            checked={parameter.option2.localeCompare(parameter.value) === 0}
                            onClick={(e: any) => onChange(e, parameter.type, parameter.option2)}
                        />
                        <Translate value={formId + `.` + parameter.option2} />
                    </label>
                </div>
            );
        case 'selection':
            const options = Object.keys(parameter.options).map(k => {
                const key = formId + '.' + k;
                return (<option key={key} value={parameter.options[k]}> {I18n.t(key)} </option>);
            });
            return (
                <select
                    value={parameter.value}
                    onChange={(e: any) => onChange(e, parameter.type)}
                    name={name}
                    className="form-control"
                >
                    {options}
                </select>
            );
        case 'checkbox':
            return (
            <div className="form-check d-flex align-self-center">
                <input
                    id={name}
                    name={name}
                    type="checkbox"
                    checked={parameter.value}
                    onChange={e =>
                    onChange(
                        e,
                        parameter.type
                    )
                    }
                />
                <label htmlFor={name}>&nbsp;</label>
            </div>);
        default:
        case 'text':
            return (
                <input
                    name={name}
                    onChange={(e: any) => onChange(e, parameter.type)}
                    type="text"
                    value={parameter.value}
                />
            );
    }
};

const InputFormGroup = ({ formId, parameter, name, onChange, onReset }: any) => {
    return (
        <div className="form-group row">
            <label
                className="col-5"
                data-toggle="tooltip"
                title={I18n.t(`${formId}.tooltip.${name}`)}
            >
                <Translate value={`${formId}.parameter.${name}`} />
            </label>
            <React.Fragment>
                <div className="col-5">
                    <div className={`input-group`}>
                        <Input parameter={parameter} onChange={onChange} name={name} />
                    </div>
                </div>
                <div className="col-1">
                    <i
                        className="oi oi-action-undo active"
                        onClick={(e: any) => onReset(name)}
                        data-toggle="tooltip"
                        title={I18n.t('settings.reset')}
                    />
                </div>
            </React.Fragment>
        </div>
    );
};

export interface Props {
    settings: { [key: string]: { [x: string]: any } }; // settings: type - value
    defaultSettings: { [key: string]: { [x: string]: any } };
    onSubmit: (settings: any) => void;
    formId: string;
    informUser: (notification: string, level: LogLevel) => void;
    onChange: (settings) => void;
}

export interface State {
    localSettings: { [key: string]: { [x: string]: any } };
}

export default class GeneralConfigFormComponent extends React.Component<
    Props,
    State
    > {
    ref: any;
    validations: Validation;
    validationService: ValidationService;
    constructor(props: Props) {
        super(props);
        this.ref = React.createRef();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnReset = this.handleOnReset.bind(this);

        this.validations = {
            translationPrefix: this.props.formId,
            rules: {
                text: [requiredValidation],
                number: [numberValidation],
                decimal: [numberValidation],
                radio: [requiredValidation],
                selection: [requiredValidation]
            }
        };

        this.validationService = new ValidationService();
    }

    componentWillMount() {
        let defaults: { [key: string]: { [x: string]: any } } = {};
        Object.keys(this.props.defaultSettings).map((def: any) => {
            if (this.props.settings[def]) {
                defaults[def] = this.props.settings[def];
            } else {
                defaults[def] = this.props.defaultSettings[def];
            }
        });
        this.setState({
            localSettings: defaults
        });
    }

    handleInvalidInput(currentTarget: string, result: string | null) {
        this.props.informUser(
            result ? result : I18n.t('configuration.error', { parameter: currentTarget }),
            LogLevel.WARN
        );
    }

    handleInputChange(
        event: any, inputType: string, radioValue?: string
    ) {
        const currentTarget = event.target.name;
        let value;
        if (inputType === 'checkbox') {
            value = event.target.checked
        } else {
            value = radioValue ? radioValue : event.target.value
        }
        // this.ref.current.focus();

        let validationValue: ValidationResult = this.validationService.format(
            this.validations.rules[inputType],
            value
        );
        if (!validationValue.valid) {
            this.handleInvalidInput(currentTarget, validationValue.message);
        }
        value = validationValue.value;

        const newSettings = {...this.state.localSettings,
            [currentTarget]: {
                ...this.state.localSettings[currentTarget],
                'value': value
            }};

        this.props.onChange({general: newSettings});

        this.setState({
            ...this.state,
            localSettings: newSettings
        });
    }

    handleOnReset(target: string) {
        const selectedSetting = this.props.defaultSettings[target];
        this.setState({
            ...this.state,
            localSettings: {
                ...this.state.localSettings,
                [target]: {
                    ...this.state.localSettings[target],
                    ...selectedSetting
                }
            }
        });
    }

    handleSubmit(event: any) {
        event.preventDefault();
        this.props.onSubmit({ general: this.state.localSettings});
    }

    handleKeyDown(event: any) {
        if (event.key === 'Enter') {
            this.handleSubmit(event);
        }
    }

    render() {
        const { localSettings } = this.state;
        const keys = Object.keys(localSettings);
        const inputs = keys.map(k => (
            <InputFormGroup
                name={k}
                key={k}
                parameter={localSettings[k]}
                onChange={this.handleInputChange}
                onReset={this.handleOnReset}
                formId={this.props.formId}
            />
        ));
        return (
            <div>
                <form
                    id={this.props.formId}
                    style={{ position: 'relative' }}
                    onSubmit={this.handleSubmit}
                >
                    <div className="settings-scroll">
                        <div className="form-group row">
                            <label className="col-sm-5 col-form-label">
                                {/* <Translate value={this.props.formId + '.elementName'} /> */}
                            </label>
                            <label className="col-sm-3 col-form-label  text-center">
                                <Translate value={'settings.setting'} />
                            </label>
                            <label className="col-sm-1 col-form-label  text-center">
                                &nbsp;
                            </label>
                        </div>
                        {inputs}
                    </div>
                    <div className="d-flex">
                        <button
                            className="btn btn-approval w150 ml-auto"
                            type="submit"
                            ref={this.ref}
                            autoFocus={true}
                        >
                            <Translate value="settings.save" />
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}
