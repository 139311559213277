import { State } from '../../main/reducers/rootReducer';
import { createSelector } from 'reselect';

const getAuthState = ((state: State) => state.auth);

/**
 * Get authorized state 
 * 
 * return [Boolean]
 */
export const getAuthorizedStatus = createSelector([getAuthState], s => s.authorized);

/**
 * Get login form data
 * return [LoginFormData]
 */
export const getLoginData = createSelector([getAuthState], s => s.loginData);

/**
 * Get user object (LoginResponse)
 * return [LoginResponse]
 */
export const getUser = createSelector([getAuthState], s => s.user);

/**
 * Get authorized state 
 * 
 * return [Boolean]
 */
export const getLoginPendingState = createSelector([getAuthState], s => s.loginPending);

/**
 * Get user permissions
 * 
 * return [Boolean]
 */
export const getUserPermissions = createSelector([getAuthState], s => !s.permissions ? [] : s.permissions);

/**
 * Is user allowed to modify profile
 * 
 * return [Boolean]
 */
export const isProfileModificationAllowed = createSelector([getAuthState], s => s.modificationAllowed);

/**
 * get jwt token
 * 
 * return [Boolean]
 */
export const getJwtToken = createSelector([getAuthState], s => s.loginData ? s.loginData.token : '  ');

/**
 * Is user admin user for profile modification
 * 
 * return [Boolean]
 */
export const isProfileAdmin = createSelector([getAuthState], s => s.isProfileAdmin);

export const isColorConfigurationAllowed = createSelector([getAuthState], s => s.colorModificationAllowed);

export const isTradeReportingUser = createSelector([getAuthState], s => s.isTradeReportingUser);

export const isPasswordExpired = createSelector([getAuthState], s => s.isPasswordExpired);

export const getTimeUntilNextLoginAttempt = createSelector([getAuthState], s => s.timeUntilNextLoginAttempt);

export const isAtLeastOneConfigEnabled = createSelector(
    isProfileModificationAllowed, isColorConfigurationAllowed, (generalSettingsEnabled, colorConfigurationEnabled) => {
      return generalSettingsEnabled || colorConfigurationEnabled;
    }
  );
