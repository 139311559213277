import { IQuadrant } from '../models/analyticsPanel';
export class QuadrantsCheck {
  private ids: string[];
  constructor(quadrants: { [key: number]: IQuadrant }) {
    this.ids = Object.keys(quadrants);
  }

  public isExists(id: string): boolean {
    return this.ids.indexOf(id) !== -1;
  }

  public areExists(ids: string[]): boolean {
    return ids.reduce((result: boolean, id: string) => {
      if (result) {
        return result;
      }
      return this.ids.indexOf(id) !== -1;
    }, false);
  }

  public containsAll(ids: string[]): boolean {
    return ids.every(id => this.ids.indexOf(id) !== -1);
  }
}

// PUBLIC
export const updateQuardrantsSizes = (
  quadrants: {
    [key: number]: IQuadrant;
  },
  axisX: number,
  axisY: number
) => {
  const qc = new QuadrantsCheck(quadrants);

  return Object.keys(quadrants).reduce(
    (acc: { [key: number]: IQuadrant }, key: string) => {
      const getWidth = () => {
        switch (key) {
          case '1':
            return qc.isExists('2') ? axisX : 100;
          case '2':
            return qc.isExists('1') ? 100 - axisX : 100;
          case '3':
            return qc.isExists('4') ? axisX : 100;
          case '4':
            return qc.isExists('3') ? 100 - axisX : 100;
          default:
            return 100;
        }
      };

      const getHeight = () => {
        switch (key) {
          case '1':
            return qc.areExists(['3', '4']) ? axisY : 100;
          case '2':
            return qc.areExists(['3', '4']) ? axisY : 100;
          case '3':
            return qc.areExists(['1', '2']) ? 100 - axisY : 100;
          case '4':
            return qc.areExists(['1', '2']) ? 100 - axisY : 100;
          default:
            return 100;
        }
      };

      return {
        ...acc,
        [key]: {
          ...quadrants[Number(key)],
          size: {
            width: getWidth(),
            height: getHeight()
          }
        }
      };
    },
    {}
  );
};

export const updateQuardrantsSizesOnMove = (
  quadrants: IQuadrant[],
  axisX: number,
  axisY: number,
  qc: QuadrantsCheck
) => {
  return quadrants.reduce((acc: IQuadrant[], quadrant: IQuadrant) => {
    const getWidth = () => {
      switch (quadrant.id) {
        case 1:
          return qc.isExists('2') ? axisX : 100;
        case 2:
          return qc.isExists('1') ? 100 - axisX : 100;
        case 3:
          return qc.isExists('4') ? axisX : 100;
        case 4:
          return qc.isExists('3') ? 100 - axisX : 100;
        default:
          return 100;
      }
    };

    const getHeight = () => {
      switch (quadrant.id) {
        case 1:
          return qc.areExists(['3', '4']) ? axisY : 100;
        case 2:
          return qc.areExists(['3', '4']) ? axisY : 100;
        case 3:
          return qc.areExists(['1', '2']) ? 100 - axisY : 100;
        case 4:
          return qc.areExists(['1', '2']) ? 100 - axisY : 100;
        default:
          return 100;
      }
    };

    return [
      ...acc,
      {
        ...quadrant,
        size: {
          width: getWidth(),
          height: getHeight()
        }
      }
    ];
  }, []);
};

export const setResizers = (
  axisX: number,
  axisY: number,
  qc: QuadrantsCheck
) => {
  return {
    x: {
      visible:
        qc.containsAll(['1', '3']) ||
        qc.containsAll(['2', '4']) ||
        qc.containsAll(['1', '4']) ||
        qc.containsAll(['2', '3']),
      position: axisX,
      size:
        (qc.isExists('1') || qc.isExists('2')) && !qc.containsAll(['1', '2'])
          ? axisY
          : (qc.isExists('3') || qc.isExists('4')) &&
            !qc.containsAll(['3', '4'])
          ? -100 + axisY
          : 0
    },
    y: {
      visible: qc.containsAll(['1', '2']) || qc.containsAll(['3', '4']),
      position: axisY,
      size: 100
    }
  };
};
