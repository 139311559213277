import * as Actions from '../actions/passwordChange';
import * as Authentication from '../../../authentication/actions/authentication';

export interface State {
    message: string | null;
    failed: boolean;
    passwordComplexity: RegExp;
    passwordComplexityErrorMessage: string;
}

export const initialState: State = {
    message: null,
    failed: false,
    passwordComplexity: new RegExp(''),
    passwordComplexityErrorMessage: ''
};

export function reducer(
    state: State = initialState,
    action: any
) {
    switch (action.type) {
        case Authentication.ActionTypes.AUTHENTICATION_SUCCESS: {
            return {
              ...state,
              passwordComplexity: new RegExp(action.payload.passwordComplexity),
              passwordComplexityErrorMessage: action.payload.passwordComplexityErrorMessage
            }; 
        }
        case Actions.ActionTypes.CHANGE_PASSWORD: {
            return {
                ...state,
                message: null,
                failed: false
            };
        }
        case Actions.ActionTypes.CHANGE_PASSWORD_FAILURE: {
            return {
                ...state,
                message: action.errorMessage,
                failed: true
            };
        }
        case Actions.ActionTypes.CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                message: action.logMessage,
                failed: false
            };
        }
        case Actions.ActionTypes.CLEAR_INFORMATION: {
            return initialState;
        }
        default:
            return state;
    }
}
