import { LoadSettingsResponse } from '../models/application';
import { LogAction } from '../middlewares/logger';
import { I18n } from 'react-redux-i18n';
import { LogLevel } from '../../shared/logger/models/logger';

export enum ActionTypes {
    LOAD_SETTINGS           = '[Settings] Load',
    LOAD_CONFIGURATION      = '[Configuration] Load',
    LOAD_CONFIGURATION_SUCCESS = '[Configuration] Load success'
}
  
export interface SettingsLoadAction {
    type: ActionTypes.LOAD_SETTINGS;
    payload: LoadSettingsResponse;
}

export interface ConfigurationLoadAction {
    type: ActionTypes.LOAD_CONFIGURATION;
    payload: {};
}

export interface ConfigurationLoadSuccessAction extends LogAction {
    type: ActionTypes.LOAD_CONFIGURATION_SUCCESS;
}

export function loadSettings(settings: LoadSettingsResponse): SettingsLoadAction {
    return {
        type: ActionTypes.LOAD_SETTINGS,
        payload: settings
    };
  }

export function loadClientConfiguration(configuration: {}): ConfigurationLoadAction {
    return {
        type: ActionTypes.LOAD_CONFIGURATION,
        payload: configuration
    };
  }  

export function loadConfigurationSuccess(): ConfigurationLoadSuccessAction {
    return {
        type: ActionTypes.LOAD_CONFIGURATION_SUCCESS,
        logMessage: I18n.t('settings.configurationLoadSuccess'),
        logLevel: LogLevel.INFO
    };
}
  
export type Action =
    | SettingsLoadAction
    | ConfigurationLoadAction
    | ConfigurationLoadSuccessAction;