import orderBookStore from "../../../orderbook/store/orderbooks";
import { getOrderbookContracts } from "../../../orderbook/selectors/contracts";
import { formatNumber, getLocalizationSettings } from "../../utils/formatters";
import { NotificationResponse } from "../models/notification";

/**
 * Formts parameters for specific notifications which are sent from backend in raw state
 * @param content NotificationResponse
 * @returns 
 */
export function formatParameters(content: NotificationResponse) {
    if (content?.message === 'market.logs.tradeReceived') {
        if (content.translationParams?.contract) {
            const contract = getOrderbookContracts(orderBookStore.getState())[content.translationParams.contract];
            const localizeOptions = getLocalizationSettings();
            const quantity = contract ? parseFloat(content.translationParams.quantity) / Math.floor(Math.exp(Math.log(10) * contract.qtyDecimals)) : parseFloat(content.translationParams.quantity);
            const price = parseFloat(content.translationParams.price);
            let unit = content.translationParams.qtyUnit;
            // special case, this conversion is also used in order entry and orderbook
            if (unit?.trim().toLowerCase() === 'kw' && contract.qtyDecimals === 3) {
                unit = 'MW';
            }
            try {
                const params = {...content.translationParams,
                    price: formatNumber(price, {...localizeOptions, maximumFractionDigits: contract.priceDecimals}), 
                    quantity: formatNumber(quantity, {...localizeOptions, maximumFractionDigits: contract.qtyDecimals, decimalStep: contract.qtyStepSize}),
                    qtyUnit: !!unit && unit !== 'NONE' ? unit : '',
                    
                };
                return {...content, translationParams: params};
            } catch (e) {
                return content;
            }
        }
    }
    return content;
}
    
