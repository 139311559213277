import { StompService, StompClient } from '../../main/services/stompService';
import { Observable } from 'rxjs/Observable';
import { config } from '../../main/config';

export default class I18nService extends StompService {
  constructor() {
    super(StompClient);
  }

  public requestDefaultTranslations(): Observable<any> {
    return Observable.create((observer: any) =>
      fetch(config.serverURL + '/i18n/get?locale=', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(function(response: Response) {
          response
            .json()
            .then((data: any) => {
              observer.next(data);
            })
            .catch(error => observer.error(error));
        })
        .catch(error => observer.error(error))
    );
  }

  public requestTranslations(locale: string): Observable<any> {
    return Observable.create((observer: any) =>
      fetch(config.serverURL + '/i18n/get?locale=' + locale, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(function(response: Response) {
        response
          .json()
          .then((data: any) => {
            observer.next(data);
          })
          .catch(error => observer.error(error));
      })
    );
  }

  public requestAvailableLocales(): Observable<any> {
    return Observable.create((observer: any) =>
      fetch(config.serverURL + '/i18n/locales', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(function(response: Response) {
          response
            .json()
            .then((data: any) => {
              observer.next(data);
            })
            .catch(error => observer.error(error));
        })
        .catch(error => observer.error(error))
    );
  }
}
