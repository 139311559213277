import { State } from '../../main/reducers/rootReducer';
import { isMatchOnlyModeEnabled, isOrderbookPreviewEnabled } from '../../requests/selectors/requests';
import { connect } from 'react-redux';
import { formComponent } from '../../shared/form/components/FormComponent';
import OrderFormComponent from '../../orders/components/orderForm';
import { getLocale } from '../../shared/i18n/selectors/translations';
import { handleInvalidInput as invalidInput } from '../../shared/validation/action';
import { getMaxDecimalsIncludingStepSizes, formatFormData } from '../../shared/utils/formatters';
import { requiredValidation, largerThanZero } from '../../shared/validation/validators';

const mapStateToProps = (state: State, props: any) => {
    return {
      locale: getLocale(state),
      matchOnlyModeEnabled: isMatchOnlyModeEnabled(state),
      orderbookPreviewEnabled: isOrderbookPreviewEnabled(state),
      basketOrder: true,
      additionalValidations: {
        rules: {
          'quantity': [largerThanZero]
        }
      }
    };
  };
  
const mapDispatchToProps = {
    handleInvalidInput: invalidInput,
    formatFormData: formatFormData,
    getMaxDecimalsIncludingStepSizes: getMaxDecimalsIncludingStepSizes
  };
  
export default connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(formComponent(OrderFormComponent));