import { IQuadrant } from '../models/analyticsPanel';
import { State } from '../reducers/analyticsPanel';
import { LogAction } from '../../main/middlewares/logger';
import { I18n } from 'react-redux-i18n';
import { LogLevel } from '../../shared/logger/models/logger';

export enum ActionTypes {
  LOAD = '[Analytics] Load',
  RESET = '[Analytics] Reset',
  SET_QUADRANT = '[Analytics] Set quadrant',
  UPDATE_QUADRANT = '[Analytics] Update quadrant',
  REMOVE_QUADRANT = '[Analytics] Remove quadrant',
  QUADRANT_RESIZE = '[Analytics] Quadrants resize',
  TRIGGER_PANEL_LOCK = '[Analytics] Trigger panel lock'
}

export interface AnalyticsLoadAction extends LogAction {
  type: ActionTypes.LOAD;
  payload: State;
}

export interface AnalyticsResetAction extends LogAction {
  type: ActionTypes.RESET;
}

export interface AnalyticsSetQuadrantAction extends LogAction {
  type: ActionTypes.SET_QUADRANT;
  payload: IQuadrant;
}

export interface AnalyticsUpdateQuadrantAction extends LogAction {
  type: ActionTypes.UPDATE_QUADRANT;
  payload: IQuadrant;
}

export interface AnalyticsRemoveQuadrantAction {
  type: ActionTypes.REMOVE_QUADRANT;
  payload: number;
}

export interface AnalyticsQuadrantsResizeAction {
  type: ActionTypes.QUADRANT_RESIZE;
  payload: { quadrants: IQuadrant[]; axisX: number; axisY: number };
}

export interface AnalyticsTriggerPanelLockAction {
  type: ActionTypes.TRIGGER_PANEL_LOCK;
  payload: { locked: boolean; height: number };
}

export function resetAnalytics(): AnalyticsResetAction {
  return {
    type: ActionTypes.RESET,
    logMessage: I18n.t('analytics.log.reset'),
    logLevel: LogLevel.DEBUG
  };
}

export function loadAnalytics(state: State): AnalyticsLoadAction {
  return {
    type: ActionTypes.LOAD,
    payload: state,
    logMessage: I18n.t('analytics.log.load'),
    logLevel: LogLevel.DEBUG
  };
}

export function setQuadrant(quadrant: IQuadrant): AnalyticsSetQuadrantAction {
  return {
    type: ActionTypes.SET_QUADRANT,
    payload: quadrant,
    logMessage: I18n.t('analytics.log.set', {quadrant: quadrant.id + ' - ' + quadrant.components.map(c => I18n.t(`recentAction.${c}`)).join(', ')}),
    logLevel: LogLevel.DEBUG
  };
}

export function updateQuadrant(
  quadrant: IQuadrant
): AnalyticsUpdateQuadrantAction {
  return {
    type: ActionTypes.UPDATE_QUADRANT,
    payload: quadrant,
    logMessage: I18n.t('analytics.log.update', {quadrant: quadrant.id + ' - ' + quadrant.components.map(c => I18n.t(`recentAction.${c}`)).join(', ')}),
    logLevel: LogLevel.DEBUG
  };
}

export function removeQuadrant(
  quadrantId: number
): AnalyticsRemoveQuadrantAction {
  return {
    type: ActionTypes.REMOVE_QUADRANT,
    payload: quadrantId
  };
}

export function quadrantsResize(
  quadrants: IQuadrant[],
  axisX: number,
  axisY: number
): AnalyticsQuadrantsResizeAction {
  return {
    type: ActionTypes.QUADRANT_RESIZE,
    payload: { quadrants, axisX, axisY }
  };
}

export function triggerPanelLock(
  locked: boolean,
  height: number
): AnalyticsTriggerPanelLockAction {
  return {
    type: ActionTypes.TRIGGER_PANEL_LOCK,
    payload: { locked, height }
  };
}
export type Action =
  | AnalyticsLoadAction
  | AnalyticsResetAction
  | AnalyticsUpdateQuadrantAction
  | AnalyticsRemoveQuadrantAction
  | AnalyticsSetQuadrantAction
  | AnalyticsQuadrantsResizeAction
  | AnalyticsTriggerPanelLockAction;
