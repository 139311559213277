import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { State } from '../../../main/reducers/rootReducer';
import { filter, map, mergeMap, catchError } from 'rxjs/operators';
import { ActionTypes as Table, successDownloadCsv, failedDownloadCsv } from '../actions/table';
import TableService from '../services/tableService';
import { receiveMessage } from '../../messenger/actions/messenger';
import { of } from 'rxjs';

const tableService = new TableService();

export const createCsvRequest: any = (
    actions$: ActionsObservable<any>,
    state: StateObservable<State>
  ) => {
    return actions$.pipe(
      filter(action => action.type === Table.TRIGGER_DOWNLOAD_CSV),
      map(actions => actions.payload),
      mergeMap(data => {
        return tableService.sendCreateCsvRequest(data.visibleColumns, data.hidden, data.sorting, data.type, data.id, data.tags, data.filter).pipe(
            map((content: any) => {
                if (content) {
                    return successDownloadCsv(data.type);
                } else {
                    return failedDownloadCsv(data.type);
                }
            }),
            catchError((error: any) =>
              of(receiveMessage(error, error, true))
            )
        );
      })
    );
};

export const tableEpics = combineEpics(
    createCsvRequest
);