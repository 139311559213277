import { IRequest, QuoteRequestFormData, AcceptQuoteRequestRequest, RejectQuoteRequestRequest } from '../models/request';
import { LogAction } from '../../main/middlewares/logger';
import { Validation } from '../../shared/validation/model';
import { LogLevel } from '../../shared/logger/models/logger';
import { I18n } from 'react-redux-i18n';
import { getOrderbookContracts } from '../../orderbook/selectors/contracts';
import orderBookStore from '../../orderbook/store/orderbooks';

export enum ActionTypes {
  LOAD = '[Requests] Load',
  LOAD_SUCCESS = '[Requests] Load success',
  LOAD_FAILURE = '[Requests] Load failure',
  QUOTE_REQUEST_REMOVE = '[Requests] Remove',
  QUOTE_REQUEST_FORM_SHOW = '[Requests] Open form',
  QUOTE_REQUEST_FORM_HIDE = '[Requests] Close form',
  QUOTE_REQUEST_FORM_CLEAR = '[Requests] Form clear',
  QUOTE_REQUEST_FORM_SUBMIT = '[Requests] Form submit',
  ENTER_QUOTE_REQUEST_COMPLETE =  '[Requests] Enter complete',
  ENTER_QUOTE_REQUEST_FAILURE =  '[Requests] Enter failure',
  QUOTE_REQUEST_SUCCESS = '[Request] Quote request success',
  QUOTE_REQUEST_ACCEPT = '[Request] Quote request accept',
  QUOTE_REQUEST_DECLINE = '[Request] Quote request decline'
}

export interface RequestsLoadAction {
  type: ActionTypes.LOAD;
}

export interface RequestsLoadActionSuccess extends LogAction {
  type: ActionTypes.LOAD_SUCCESS;
  payload: IRequest[];
}

export interface RequestsLoadActionFailure extends LogAction {
  type: ActionTypes.LOAD_FAILURE;
  error: any;
}

export interface QuoteRequestRemoveAction {
  type: ActionTypes.QUOTE_REQUEST_REMOVE;
  payload: IRequest[];
}

export interface ShowQuoteRequestFormAction {
  type: ActionTypes.QUOTE_REQUEST_FORM_SHOW;
  payload: QuoteRequestFormData;
}

export interface HideQuoteRequestFormAction {
  type: ActionTypes.QUOTE_REQUEST_FORM_HIDE;
}

export interface QuoteRequestFormClearAction {
  type: ActionTypes.QUOTE_REQUEST_FORM_CLEAR;
}

export interface QuoteRequestFormSubmitAction  extends LogAction {
  type: ActionTypes.QUOTE_REQUEST_FORM_SUBMIT;
  payload: QuoteRequestFormData;
  correlationId: string;
  validations: Validation;
  logLevel: LogLevel;
}

export interface QuoteRequestEnterCompleteAction {
  type: ActionTypes.ENTER_QUOTE_REQUEST_COMPLETE;
  payload: any;
}

export interface QuoteRequestEnterFailureAction {
  type: ActionTypes.ENTER_QUOTE_REQUEST_FAILURE;
  error: any;
}

export interface QuoteRequestSuccessAction extends LogAction {
  type: ActionTypes.QUOTE_REQUEST_SUCCESS;
  logLevel: LogLevel;
  logMessage: string;
}

export interface QuoteRequestAcceptAction extends LogAction {
  type: ActionTypes.QUOTE_REQUEST_ACCEPT;
  payload: AcceptQuoteRequestRequest;
  logLevel: LogLevel;
}

export interface QuoteRequestDeclineAction extends LogAction {
  type: ActionTypes.QUOTE_REQUEST_DECLINE;
  payload: RejectQuoteRequestRequest;
  logLevel: LogLevel;
}

export function requestsLoad(): RequestsLoadAction {
  return {
    type: ActionTypes.LOAD
  };
}

export function requestsLoadSuccess(payload: IRequest[]) {
  return {
    type: ActionTypes.LOAD_SUCCESS,
    payload: payload
  };
}

export function requestsLoadFailure(error: any) {
  return {
    type: ActionTypes.LOAD_FAILURE,
    error: error
  };
}

export function quoteRequestsRemove(payload: IRequest[]) {
  return {
    type: ActionTypes.QUOTE_REQUEST_REMOVE,
    payload: payload
  };
}

export function openQuoteRequest(formData: QuoteRequestFormData): ShowQuoteRequestFormAction {
  return {
      type: ActionTypes.QUOTE_REQUEST_FORM_SHOW,
      payload: formData
  };
}

export function hideQuoteRequest(): HideQuoteRequestFormAction {
  return {
      type: ActionTypes.QUOTE_REQUEST_FORM_HIDE
  };
}

export function clearQuoteRequestForm(): QuoteRequestFormClearAction {
  return {
      type: ActionTypes.QUOTE_REQUEST_FORM_CLEAR
  };
}

export function submitQuoteRequestForm(payload: QuoteRequestFormData, correlationId: string, validations: Validation): QuoteRequestFormSubmitAction {
  const contract = getOrderbookContracts(orderBookStore.getState())[payload.contractId];
  return {
      type: ActionTypes.QUOTE_REQUEST_FORM_SUBMIT,
      payload: payload,
      correlationId: correlationId,
      validations: validations,
      logLevel: LogLevel.DEBUG,
      logMessage: I18n.t('quoteRequest.log.submit' + payload.mode, {
        contract: contract ? contract.name : payload.contractId, 
        quantity: payload.quantity,
        quote: payload.quote,
        validTo: payload.validTo
      })
  };
}

export function quoteRequestEnterComplete(payload: any,  correlationId: string, request: any): QuoteRequestEnterCompleteAction {
  return {
    type: ActionTypes.ENTER_QUOTE_REQUEST_COMPLETE,
    payload: payload
  };
}

export function quoteRequestEnterFailure(payload: any): QuoteRequestEnterFailureAction {
  return {
    type: ActionTypes.ENTER_QUOTE_REQUEST_FAILURE,
    error: payload
  };
}

export function quoteRequestSuccess(message: string): QuoteRequestSuccessAction {
  return {
    type: ActionTypes.QUOTE_REQUEST_SUCCESS,
    logLevel: LogLevel.INFO,
    logMessage: message
  };
}

export function acceptQuoteRequest(message: AcceptQuoteRequestRequest): QuoteRequestAcceptAction {
  return {
    type: ActionTypes.QUOTE_REQUEST_ACCEPT,
    payload: message,
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('quoteRequest.log.accept', {id: message.id})
  };
}

export function declineQuoteRequest(message: RejectQuoteRequestRequest): QuoteRequestDeclineAction {
  return {
    type: ActionTypes.QUOTE_REQUEST_DECLINE,
    payload: message,
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('quoteRequest.log.decline', {id: message.id})
  };
}

export type Action =
  | RequestsLoadAction
  | RequestsLoadActionSuccess
  | RequestsLoadActionFailure
  | ShowQuoteRequestFormAction
  | HideQuoteRequestFormAction
  | QuoteRequestFormClearAction
  | QuoteRequestEnterCompleteAction
  | QuoteRequestEnterFailureAction
  | QuoteRequestSuccessAction
  | QuoteRequestAcceptAction
  | QuoteRequestDeclineAction
  | QuoteRequestRemoveAction;
