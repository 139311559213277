import { connect } from 'react-redux';
import { State } from '../../main/reducers/rootReducer';
import { getRequestRows } from '../selectors/requests';
import { ComponentType } from '../../shared/ui/models/component';
import { getTableForTab } from '../../shared/ui/selectors/ui';

import UITable from '../../shared/ui/components/table/components/uiTableComponent';
import {
  triggerColumnNames,
  triggerHeadlines,
  sort,
  hideRows,
  showRows,
  triggerSearchTags,
  triggerTableSpecificAction,
  pinRows,
  unpinRows,
  triggerShowHidden
} from '../../shared/ui/actions/table';
import { triggerFilter } from '../../shared/ui/actions/tab';

const mapStateToProps = (state: State, props: any) => ({
  table: getTableForTab(state, props.parentId, ComponentType.Request),
  rows: getRequestRows(
    state,
    getTableForTab(state, props.parentId, ComponentType.Request)
  )
});

const mapDispatchToProps = {
  sort: sort,
  triggerColumnNames: triggerColumnNames,
  triggerHeadlines: triggerHeadlines,
  triggerFilter: triggerFilter,
  triggerSearchTags: triggerSearchTags,
  triggerShowHidden: triggerShowHidden,
  hideRows: hideRows,
  showRows: showRows,
  pinRows: pinRows,
  unpinRows: unpinRows
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(UITable);
