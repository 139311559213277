import * as React from 'react';

export interface DropdownMenuItem {
  key: string; // Unique key
  caption: string;
  onClick: () => void;
}

interface DropdownMenuProps {
  items: DropdownMenuItem[];
  dockId: string;
}

interface State {
  maxHeight: string;
}

export default class UIDropdownMenu extends React.Component<DropdownMenuProps, State> {

  componentWillMount() {
    this.setState({
      maxHeight: '100%'
    });
  }

  render() {
    const { maxHeight } = this.state;

    const items = this.props.items.map(item => (
      <li key={item.key} onClick={() => item.onClick()}>
        {item.caption}
      </li>
    ));

    return (
      <div className="dropdown__menu" style={{ maxHeight: maxHeight }}>
        <ul>
          {items}
        </ul>
      </div>
    );
  }

}
