import {
  ActionsObservable,
  combineEpics,
  StateObservable
} from 'redux-observable';
import * as Rx from 'rxjs';
import { filter, map, switchMap, concatAll } from 'rxjs/operators';
import { State } from '../../main/reducers/rootReducer';

import {
  ActionTypes,
  updateQuadrant as update,
  removeQuadrant as remove
} from '../actions/analyticPanel';
import { ActionTypes as Tabs } from '../../shared/ui/actions/tab';

import {
  create as tabCreate,
  remove as tabRemove
} from '../../shared/ui/actions/tab';
import { Tab, ITab } from '../../shared/ui/models/tab';
import { IQuadrant } from '../models/analyticsPanel';
import {
  getTabsForDock,
  getTabsCountInDocks,
  getTabs
} from '../../shared/ui/selectors/ui';
import { ComponentType } from '../../shared/ui/models/component';
import { getAnalyticsQuadrantsEntities } from '../selectors/anaylticsPanel';

export const setQuadrant: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.SET_QUADRANT),
    map(action => action.payload),
    switchMap((payload: IQuadrant) => {
      const newTab: ITab = new Tab(
        'analyticsPanel-Q' + payload.id,
        `sidebar.${payload.components[0].toLowerCase()}s`,
        payload.components[0],
        true,
        'analytics-Q' + payload.id + '-' + payload.components[0]
      );
      return Rx.of(tabCreate(newTab));
    })
  );
};

export const updateQuadrant: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.UPDATE_QUADRANT),
    map(action => action.payload),
    switchMap(payload => {
      const tabTypes = getTabsForDock(
        state.value,
        'analyticsPanel-Q' + payload.id
      ).map(t => t.type);
      const newTabType: ComponentType = payload.components.find(
        (c: ComponentType) => tabTypes.indexOf(c) === -1
      );

      if (newTabType) {
        const newTab: ITab = new Tab(
          'analyticsPanel-Q' + payload.id,
          `sidebar.${newTabType.toLowerCase()}s`,
          newTabType,
          true,
          'analytics-Q' + payload.id + '-' + newTabType
        );
        return Rx.of(tabCreate(newTab));
      } else {
        return Rx.empty();
      }
    })
  );
};

export const removeQuadrant: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Tabs.REMOVE),
    map(actions => actions.payload),
    switchMap(data => {
      const { dockId, type, isAnalayticsReset } = data;
      if (dockId.indexOf('analyticsPanel') !== -1) {
        const tabsInDocks: { [id: string]: any } = getTabsCountInDocks(
          state.value
        );
        const quadrantId = Number(dockId.substr(dockId.length - 1));
        const quadrant = getAnalyticsQuadrantsEntities(state.value)[quadrantId];
        if (!tabsInDocks[dockId] || isAnalayticsReset) {
          return Rx.of(remove(Number(dockId.substr(dockId.length - 1))));
        }

        const updatedQuadrant = {
          ...quadrant,
          components: quadrant.components.filter(c => c !== type)
        };
        return Rx.of(update(updatedQuadrant));
      } else {
        return Rx.of();
      }
    })
  );
};

export const resetAnalytics: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.RESET),
    map(actions => actions.payload),
    switchMap(() => {
      const tabEntities = getTabs(state.value);
      const tabs = Object.keys(tabEntities)
        .filter(key => tabEntities[key].dockId.indexOf('analyticsPanel') !== -1)
        .map(key => tabEntities[key]);

      return Rx.concat(
        tabs.map(tab => {
          return tabRemove(tab.id, tab.dockId, tab.type, true);
        })
      );
    })
  );
};

export const analyticsEpic = combineEpics(
  setQuadrant,
  updateQuadrant,
  removeQuadrant,
  resetAnalytics
);
