import { State } from '../../main/reducers/rootReducer';
import { createSelector } from 'reselect';

export const getAnalyticsState = (state: State) => state.analytics;

export const getAnalyticsQuadrantsState = createSelector(
  getAnalyticsState,
  s => s.quadrants
);

export const getAnalyticsQuadrantsIds = createSelector(
  getAnalyticsQuadrantsState,
  s => s.ids
);

export const getAnalyticsQuadrantsEntities = createSelector(
  getAnalyticsQuadrantsState,
  s => s.entities
);

export const getAnalyticsQuadrants = createSelector(
  [getAnalyticsQuadrantsIds, getAnalyticsQuadrantsEntities],
  (ids, entities) => ids.map(id => entities[id])
);

export const getAnalyticsIsLocked = createSelector(
  getAnalyticsState,
  s => s.locked
);

export const getAnalyticsPanelHeight = createSelector(
  getAnalyticsState,
  s => s.height
);

export const getAnalyticsAxises = createSelector(
  getAnalyticsState,
  s => s.axises
);

export const getAnalyticsQuadrantLayout = createSelector(
  getAnalyticsState,
  s => { 
    let layout;
    // layout array is created:
    // if first row is Q1, Q2, second row is Q3, Q4 -> array is [Q1, Q2, Q3, Q4]
    const ids = s.quadrants.ids.sort();
    switch (s.quadrants.ids.length) {
      // one id occupies whole space
      case 1: layout = [ids[0], ids[0], ids[0], ids[0]]; break;
      // two ids might be split vertically ([A B A B]) or horizontally ([A A B B])
      case 2: layout = [ids[0], ids[1] - ids[0] > 1 ? ids[0] : ids[1], ids[1] - ids[0] > 1 ? ids[1] : ids[0], ids[1]]; break;
      // three ids split the space horizontally and one half is split vertically ([1 1 3 4], [2 2 3 4], [1 2 3 3], [1, 2, 4, 4])
      case 3: layout =  [ids[0] == 1 ? 1 : 2, ids[1] == 2 ? 2 : ids[0], ids[1] == 3 ? 3 : ids[2], ids[2] == 4 ? 4 : 3]; break;
      // no active quadrants or all four
      default:layout = [1,2,3,4];
    }
    return layout;
  } 
)
