import { connect } from 'react-redux';
import { State } from '../../main/reducers/rootReducer';
import OrderFormComponent from '../components/orderForm';
import { submitOrderForm, clearOrderForm } from '../actions/orderForm';
import {
  getSelectedContractId,
  getOrderFormData,
  getPreselectedContractId,
  getSelectedContract
} from '../selectors/orders';
import { getLocale } from '../../shared/i18n/selectors/translations';
import { orderSuccess } from '../actions/orders';
import { handleInvalidInput as invalidInput } from '../../shared/validation/action';
import { formatFormData, getMaxDecimalsIncludingStepSizes } from '../../shared/utils/formatters';
import { isMatchOnlyModeEnabled, isOrderbookPreviewEnabled } from '../../requests/selectors/requests';
import { formComponent } from '../../shared/form/components/FormComponent';

const mapStateToProps = (state: State) => {
  return {
    preselectedContractId: getPreselectedContractId(state),
    selectedContractId: getSelectedContractId(state),
    data: getOrderFormData(state),
    locale: getLocale(state),
    contract: getSelectedContract(state),
    matchOnlyModeEnabled: isMatchOnlyModeEnabled(state),
    orderbookPreviewEnabled: isOrderbookPreviewEnabled(state)
  };
};

const mapDispatchToProps = {
  handleSubmit: submitOrderForm,
  handleClear: clearOrderForm,
  handleSuccess: orderSuccess,
  handleInvalidInput: invalidInput,
  formatFormData: formatFormData,
  getMaxDecimalsIncludingStepSizes: getMaxDecimalsIncludingStepSizes
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(formComponent(OrderFormComponent));
