import { DockType } from '../../dock/models/dock';
import { ITableColumn } from '../../ui/models/table';
import { v1 } from 'uuid';
import { ComponentType } from '../../ui/models/component';

export interface SaveFavoriteRequest {
  jsonData: string;
}

export interface IFavoriteMarkets {
  componentType: ComponentType;
  instrumentId: string;
  itemId: string;
  instrumentTitle: string;
  columns: ITableColumn[];
  hiddenColumnNames: string[];
  hiddenExpiryKeys: string[];
  isHeadlinesVisible: boolean;
}

export interface IFavoriteCharts {
  componentType: ComponentType;
  title: string;
  itemId?: string;
  instrumentId?: string;
  selectedPeriodType?: string;
  contractId?: string;
  groupType?: string;
}

export interface IFavorite {
  id: string;
  name: string;
  entityType: DockType;
  entityId: string;
  entities: IFavoriteMarkets[] | IFavoriteCharts[];
  isNew: boolean;
  isVisible: boolean;
}

export class Favorite implements IFavorite {
  id = v1();
  entityType: DockType = DockType.Market;
  entityId: string;
  entities: IFavoriteMarkets[] | IFavoriteCharts[];
  name: string;
  isNew: false;
  isVisible: true;

  constructor(name: string, entityId: string,  entities: IFavoriteMarkets[] | IFavoriteCharts[], entityType?: DockType) {
    this.name = name;
    this.entityId = entityId;
    this.entities = entities;
    this.entityType = entityType ? entityType : this.entityType;
  }
}
