import { connect } from 'react-redux';
import { loginRequest, logout, currentlyPendingLogin, pendingLoginTimeout } from '../actions/authentication';
import LoginForm from '../components/LoginForm';
import { State } from '../../main/reducers/rootReducer';
import { getConnectionError, getTakeoverConfirmDisplayed, getAuthError } from '../selectors/connection';
import { getAuthorizedStatus, getLoginPendingState, getTimeUntilNextLoginAttempt } from '../selectors/authetication';
import { applicationStarted, takeoverConfirm } from '../actions/connection';
import { getLocale } from '../../shared/i18n/selectors/translations';

const mapStateToProps = (state: State) => ({
    isAuthorized: getAuthorizedStatus(state),
    error: getConnectionError(state),
    loginError: getAuthError(state),
    locale: getLocale(state),
    isLoginPending: getLoginPendingState(state),
    timeUntilNextLoginAttempt: getTimeUntilNextLoginAttempt(state),
    showTakeoverConfirm: getTakeoverConfirmDisplayed(state)
});

const mapDispatchToProps = {
    handleSubmit: loginRequest,
    TEMPLogout: logout,
    applicationStarted: applicationStarted,
    pendingLogin: currentlyPendingLogin,
    reactivateSubmitButtonAfterTimeout: pendingLoginTimeout,
    takeoverConfirm: takeoverConfirm
};

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(LoginForm);