import { v1 } from 'uuid';

export enum NotificationCoditionInstrument {
  ALL = 'ALL',
  OPENED = 'OPENED',
  OPEN_SAVED = 'OPEN_SAVED',
  SAVED = 'SAVED'
}

export enum NotificationType {
  INFO = 'INFO',
  DANGER = 'DANGER',
  WARNING = 'WARNING'
}

export interface INotification {
  id: string;
  type: NotificationType;
  message: string;
  confirmationRequired?: boolean;
}

export class Notification implements INotification {
  id: string;
  type: NotificationType;
  message: string;
  confirmationRequired: boolean;

  constructor(type: NotificationType, message: string, confirmationRequired?: boolean) {
    this.id = v1();
    this.type = type;
    this.message = message;
    this.confirmationRequired = !!confirmationRequired ? true : false;
  }
}

export interface UpdateTokenRequest {
  token: string;
}

export interface NotificationResponse {
  message: string;
  notificationType: NotificationType;
  id: string;
  translate: boolean;
  translationParams: {[name: string]: string};
}
