import { BusinessUnit } from '../models/businessUnits';
import * as BusinessUnits from '../actions/businessUnits';

export interface State {
    businessUnits: {[key: string]: BusinessUnit};
}

export const initialState: State = {
    businessUnits: {}
};
  
export function reducer(
    state: State = initialState,
    action: BusinessUnits.Action
) {
    switch (action.type) {
        case BusinessUnits.ActionTypes.LOAD_BUSINESS_UNITS_SUCCESS: {
            return {
                ...state,
                businessUnits: action.payload
            };
        }
        default: 
            return state;       
    }
}