import { Observable, of } from 'rxjs';
import { StompService, StompClient } from '../../../main/services/stompService';
import RestService from '../../../main/services/restService';
import { SaveSettingsRequest } from '../../../main/models/application';
import { SaveFavoriteRequest } from '../models/favorite';

export default class FavoriteService extends StompService {
  private restService: RestService;

  constructor() {
    super(StompClient);
    this.restService = new RestService();
  }

  public saveFavorites(message: SaveSettingsRequest): Observable<any> {
    return this.restService.restPost('/data/favorites/save', message);
  }

  public subscribeAvailableFavorites(): Observable<any> {
    return this.subscribe('/user/topic/favorite');
  }
}
