import { StompService, StompClient } from '../../main/services/stompService';
import { Observable } from 'rxjs';
import RestService from '../../main/services/restService';

export default class ProductService extends StompService {
  private restService: RestService;

  constructor() {
    super(StompClient);
    this.restService = new RestService();
  }

  public subscribeProductMapWs(): Observable<any> {
    return this.subscribe('/user/topic/im/products/map');
  }

  public subscribeProductMap(): Observable<any> {
    return this.restService.restFetch('/data/products');
  }

  public subscribeProductsResponse(): Observable<any> {
    return this.subscribe('/user/topic/im/sub/products');
  }
}
