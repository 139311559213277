import { LogAction } from '../../../main/middlewares/logger';
import { LogLevel } from '../../logger/models/logger';
import { ChangePasswordRequest } from '../models/passwordChange';
import { I18n } from 'react-redux-i18n';

export enum ActionTypes {
    CHANGE_PASSWORD = '[Password Change] Changing password',
    CHANGE_PASSWORD_SUCCESS = '[Password Change Success] Changing password successful',
    CHANGE_PASSWORD_FAILURE = '[Password Change Failure] Changing password failed',
    CLEAR_INFORMATION = '[Password Change Clear] Clearing password form data',
    RESET_PASSWORD = '[Password Reset Triggered] Password expired - reset triggered'
}

export interface ChangePasswordAction extends LogAction {
    type: ActionTypes.CHANGE_PASSWORD;
    payload: ChangePasswordRequest;
}

export interface ChangePasswordSuccessAction extends LogAction {
    type: ActionTypes.CHANGE_PASSWORD_SUCCESS;
}

export interface ChangePasswordFailureAction extends LogAction {
    type: ActionTypes.CHANGE_PASSWORD_FAILURE;
    errorMessage: string;
}

export interface ClearPasswordChangeInformationAction {
    type: ActionTypes.CLEAR_INFORMATION;
}

export interface ResetPasswordAction extends LogAction {
    type: ActionTypes.RESET_PASSWORD;
}

export function clearPasswordChangeInformation(): ClearPasswordChangeInformationAction {
    return {
        type: ActionTypes.CLEAR_INFORMATION
    };

}

export function passwordChange(oldPassword: string, newPassword: string): ChangePasswordAction {
    return {
        type: ActionTypes.CHANGE_PASSWORD,
        payload: {
            oldPassword: oldPassword,
            newPassword: newPassword,
            isForeignPw: false
        },
        logLevel: LogLevel.INFO,
        logMessage: I18n.t('password.changing')
    };
}

export function passwordChangeSuccess(): ChangePasswordSuccessAction {
    return {
        type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
        logLevel: LogLevel.INFO,
        logMessage: I18n.t('password.changed')
    };
}

export function passwordChangeFailed(errorMessage: any): ChangePasswordFailureAction {
    return {
        type: ActionTypes.CHANGE_PASSWORD_FAILURE,
        errorMessage: errorMessage,
        logLevel: LogLevel.ERROR,
        logMessage: I18n.t('password.changeFailed', {error: errorMessage})
    };
}

export function resetPassword(): ResetPasswordAction {
    return {
        type: ActionTypes.RESET_PASSWORD,
        logLevel: LogLevel.ERROR,
        logMessage: I18n.t('password.resetExpiredPassword')
    };
}

export type Action =
    | ChangePasswordAction
    | ChangePasswordSuccessAction
    | ChangePasswordFailureAction
    | ClearPasswordChangeInformationAction
    | ResetPasswordAction;
