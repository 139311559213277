import * as Messager from '../actions/messenger';

export interface State {
  ids: string[];
  messages: { [id: string]: any };
}

export const initialState: State = {
  ids: [],
  messages: {}
};

export function reducer(state: State = initialState, action: Messager.Action) {
  switch (action.type) {
    case Messager.ActionTypes.SAVE_CORRELATION_ID: {
      return {
        ...state,
        ids: [...state.ids, action.payload]
      };
    }

    case Messager.ActionTypes.RECEIVE_MESSAGE: {
      const { id, message, error, translationParams, isTranslated } = action.payload;
      return {
        ...state,
        messages: {
          ...state.messages,
          [id]: {
            correlationId: id,
            message: message,
            isError: error,
            translationParams: translationParams,
            isTranslated
          }
        }
      };
    }

    case Messager.ActionTypes.RECEIVE_CONCAT_MESSAGE: {
      const { acks } = action.payload;
      const newMessages = acks.reduce(
        (acc: {[key: string]: any}, ack) => {
          
          acc[ack.correlationId] = {
            correlationId: ack.correlationId,
            message: ack.message,
            isError: ack.error
          };

          return acc;
        }, {}
      );
      return {
        ...state,
        messages: {
          ...state.messages,
          ...newMessages
        }
      };
    }

    case Messager.ActionTypes.REMOVE_MESSAGE: {
      const newState = action.payload.reduce((acc: {[correlationId: string]: string}, id) => {
        if (action.payload.indexOf(id) > -1) {
          acc[id] = state.messages.id;
        }
        return acc;
      }, {});
      return {
        ids: state.ids.filter(id => action.payload.indexOf(id) > -1),
        messages: newState
      };
    }

    default:
      return state;
  }
}
