import { createSelector } from 'reselect';
import { OrderbooksState } from '../reducers/combinedReducer';

const getChartsState = (state: OrderbooksState) => state.charts;

export const getChartContractIds = createSelector(
    [getChartsState],
    s => {
        return Object.keys(s.chartData);
    }
);

export const getChartData = createSelector(
    [getChartsState],
    s => {
        return s.chartData;
    }
);

export const getChartError = createSelector(
    [getChartsState],
    s => {
        return s.error;
    }
);

export const getChartVersion = createSelector(
    [getChartsState],
    s => {
      return s.version;
    }
);

export const getChartDataForDockId = createSelector(
    [getChartData, (state: OrderbooksState, dockId: string) => dockId],
    (data, dockId) => {
        const contractData = data[dockId];
        return contractData && Object.keys(contractData) ? contractData : {}; // currently only last prices
    }
);