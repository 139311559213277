import * as React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { IFavorite } from '../models/favorite';
import { DockType } from '../../dock/models/dock';

const ConfirmationReplaceBlock = ({ viewName, onSuccess, onCancel }: any) => {
  return (
    <div className="confirmation-block">
      <div>
        <p>
          <Translate
            value="favorite.modal.replaceQuestion"
            name={viewName}
          />
        </p>
      </div>
      <div className="modal-footer d-flex">
        <button className="btn btn-cancellation w150 ml-auto" onClick={onCancel}>
          <Translate
            value={'favorite.form.cancel'
            }
          />
        </button>
        <button
          className="btn btn-approval w150 ml-auto"
          onClick={onSuccess}
          autoFocus={true}
        >
          <Translate value="favorite.modal.replace" />
        </button>
      </div>
    </div>
  );
};

interface FavoriteNameFormProps {
  onSubmit: (favoriteName: string) => void;
  onCancel: () => void;
  isFavorite: boolean;
  favorites: IFavorite[];
}

interface FavoriteNameFormState {
  inputValue: string;
  matchingFavoriteNames: string[];
  isConfirmationOpen: boolean;
}

export default class FavoriteNameForm extends React.Component<
  FavoriteNameFormProps,
  FavoriteNameFormState
> {
  constructor(props: FavoriteNameFormProps) {
    super(props);

    this.state = {
      inputValue: '',
      matchingFavoriteNames: [],
      isConfirmationOpen: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectMatching = this.selectMatching.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onConfirmationSuccess = this.onConfirmationSuccess.bind(this);
  }

  handleChange(event: any) {
    event.preventDefault();
    const target = event.target;
    let value = target.value;
    const matching = this.props.favorites.map(f => f.name).filter((name: string) => {
      return value.length > 0
        && name.toLowerCase().indexOf(value.toLowerCase()) === 0;
    });
    this.setState({
      ...this.state,
      inputValue: value,
      matchingFavoriteNames: matching
    });
  }

  selectMatching(name: string) {
    this.setState(state => ({
      ...state,
      inputValue: name,
      matchingViewNames: []
    }));
  }

  handleCancel(event: any) {
    event.preventDefault();
    this.props.onCancel();
    this.setState({
      inputValue: '',
      isConfirmationOpen: false,
      matchingFavoriteNames: []
    });
  }

  handleSubmit(event: any) {
    event.preventDefault();
    const { inputValue } = this.state;
    if (!!this.props.favorites.map(f => f.name).find(name => name === inputValue)) {
      this.setState(state => ({
        ...state,
        isConfirmationOpen: true
      }));
    } else {
      this.props.onSubmit(inputValue);
      this.setState({
        inputValue: '',
        isConfirmationOpen: false,
        matchingFavoriteNames: []
      });
    }

  }

  handleKeyPress(event: any) {
    if (event.key === 'Enter') {
      this.handleSubmit(event);
    }
  }

  onConfirmationSuccess() {
    this.props.onSubmit(this.state.inputValue);
    this.setState(state => ({
      ...state,
      isConfirmationOpen: false
    }));
  }

  render() {
    const { inputValue, isConfirmationOpen } = this.state;
    const isFormVisible = !this.props.isFavorite;
    const expanded = this.state.matchingFavoriteNames.length > 0;
    if (isConfirmationOpen) {
      return (
        <ConfirmationReplaceBlock
          viewName={inputValue}
          onSuccess={this.onConfirmationSuccess}
          onCancel={this.handleCancel}
        />
      );
    }
    const dropdownItems = this.state.matchingFavoriteNames.map((name: string) => {
      const favorite = this.props.favorites.filter(f => f.name === name)[0];
      const displayedIcon = favorite && favorite.entityType === DockType.ChartMarket ? 'graph' : 'list';
      return (
        <li
          key={`favorite-${name}`}
          onClick={(e) => this.selectMatching(name)}
        >
          <span className={'oi oi-' + displayedIcon} />
          <span className="favorite-name" >
            <label>{name}</label>
          </span>
        </li>
      );
    });

    return (
      <form className="favorite__new" onKeyPress={this.handleKeyPress}>
        <div
          className="form-group"
          style={{ display: isFormVisible ? 'block' : 'none' }}
        >
          <div className="col">
            <input
              data-test="save-favorite-input"
              name="favName"
              onChange={this.handleChange}
              placeholder={I18n.t('favorite.form.name')}
              type="text"
              value={inputValue}
              required={true}
              autoFocus={true}
              autoComplete="off"
            />
          </div>
          <div className={'favorite__dropdown ' + (expanded ? 'visible' : '')}>
            <div className="favorite__results">
              <ul>{dropdownItems}</ul>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <button className="btn btn-cancellation w150 ml-auto" onClick={this.handleCancel}>
            <Translate
              value={'favorite.form.cancel'
              }
            />
          </button>
          <button data-test="save-favorite-button" className="btn btn-approval w150 ml-auto" onClick={this.handleSubmit} autoFocus={!isFormVisible}>
            <Translate
              value={
                isFormVisible ? 'favorite.form.save' : 'favorite.form.remove'
              }
            />
          </button>
        </div>
      </form>
    );
  }
}
