import { v1 } from 'uuid';
import * as Orders from '../actions/orders';
import { IOrder } from '../models/orders';
import * as Contracts from '../../orderbook/actions/contracts';
import * as OrderForm from '../actions/orderForm';
import * as orderFormReducer from './orderForm';
export interface State {
  ids: number[];
  entities: { [id: number]: IOrder };
  error: any;
  orderForm: orderFormReducer.State;
  dataVersion: string;
}

export const initialState: State = {
  ids: [],
  entities: {},
  error: null,
  orderForm: orderFormReducer.initialState,
  dataVersion: 'init'
};

export function reducer(
  state: State = initialState,
  action: Orders.Action | Contracts.Action | OrderForm.Action
) {
  switch (action.type) {
    case Orders.ActionTypes.REMOVE_ALL_ORDERS: {
      return {
        ...state,
        ...initialState,
        dataVersion: 'init'
      };
    }
    case Orders.ActionTypes.LOAD_SUCCESS: {
      const orders = action.payload;
      const newOrderIds = orders.map(order => order.orderId).filter(id => state.ids.indexOf(id) === -1);
      const newOrderEntities = orders.reduce(
        (entities: { [id: string]: IOrder }, order: IOrder) => {
          return Object.assign(entities, {
            [order.orderId]: {
              ...order,
              $index: order.orderId
            }
          });
        },
        {}
      );
      return {
        ...state,
        ids: [...state.ids, ...newOrderIds],
        entities: {
          ...state.entities,
          ...newOrderEntities
        },
        error: null,
        dataVersion: v1()
      };
    }
    case Orders.ActionTypes.REMOVE_ORDERS: {
      const canceledOrderIds = action.payload.map(order => order.orderId);
      const remainingOrderIds = state.ids.filter(id => canceledOrderIds.indexOf(id) === -1);
      
      const newOrderEntities = remainingOrderIds.reduce(
        (entities: { [id: string]: IOrder }, id: number) => {
          entities[id] = state.entities[id];
          return entities;
        },
        {}
      );
      return {
        ...state,
        ids: remainingOrderIds,
        entities: newOrderEntities,
        error: null,
        dataVersion: v1()
      };
    }

    case Orders.ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        error: action.error
      };
    }

    case OrderForm.ActionTypes.ORDER_FORM_SHOW:
    case OrderForm.ActionTypes.ORDER_FORM_HIDE:
    case Contracts.ActionTypes.SELECT_CONTRACT:
    case Contracts.ActionTypes.SEARCH_CONTRACTS:
    case Contracts.ActionTypes.CONTRACTS_STATE_CHANGED:
    case OrderForm.ActionTypes.ORDERBOOK_PREVIEW_LOAD_SUCCESS:
    case OrderForm.ActionTypes.ORDER_FORM_SANITY_CHECK: {
      return {
        ...state,
        orderForm: orderFormReducer.reducer(state.orderForm, action)
      };
    }
    default:
      return state;
  }
}
