
export interface SanityCheckRequest {
  contractId: string;
}


export interface SanityCheckResponse {
  sanityChecks: SanityCheck[];
}

export enum SanityCheckScope {
  GENERAL = 'General',
  PRODUCT = 'Product',
  USER = 'User'
}

export enum PriceDeviationType {
  LAST_PRICE = 'LAST_PRICE',
  ORDER_PRICE = 'ORDER_PRICE',
  MID_PRICE = 'MID_PRICE'
}

export enum DeviationMode {
  ABSOLUTE, PERCENTAGE
}

export interface SanityCheck {
    user: string;
    productId: string;
    productName: string;
    scope: SanityCheckScope;
    invertedPrice: boolean;
    matchesOwnOrders: boolean;
    emptyOrderbook: boolean;
    priceDeviation: PriceDeviationType;
    priceDeviationMode: DeviationMode;
    priceDeviationValue: number;
    minQuantityWarning: boolean;
    minQuantity: number;
    maxQuantityWarning: boolean;
    maxQuantity: number;
    orderValueWarning: boolean;
    orderValue: number;
}