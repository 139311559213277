import { StompService, StompClient } from '../../main/services/stompService';
import { Observable, of } from 'rxjs';
import { GenericRequest } from '../../main/models/application';
import {
  EnterOrderRequest,
  ModifyOrderRequest,
  CancelOrderRequest
} from '../models/orders';
import { ParseBulkOrdersRequest } from '../../bulkOrders/models/model';
import RestService from '../../main/services/restService';
import { OrderFormMode } from '../models/formData';
import { SanityCheckRequest } from '../models/sanityChecks';

export default class OrdersService extends StompService {
  private restService: RestService;

  constructor() {
    super(StompClient);
    this.restService = new RestService();
  }

  public sendOrdersInquiry(message: GenericRequest): Observable<any> {
    return of(
      this.sendMessage('/app/im/generic', JSON.stringify(message))
    );
  }

  public sendEnterOrder(message: EnterOrderRequest): Observable<any> {
    return of(
      this.sendMessage('/app/im/order/enter', JSON.stringify(message))
    );
  }

  public sendParseBulkOrdersFile(message: ParseBulkOrdersRequest): Observable<any> {
    return this.restService.restPost('/data/bulk/parsing', message);
  }

  public sendModifyOrder(message: ModifyOrderRequest): Observable<any> {
    return of(
      this.sendMessage('/app/im/order/modify', JSON.stringify(message))
    );
  }

  public sendCancelOrder(message: CancelOrderRequest): Observable<any> {
    return of(
      this.sendMessage('/app/im/order/cancel', JSON.stringify(message))
    );
  }

  public inquireOrders(): Observable<any> {
    return this.subscribe('/user/topic/im/ownorders');
  }

  public subscribeOrders(): Observable<any> {
    return this.subscribe('/user/topic/im/sub/orders');
  }

  subscribeOrderbookPreview() {
    return this.subscribe('/user/topic/im/sub/orderbook/preview');
  }

  public sendSubscribePreviewRequest(message: {contractId: string}): Observable<any> {
    return of(
      this.sendMessage('/app/im/sub/orderbook/preview', JSON.stringify(message))
    );
  }

  public sendUnsubscribePreviewRequest(): Observable<any> {
    return of(
      this.sendMessage('/app/im/unsub/orderbook/preview', JSON.stringify({}))
    );
  }

  sendOrderToUpdatePreview(message: {request: CancelOrderRequest | ModifyOrderRequest | EnterOrderRequest, mode: OrderFormMode}): any {
    return of(
      this.sendMessage('/app/im/orderbook/preview/update', JSON.stringify(message))
    );
  }

  public getSanityLimits(message: SanityCheckRequest): any {
    return this.restService.restPost('/data/sanitychecks', message);
  }
}
