import { config } from '../../main/config';
import {
  ITable,
  ITableColumn,
  ITableSort,
  TableAction,
  ITableSearchTag,
  prepareColumns,
  ITableRow
} from '../../shared/ui/models/table';
import { ComponentType } from '../../shared/ui/models/component';
import { MeetOrderTableRowPopover } from '../components/orderPopover';

export class OrderTable implements ITable {
  static index = 1;

  id: string;
  parentId: string;
  type: ComponentType = ComponentType.Order;
  actions: TableAction[] = [
    TableAction.SEARCH,
    TableAction.DOWNLOAD_CSV,
    TableAction.HIDDING_ROWS,
    TableAction.PINNING_ROWS
  ];
  columns: ITableColumn[] = [];
  sorting: ITableSort[];
  hiddenColumnNames: string[];
  hiddenRows: any[] = [];
  tags: ITableSearchTag[] = [];
  showHeadlines: boolean = true;
  showingHidden: boolean = false;
  rowPopoverComponent: any = null; // MeetOrderTableRowPopover; // currently turned off
  cellActionMapping = {};
  cellActions = {};
  filters = {};
  pinnedRows = [];

  constructor(parentId: string, customId?: string) {
    this.id = customId
      ? customId
      : new Date().getUTCMilliseconds().toString() + OrderTable.index++;
    this.parentId = parentId;
    this.columns = config.ui.orderTable.columns.map(c => { return {...c}; });

    if (customId) {
      this.hiddenColumnNames = prepareColumns(config.ui.orderTable, this.columns, true);
      this.sorting = config.ui.orderTable.recentDefaultSorting;
    } else {
      this.hiddenColumnNames = prepareColumns(config.ui.orderTable, this.columns, false);
      this.sorting = config.ui.orderTable.defaultSorting;
    }

    this.cellActionMapping = config.ui.orderTable.cellActions;
    this.cellActions = {
      showPopover: (rowIndex: string, columnKey: string, tableComponent: any, row: ITableRow, event: any) => {
        if (!config.readonlyMarketsheet) {  
          tableComponent.hidePopover();
          tableComponent.showPopover(MeetOrderTableRowPopover, row, event);
        }
      },
    };
  }
}
