import { combineReducers } from 'redux';
import { ActionTypes, cleaned } from '../../authentication/actions/connection';
import * as fromOrderbooks from './orderbook';
import * as fromCharts from './charts';

export interface OrderbooksState {
  orderbook: fromOrderbooks.State;
  charts: fromCharts.State;
}

export const initialState: OrderbooksState = {
  orderbook: fromOrderbooks.initialState,
  charts: fromCharts.initialState
};

export const orderbookReducer = combineReducers<OrderbooksState>({
  orderbook: fromOrderbooks.reducer,
  charts: fromCharts.reducer
});

export const state = (orderbookState: OrderbooksState, action: any) => {
  if (action.type === ActionTypes.CLOSE_CLEAN) {
    orderbookState = {
      ...initialState
    };
    return orderbookReducer(orderbookState, cleaned());
  }
  
  return orderbookReducer(orderbookState, action);
};
