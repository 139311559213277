import { IChartData } from '../models/charts';
import * as ChartActions from '../actions/charts';

export interface State {
    chartData: { [dockId: string]: {[contractId: string]: IChartData} };
    error: string | undefined;
    version: number;
}

export const initialState: State = {
    chartData: {},
    error: undefined,
    version: 0
};

export function reducer(state: State = initialState, action: ChartActions.Action) {
    switch (action.type) {
        case ChartActions.ActionTypes.LOAD_CHART_DATA_SUCCESS: {
            const dockIdState = {...state.chartData[action.dockId]};
            Object.keys(action.payload).forEach(contractId => {
                dockIdState[contractId] = action.payload[contractId];
            });
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    [action.dockId]: dockIdState,
                },
                version: state.version + 1,
                lastUpdate: new Date().getTime()
            };
        }

        case ChartActions.ActionTypes.LOAD_CHART_DATA_FAILURE: {
            return {
                ...state,
                error: action.payload
            };
        }

        case ChartActions.ActionTypes.UPDATE_CHART_DATA: {
            const dockIdState = {...state.chartData[action.dockId]};
            if (dockIdState[action.contract]) {
                if (dockIdState[action.contract].lastPrices.ohlc) {
                    const length = dockIdState[action.contract].lastPrices.ohlc.length;
                    const oldOhlc = length === 0 ? [0] : dockIdState[action.contract].lastPrices.ohlc[length - 1];
                    
                    if (oldOhlc[0] + action.period * 1000 > action.ohlc[0]) {
                        // update existing ohlc
                        const newOhlc: number[] = [
                            oldOhlc[0], oldOhlc[1], Math.max(oldOhlc[2], action.ohlc[2]), Math.min(oldOhlc[3], action.ohlc[3]), action.ohlc[4]
                        ];
                        dockIdState[action.contract].lastPrices.ohlc[length - 1] = newOhlc;
                        dockIdState[action.contract].lastPrices.volume[length - 1] = [oldOhlc[0],  dockIdState[action.contract].lastPrices.volume[length - 1][1] + action.volume[1]];
                    } else {
                        // add new ohlc
                        dockIdState[action.contract].lastPrices.ohlc.push(action.ohlc);
                        dockIdState[action.contract].lastPrices.volume.push(action.volume);
                    }
                }
            }
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    [action.dockId]: dockIdState
                },
                version: state.version + 1
            };
        }

        default:
            return state;
    }
}
