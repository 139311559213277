import OrderbookPreview from '../components/orderbookPreview';
import { State } from '../../main/reducers/rootReducer';
import { getFontColorsForMarket, getIsSeparateCellsActive } from '../../shared/settings/selectors/selector';
import { connect } from 'react-redux';
import { subscribeOrderbooks, unsubscribeOrderbooks, updateOrderbookPreview } from '../actions/orderForm';
import { getPreviewPrices } from '../selectors/orders';

const mapStateToProps = (state: State, ownProps: any) => {
    return {
        colors: getFontColorsForMarket(state),
        prices: getPreviewPrices(state),
        isSeparateCells: getIsSeparateCellsActive(state)
    };
};
  
const mapDispatchToProps = {
    subscribe: subscribeOrderbooks,
    unsubscribe: unsubscribeOrderbooks,
    updateOrder: updateOrderbookPreview
};
  
export default connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(OrderbookPreview);