import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { ActionTypes, SendLogToServer } from '../actions/logger';
import { StompService, StompClient } from '../../../main/services/stompService';
import { getClientLogLevel } from '../../../requests/selectors/requests';
import { State } from '../../../main/reducers/rootReducer';
import * as Rx from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';

const stompService = new StompService(StompClient);

export const messageListener: any = (
  actions$: ActionsObservable<SendLogToServer>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.SEND_LOG_TO_SERVER),
    switchMap(action => {
      if (action.payload.level <= getClientLogLevel(state.value)) {
        stompService.sendClientLogToServer(action);
      }
      return Rx.empty();
    }
  )
);
};

export const loggerEpics = combineEpics(messageListener);
