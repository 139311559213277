import { IGlobal } from '../models/global';

export enum ActionTypes {
  LOAD_GLOBAL = '[UI][Global] Load global UI',
  SET_MODAL_POSITION = '[UI][Global] Set modal position'
}

export interface GlobalLoadAction {
  type: ActionTypes.LOAD_GLOBAL;
  payload: IGlobal;
}

export interface GlobalSetModalPositionAction {
  type: ActionTypes.SET_MODAL_POSITION;
  payload: {
    modalId: string;
    positionClass: string;
  };
}

export function globalLoad(globalObj: IGlobal): GlobalLoadAction {
  return {
    type: ActionTypes.LOAD_GLOBAL,
    payload: globalObj
  };
}

export function setModalPosition(modalId: string, positionClass: string): GlobalSetModalPositionAction {
  return {
    type: ActionTypes.SET_MODAL_POSITION,
    payload: {
      modalId,
      positionClass
    }
  };
}

export type Action = GlobalLoadAction | GlobalSetModalPositionAction;
