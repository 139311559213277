import { Observable, of } from 'rxjs';
import RestService from '../../main/services/restService';
import { StompService, StompClient } from '../../main/services/stompService';

export default class BasketService extends StompService {
  private restService: RestService;

  constructor() {
    super(StompClient);
    this.restService = new RestService();
  }

  public requestSampleBasketFile(): Observable<any> {
    return this.restService.restFetch('/data/bulk/sample');
  }

  public sendBulkOrders(request: any): Observable<any> {
    return this.restService.restPost('/data/bulk/orders', request);
  }
}