import { ValidationResult } from './model';
import { LogAction } from '../../main/middlewares/logger';
import { LogLevel } from '../logger/models/logger';
import { I18n } from 'react-redux-i18n';

export enum ActionTypes {
    INVALID_INPUT = '[UI] [Validation] Invalid input'
}

export interface InvalidInputAction extends LogAction {
    type: ActionTypes.INVALID_INPUT;
    payload: ValidationResult;
}

export function handleInvalidInput(result: ValidationResult): InvalidInputAction {
    return {
        type: ActionTypes.INVALID_INPUT,
        payload: result,
        logLevel: LogLevel.WARN,
        logMessage: result.message ? result.message : I18n.t('validation.invalidInput', { input: result.value })
    };
}

export type Action =
    | InvalidInputAction;