import { Log, LogLevel } from '../models/logger';
import { LogAction } from '../../../main/middlewares/logger';
import { I18n } from 'react-redux-i18n';
import { convertSeverityToLogLevel } from '../helper/logger';

export enum ActionTypes {
    DEBUG = '[Logger] Debug',
    STATS = '[Logger] Stats',
    INFO = '[Logger] Info',
    WARN = '[Logger] Warn',
    ERROR = '[Logger] Error',
    FATAL = '[Logger] Fatal',
    CLEAR = '[Logger] Clear log',
    SEND_LOG_TO_SERVER = '[Logger] Send log to server',
    LOG_UNSPECIFIED_MESSAGE = '[Logger] Log unspecified backend message',
    LOG = '[Logger] Log message'
}

export interface LoggerDebug extends LogAction {
    type: ActionTypes.DEBUG;
}

export interface LoggerStats extends LogAction {
    type: ActionTypes.STATS;
}

export interface LoggerInfo extends LogAction {
    type: ActionTypes.INFO;
}

export interface LoggerWarn extends LogAction {
    type: ActionTypes.WARN;
}

export interface LoggerError extends LogAction {
    type: ActionTypes.ERROR;
}

export interface LoggerFatal extends LogAction {
    type: ActionTypes.FATAL;
}

export interface LoggerClear {
    type: ActionTypes.CLEAR;
}

export interface SendLogToServer {
    type: ActionTypes.SEND_LOG_TO_SERVER;
    payload: Log;
}

export interface Logger {
    type: ActionTypes.LOG;
}

export interface LogUnspecifiedMessage extends LogAction {
    type: ActionTypes.LOG_UNSPECIFIED_MESSAGE;
}

export function log(message: string, level: LogLevel) {
    return {
        type: ActionTypes.LOG,
        logMessage: message,
        logLevel: level
    };
}

export function debug(message: string): LoggerDebug {
    return {
        type: ActionTypes.DEBUG,
        logMessage: message,
        logLevel: LogLevel.DEBUG
    };
}

export function stats(message: string): LoggerStats {
    return {
        type: ActionTypes.STATS,
        logMessage: message,
        logLevel: LogLevel.STATS
    };
}

export function info(message: string): LoggerInfo {
    return {
        type: ActionTypes.INFO,
        logMessage: message,
        logLevel: LogLevel.INFO
    };
}

export function warn(message: string): LoggerWarn {
    return {
        type: ActionTypes.WARN,
        logMessage: message,
        logLevel: LogLevel.WARN
    };
}

export function error(message: string): LoggerError {
    return {
        type: ActionTypes.ERROR,
        logMessage: message,
        logLevel: LogLevel.ERROR
    };
}

export function fatal(message: string): LoggerFatal {
    return {
        type: ActionTypes.FATAL,
        logMessage: message,
        logLevel: LogLevel.FATAL
    };
}

export function clear(): LoggerClear {
    return {
        type: ActionTypes.CLEAR
    };
}

export function sendLogToServer(logContent: { message: string, level: LogLevel }): SendLogToServer {
    return {
        type: ActionTypes.SEND_LOG_TO_SERVER,
        payload: new Log(logContent.message, logContent.level)
    };
}

export function logUnspecifiedMessage(payload: any): LogUnspecifiedMessage {
    return {
        type: ActionTypes.LOG_UNSPECIFIED_MESSAGE,
        logMessage: payload.message ? payload.message : payload.error ? payload.error : JSON.stringify(payload),
        logLevel: convertSeverityToLogLevel(payload.severity)
    };
}

export type Action =
    | LoggerDebug
    | LoggerStats
    | LoggerInfo
    | LoggerWarn
    | LoggerError
    | LoggerFatal
    | LoggerClear
    | SendLogToServer
    | Logger 
    ;
