import { v4 as uuid } from 'uuid';
import { ITable, TableAction, ITableColumn, ITableSort, ITableSearchTag, prepareColumns } from '../../ui/models/table';
import { ComponentType } from '../../ui/models/component';
import { config } from '../../../main/config';

export interface ILog {
    [key: string]: any;
    $index: string;
    id: string;
    message: string;
    level: LogLevel;
    time: Date;
}

export class Log implements ILog {
    [key: string]: any;
    $index: string;
    public message: string;
    public level: LogLevel;
    public time: Date;
    public id: string;
    
    constructor(message: string, level: LogLevel) {
        this.message = message;
        this.level = level;
        this.time = new Date();
        this.id = uuid();
    }

}

export enum LogLevel {
    DEBUG = 6,
    STATS = 5,
    INFO = 4,
    WARN = 3,
    ERROR = 2,
    FATAL = 1
}

export class LogsTable implements ITable {
    static index = 1;
  
    id: string;
    parentId: string;
    type: ComponentType = ComponentType.Log;
    actions: TableAction[] = [
      TableAction.SEARCH
    ];
    columns: ITableColumn[] = [];
    sorting: ITableSort[];
    hiddenColumnNames: string[];
    hiddenRows: any[] = [];
    tags: ITableSearchTag[] = [];
    showHeadlines: boolean = true;
    showingHidden: boolean = false;
    filters = {};
    pinnedRows = [];
  
    constructor(parentId: string, customId?: string) {
      this.id = customId
        ? customId
        : new Date().getUTCMilliseconds().toString() + LogsTable.index++;
      this.parentId = parentId;
      this.columns = config.ui.logTable.columns.map(c => { return {...c}; });

      if (customId) {
        this.hiddenColumnNames = prepareColumns(config.ui.logTable, this.columns, true);
      } else {
        this.hiddenColumnNames = prepareColumns(config.ui.logTable, this.columns, false);
      }

      this.sorting = config.ui.logTable.defaultSorting;
    }
  }