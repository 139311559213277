import { ActionsObservable, StateObservable, combineEpics } from 'redux-observable';
import { State } from '../../main/reducers/rootReducer';
import { filter, map, mergeMap } from 'rxjs/operators';
import * as Quadrant from '../actions/quadrants';
import * as Dashboard from '../actions/dashboard';
import * as Dock from '../../shared/dock/actions/dock';
import { of, empty, EMPTY } from 'rxjs';
import { inactive } from '../../shared/dock/actions/dock';
import { cleanDashboard, connectDocks, loadSuccess } from '../actions/dashboard';
import { getDashboardQuadrantsEntities, getDashboardQuadrants, getAxisX, getAxisY } from '../selectors/quadrantPanel';
import { removeDockFromQuadrant, dashboardQuadrantsResize } from '../actions/quadrants';
import { load } from '../actions/profile';
import { IDock } from '../../shared/dock/models/dock';

function areDocksCompatible(dock1: IDock, dock2: IDock) {
  return dock1 && dock2 && dock1.type === dock2.type;
}

export const addDockAndDeactivate: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Quadrant.ActionTypes.QUADRANT_ADD_DOCK),
    map(action => action.payload),
    mergeMap(payload => {
      return of(inactive(payload.dock.id, {x: 0, y: 0}, {width: 100, height: 100}))
    })
  );
};

export const connectToDock: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Quadrant.ActionTypes.QUADRANT_ADD_DOCK),
    map(action => action.payload),
    mergeMap(payload => {
      const quadrantEntities = getDashboardQuadrantsEntities(state.value);
      if (quadrantEntities && quadrantEntities[payload.quadrantId] && quadrantEntities[payload.quadrantId].dock && areDocksCompatible(payload.dock, quadrantEntities[payload.quadrantId].dock)) {
        return of(connectDocks(payload.dock.id, quadrantEntities[payload.quadrantId].dock!.id, payload.dock.type));
      }
      return of(Dock.remove(payload.dock.id));
    })
  );
};

export const toggleQuadrantGrid: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Quadrant.ActionTypes.QUADRANT_GRID_TOGGLE),
    map(action => action.payload),
    mergeMap(payload => {
      return of(cleanDashboard(state.value.dashboard.grid.active));
    })
  );
};

export const triggerResize: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Dashboard.ActionTypes.RESIZE),
    map(action => action.payload),
    mergeMap(payload => {
      return of(dashboardQuadrantsResize(getDashboardQuadrants(state.value), getAxisX(state.value), getAxisY(state.value)));
    })
  );
};

export const removeQuadrantDock: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Dock.ActionTypes.REMOVE),
    map(action => action.payload),
    mergeMap(payload => {
      return of(removeDockFromQuadrant(payload))
    })
  )
}



export const quadrantEpic = combineEpics(
  addDockAndDeactivate,
  connectToDock,
  toggleQuadrantGrid,
  triggerResize,
  removeQuadrantDock
);