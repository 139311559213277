import { IQuadrant } from '../models/analyticsPanel';

const ONE_HUNDRED_PERCENT = 100;

export class QuadrantsCheck {
  private ids: string[];
  constructor(quadrants: { [key: number]: IQuadrant }) {
    this.ids = Object.keys(quadrants);
  }

  public doesExist(id: string): boolean {
    return this.ids.indexOf(id) !== -1;
  }

  public doExist(ids: string[]): boolean {
    return ids.reduce((result: boolean, id: string) => {
      if (result) {
        return result;
      }
      return this.ids.indexOf(id) !== -1;
    }, false);
  }

  public containsAll(ids: string[]): boolean {
    return ids.every(id => this.ids.indexOf(id) !== -1);
  }
}

function isOverflowVertical(layout: number[], id: number): boolean {
  if (!layout || layout.length < 4) {
    return false;
  }
  switch (id) {
    case 1: return layout[2] == 1;
    case 2: return layout[3] == 2;
    case 3: return layout[1] == 3;
    case 4: return layout[0] == 4;
    default: return false;
  }  
}

function isOverflowHorizontal(layout: number[], id: number): boolean {
  if (!layout || layout.length < 4) {
    return false;
  }
  switch (id) {
    case 1: return layout[1] == 1;
    case 2: return layout[0] == 2;
    case 3: return layout[3] == 3;
    case 4: return layout[2] == 4;
    default: return false;
  }  
}

// PUBLIC
export const updateQuardrantsSizes = (
  quadrants: {
    [key: number]: IQuadrant;
  },
  axisX: number,
  axisY: number,
  layout: number[]
): {[id: string]: IQuadrant} => {
  return Object.keys(quadrants).reduce(
    (acc: { [key: number]: IQuadrant }, key: string) => {
      const getWidth = () => {
        switch (key) {
          case '1':
            return !isOverflowHorizontal(layout, 1) ? axisX : ONE_HUNDRED_PERCENT;
          case '2':
            return !isOverflowHorizontal(layout, 2) ? ONE_HUNDRED_PERCENT - axisX : ONE_HUNDRED_PERCENT;
          case '3':
            return !isOverflowHorizontal(layout, 3) ? axisX : ONE_HUNDRED_PERCENT;
          case '4':
            return !isOverflowHorizontal(layout, 4) ? ONE_HUNDRED_PERCENT - axisX : ONE_HUNDRED_PERCENT;
          default:
            return ONE_HUNDRED_PERCENT;
        }
      };

      const getHeight = () => {
        switch (key) {
          case '1':
            return !isOverflowVertical(layout, 1) ? axisY : ONE_HUNDRED_PERCENT;
          case '2':
            return !isOverflowVertical(layout, 2) ?  axisY: ONE_HUNDRED_PERCENT;
          case '3':
            return !isOverflowVertical(layout, 3) ? ONE_HUNDRED_PERCENT - axisY : ONE_HUNDRED_PERCENT;
          case '4':
            return !isOverflowVertical(layout, 4) ? ONE_HUNDRED_PERCENT - axisY : ONE_HUNDRED_PERCENT;
          default:
            return ONE_HUNDRED_PERCENT;
        }
      };

      return {
        ...acc,
        [key]: {
          ...quadrants[Number(key)],
          size: {
            width: getWidth(),
            height: getHeight()
          }
        }
      };
    },
    {}
  );
};

export const updateQuardrantsSizesOnMove = (
  quadrants: IQuadrant[],
  axisX: number,
  axisY: number,
  qc: QuadrantsCheck,
  layout: number[]
) => {
  return quadrants.reduce((acc: IQuadrant[], quadrant: IQuadrant) => {
    const getWidth = () => {
      switch (quadrant.id) {
        case 1:
          return !isOverflowHorizontal(layout, 1) ? axisX : ONE_HUNDRED_PERCENT;
        case 2:
          return !isOverflowHorizontal(layout, 2) ? ONE_HUNDRED_PERCENT - axisX : ONE_HUNDRED_PERCENT;
        case 3:
          return !isOverflowHorizontal(layout, 3) ? axisX : ONE_HUNDRED_PERCENT;
        case 4:
          return !isOverflowHorizontal(layout, 4) ? ONE_HUNDRED_PERCENT - axisX : ONE_HUNDRED_PERCENT;
        default:
          return ONE_HUNDRED_PERCENT;
      }
    };

    const getHeight = () => {
      switch (quadrant.id) {
        case 1:
          return !isOverflowVertical(layout, 1) ? axisY : ONE_HUNDRED_PERCENT;
        case 2:
          return !isOverflowVertical(layout, 2) ? axisY : ONE_HUNDRED_PERCENT;
        case 3:
          return !isOverflowVertical(layout, 3) ? ONE_HUNDRED_PERCENT - axisY : ONE_HUNDRED_PERCENT;
        case 4:
          return !isOverflowVertical(layout, 4) ? ONE_HUNDRED_PERCENT - axisY : ONE_HUNDRED_PERCENT;
        default:
          return ONE_HUNDRED_PERCENT;
      }
    };

    return [
      ...acc,
      {
        ...quadrant,
        size: {
          width: getWidth(),
          height: getHeight()
        }
      }
    ];
  }, []);
};

export const setResizers = (
  axisX: number,
  axisY: number,
  qc: QuadrantsCheck
) => {
  const res = {
    x: {
      visible: qc.containsAll(['1', '3']) || qc.containsAll(['2', '4']),
      position: axisX,
      size: (qc.doesExist('1') || qc.doesExist('2')) && !qc.containsAll(['1', '2'])
            ? axisY
            : (qc.doesExist('3') || qc.doesExist('4')) &&
              !qc.containsAll(['3', '4'])
              ? -ONE_HUNDRED_PERCENT + axisY
              : 0
    },
    y: {
      visible: qc.containsAll(['1', '2']) || qc.containsAll(['3', '4']),
      position: axisY,
      size: ONE_HUNDRED_PERCENT
    }
  };
  return res;
};
