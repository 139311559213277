import { createContext } from 'react';

const { Provider, Consumer } = createContext<any>({
    /* Default Values are only available for components who are not wrapped in a Provider but only in a Consumer,
       in order to Test them isolated */

    selectedTheme: 'theme-exxeta',
});

export { Provider, Consumer };
