import { connect } from 'react-redux';
import { State } from '../../main/reducers/rootReducer';
import { getOrderRows } from '../selectors/orders';
import { ComponentType } from '../../shared/ui/models/component';
import { getTableForTab } from '../../shared/ui/selectors/ui';
import {
  triggerColumnNames,
  triggerHeadlines,
  sort,
  hideRows,
  showRows,
  triggerSearchTags,
  triggerShowHidden,
  pinRows,
  unpinRows
} from '../../shared/ui/actions/table';
import { triggerFilter } from '../../shared/ui/actions/tab';

import UITable from '../../shared/ui/components/table/components/uiTableComponent';

const mapStateToProps = (state: State, props: any) => ({
  table: getTableForTab(state, props.parentId, ComponentType.Order),
  rows: getOrderRows(
    state,
    getTableForTab(state, props.parentId, ComponentType.Order),
    props.parentId
  )
});

const mapDispatchToProps = {
  sort: sort,
  triggerColumnNames: triggerColumnNames,
  triggerHeadlines: triggerHeadlines,
  triggerShowHidden: triggerShowHidden,
  triggerFilter: triggerFilter,
  triggerSearchTags: triggerSearchTags,
  hideRows: hideRows,
  showRows: showRows,
  pinRows: pinRows,
  unpinRows: unpinRows
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(UITable);
