import withAutoClose from '../../autoclose/components/Autoclose';
import * as React from 'react';
import { MemoTranslate } from '../../i18n/components/memoTranslate';
import { I18n, Translate } from 'react-redux-i18n';

interface State {
  expanded: boolean; // from withAutoClose
  selectedItem: any;
  phrase: string;
  results: any[];
  displayLimit?: number;
}

interface Props {
  list: any[];
  itemValue: (item: any) => any;
  itemName: (item: any) => string;
  itemId: (item: any) => string;
  onSelect: (item: any) => void;
  selectedItem: any;
  visibleDropdown?: boolean;
  searchable?: boolean;
  placeholder?: string;
  disabled?: boolean;
  displayLimit?: number;
}

class DropdownList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expanded: false,
      selectedItem: props.selectedItem,
      results: [],
      phrase: this.props.selectedItem ? this.props.itemName(props.selectedItem) : '',
      displayLimit: this.props.displayLimit
    };
  }  

  _select = (e: any, item: any) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        selectedItem: item,
        expanded: false,
        phrase: this.props.itemName(item)
      };
    });
    this.props.onSelect(item);
  }

  _expand = (e: any) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        expanded: !prevState.expanded
      };
    });
  }

  _getFilteredResults = (phrase: string): any[] => {
    let filteredResults: any[] = []; 
    filteredResults = this.props.list.map(c => { return {name: this.props.itemName(c), id: this.props.itemId(c)}; });
    
    return this._filterResults(phrase, filteredResults);
  }

  _search = (event: any) => {
    const target = event.target;
    const value = target.value;
    this.setState(prevState => {
      return {
        ...prevState,
        results: this._getFilteredResults(value),
        phrase: value,
        expanded: true
      };
    });
  }

  _filterResults = (phrase: string, results: any[]) => {
    if (!phrase || phrase.length < 3) {
      return [];
    }
    let phraseLowerCase = phrase.toLowerCase();
    let res = [];
    for (let i = 0; i < results.length; i++) {
      if (this.props.itemName(results[i]).toLowerCase().indexOf(phraseLowerCase) > -1) {
        res.push(results[i]);
      }
    }
    return res;
  }

  getDropdownItems(listItems: any[]) {
    const items = this.state.displayLimit ? listItems.slice(0, this.state.displayLimit) : listItems;
    const htmlItems = items.map(item => {
      const id = this.props.itemId(item);
      const name = this.props.itemName(item);
      return (
        <li 
          className={(this.state.selectedItem && id === this.props.itemId(this.state.selectedItem)) ? 'selected' : ''}
          key={`dropdown-${id}`} 
          onClick={e => this._select(e, item)}
        >
          {name}
        </li>
      );
    });
    if (this.state.displayLimit && htmlItems.length > 0 && htmlItems.length < listItems.length) {
      htmlItems.push(
        (<li 
          className="additional-item"
          key={`dropdown-more`} 
          onClick={e => this._increaseLimit(e)}
        ><MemoTranslate value="general.dropdown.showMore" />
        </li>)
        );
    }
    return htmlItems;
  }

  _increaseLimit = (e: any) => {
    this.setState(prevState => {
      return {
        ...prevState,
        displayLimit: prevState.displayLimit! + this.props.displayLimit!
      };
    });
  }

  render() {
    const {list} = this.props;
    let dropdownItems: any = [];
    if (list.length === 0) {
      dropdownItems = (
        <li className="p-2">
          <MemoTranslate value="general.dropdown.empty" />
        </li>
      );
    } else if (!this.props.searchable) {
      dropdownItems = this.getDropdownItems(list);        
      
    }  

    let selectedItemComponent;
    if (this.props.searchable && list.length > 0) {
      dropdownItems = this.getDropdownItems(this.state.results); 

      selectedItemComponent = (
        <input 
          type="text"
          value={this.state.phrase}
          onChange={this._search}
          placeholder={this.props.placeholder || I18n.t('general.dropdown.search')}
          disabled={!!this.props.disabled}
        />
      );
    } else {
      selectedItemComponent = (<>{this.state.selectedItem ? this.state.selectedItem.name : ''}</>);
    }

    return (
      <div className={`dropdown-list ${this.props.visibleDropdown ? 'visible' : ''}`}>
        
        <div style={{display: !!this.props.visibleDropdown ? 'none' : 'block'}} onClick={this._expand}>
          {selectedItemComponent}
        </div>
        
        <ul style={{display: !this.state.expanded && !this.props.visibleDropdown ? 'none' : 'block'}}>{dropdownItems}</ul>
      </div>
    );
  }
}

export default withAutoClose(DropdownList);