import * as React from 'react';
import { State } from '../../../main/reducers/rootReducer';
import { connect } from 'react-redux';
import ModalWindow from '../../modalWindow/components/modalWindow';
import { Instrument } from '../../../instruments/models/instruments';
import { getAllInstruments } from '../../../instruments/selectors/instruments';

export interface MappedInfoProps {
    instruments: Instrument[];
    modalVisible: boolean;
    closeModal: () => void;
}

export interface MappedInfoState {
    modalVisible: boolean;
}

export class MappedInfoModalComponent extends React.Component<
    MappedInfoProps,
    MappedInfoState
    > {
    constructor(props: MappedInfoProps) {
        super(props);
        this.state = {
            modalVisible: props.modalVisible
        };
    }

    render() {
        const { modalVisible, instruments } = this.props;
       
        const mapped = {};
        for (let i = 0; i < instruments.length; i++) {
            if (instruments[i]?.mappedInstruments?.length > 0) {
                instruments[i].mappedInstruments.forEach(mappedInstrument => {
                    mapped[mappedInstrument] = !mapped.hasOwnProperty(mappedInstrument) ? [instruments[i].name] : [...mapped[mappedInstrument], instruments[i].name];
                })
            }
        }

        return (
            <div className="meet-settings__modal">
                <ModalWindow
                    id="version-info"
                    onModalClose={() =>
                        this.props.closeModal()
                    }
                    isOpen={modalVisible}
                    title={'mapped.title'}
                >
                    <div style={{maxHeight: '500px', overflow: 'auto'}}>
                        {Object.keys(mapped).map(mappedInstrument => (
                            <li key={'parent-' + mappedInstrument}>
                                {mappedInstrument} 
                                <ul key={'parent-mapped-' + mappedInstrument}>
                                    {mapped[mappedInstrument].map(instrument => (<li key={mappedInstrument+'-'+instrument}>{instrument}</li>))}
                                </ul>
                            </li>
                        ))}
                    </div>
                    <div className="modal-footer" />
                </ModalWindow>
            </div>
        );
    }
}

const mapStateToProps = (state: State) => ({
    instruments: getAllInstruments(state)
});

const mapDispatchToProps = {
};

export default connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
)(MappedInfoModalComponent);
