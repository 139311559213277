
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import * as Rx from 'rxjs';
import { State } from '../../main/reducers/rootReducer';
import { GenericRequest } from '../../main/models/application';
import { receiveMessage } from '../../shared/messenger/actions/messenger';
import * as Connection from '../../authentication/actions/connection';
import { filter, mergeMap, takeUntil, map, switchMap, catchError } from 'rxjs/operators';
import BusinessUnitPortfolioService from '../services/businessUnitPortfolios';
import { ActionTypes as Authentication } from '../../authentication/actions/authentication';
import { ActionTypes as Contracts } from '../../orderbook/actions/contracts';
import { businessUnitsLoadSuccess, businessUnitsLoadFailure } from '../actions/businessUnits';
const businessUnitPortfolioService = new BusinessUnitPortfolioService();

export const sendBusinessUnitSubscritpion: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Contracts.STATIC_DATA_LOADED || action.type === Authentication.RELOGIN_SUCCESS),
    map(action => action.payload),
    switchMap(() => {
      businessUnitPortfolioService.sendBusinessUnitInquiry(<GenericRequest> {
        requestType: 'SUBSCRIBE_BUSINESSUNITS_REQUEST'
      });
      return Rx.empty();
    }),
    takeUntil(
      actions$.pipe(filter(action => action.type === Connection.ActionTypes.DISCONNECT || action.type === Connection.ActionTypes.CONNECTION_LOST))
    ),
    catchError(error => {
      return Rx.of(receiveMessage('', error, true));
    })
  );
};

export const subscribeBusinessUnits: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Contracts.STATIC_DATA_LOADED || action.type === Authentication.RELOGIN_SUCCESS),
    map(action => action.payload),
    switchMap(() => {
      return businessUnitPortfolioService.subscribeBusinessUnits().pipe(
        mergeMap((content: any) => {
          return Rx.of(businessUnitsLoadSuccess(content.businessUnits));
        }),
        catchError(error => {
          return Rx.of(businessUnitsLoadFailure(error));
        }),
        takeUntil(
          actions$.pipe(filter(action => action.type === Connection.ActionTypes.DISCONNECT
            || action.type === Connection.ActionTypes.CONNECTION_LOST))
        ));
    })
  );
};

//   export const sendPortfolioSubscritpion: any = (
//     actions$: ActionsObservable<any>,
//     state: StateObservable<State>
//   ) => {
//     return actions$.pipe(
//         filter(action => action.type === Contracts.STATIC_DATA_LOADED ||  action.type === Authentication.RELOGIN_SUCCESS),
//         map(action => action.payload),
//       switchMap(() => {
//         businessUnitPortfolioService.sendPortfolioInquiry(<GenericRequest> {
//           requestType: 'SUBSCRIBE_PORTFOLIO_CLASSES_REQUEST'
//         });
//         return Rx.empty();
//       }),
//       takeUntil(
//         actions$.pipe(filter(action => action.type === Connection.ActionTypes.DISCONNECT || action.type === Connection.ActionTypes.CONNECTION_LOST))
//       ),
//       catchError(error => {
//         return Rx.of(receiveMessage('', error, true));
//       })
//     );
//   };

//   export const subscribePortfolios: any = (actions$: ActionsObservable<any>) => {
//     return actions$.pipe(
//         filter(action => action.type === Contracts.STATIC_DATA_LOADED ||  action.type === Authentication.RELOGIN_SUCCESS),
//         map(action => action.payload),
//       switchMap(() => {
//         return businessUnitPortfolioService.subscribePortfolios().pipe(
//           mergeMap((content: any) => {
//              return Rx.of(inquirePortfolios(content.portfolioClasses));
//           }),
//           catchError(error => {
//             return Rx.of(inquirePortfoliosFailed(error));
//           }),
//           takeUntil(
//             actions$.pipe(filter(action => action.type === Connection.ActionTypes.DISCONNECT  
//               || action.type === Connection.ActionTypes.CONNECTION_LOST))
//           ));
//       })
//     );
//   };

export const businessUnitsPortfoliosEpic = combineEpics(
  subscribeBusinessUnits,
  // subscribePortfolios,
  sendBusinessUnitSubscritpion,
  // sendPortfolioSubscritpion
);
