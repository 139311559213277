import * as Analytics from '../actions/analyticPanel';
import { IQuadrant } from '../models/analyticsPanel';
import { updateQuardrantsSizes } from '../helper/analyticsPanel';

export interface State {
  locked: boolean;
  height: number;
  axises: {
    x: number;
    y: number;
  };
  quadrants: {
    ids: number[];
    entities: { [key: number]: IQuadrant };
  };
}

export const initialState: State = {
  locked: false,
  height: 0,
  axises: {
    x: 50,
    y: 50
  },
  quadrants: {
    ids: [],
    entities: {}
  }
};

export function reducer(state: State = initialState, action: Analytics.Action) {
  switch (action.type) {
    case Analytics.ActionTypes.LOAD: {
      const { axises, quadrants, locked, height } = action.payload;
      return {
        ...state,
        locked: locked,
        height: locked ? height : 0,
        axises: axises,
        quadrants: {
          ids: quadrants.ids,
          entities: updateQuardrantsSizes(
            quadrants.entities,
            axises.x,
            axises.y
          )
        }
      };
    }

    case Analytics.ActionTypes.SET_QUADRANT: {
      const quadrant = action.payload;
      const newIds = [...state.quadrants.ids, quadrant.id].filter(
        (v, i, a) => a.indexOf(v) === i // only unique quadrant ids
      );

      const newQuadrantEntities = {
        ...state.quadrants.entities,
        [quadrant.id]: quadrant
      };

      return {
        ...state,
        quadrants: {
          ...state.quadrants,
          ids: newIds,
          entities: updateQuardrantsSizes(
            newQuadrantEntities,
            state.axises.x,
            state.axises.y
          )
        }
      };
    }

    case Analytics.ActionTypes.UPDATE_QUADRANT: {
      const quadrant = action.payload;
      return {
        ...state,
        quadrants: {
          ...state.quadrants,
          entities: {
            ...state.quadrants.entities,
            [quadrant.id]: quadrant
          }
        }
      };
    }

    case Analytics.ActionTypes.REMOVE_QUADRANT: {
      const quadrantId = action.payload;
      const newQuadrantIds = state.quadrants.ids.filter(
        id => id !== quadrantId
      );
      const newQuadrantEntities = newQuadrantIds.reduce(
        (entities: { [id: number]: IQuadrant }, id: number) => {
          return Object.assign(entities, {
            [id]: state.quadrants.entities[id]
          });
        },
        {}
      );

      return {
        ...state,
        quadrants: {
          ...state.quadrants,
          ids: newQuadrantIds,
          entities: updateQuardrantsSizes(
            newQuadrantEntities,
            state.axises.x,
            state.axises.y
          )
        }
      };
    }

    case Analytics.ActionTypes.QUADRANT_RESIZE: {
      const { quadrants, axisX, axisY } = action.payload;
      return {
        ...state,
        axises: {
          x: axisX,
          y: axisY
        },
        quadrants: {
          ...state.quadrants,
          entities: quadrants.reduce(
            (acc: { [key: number]: IQuadrant }, q: IQuadrant) => {
              return {
                ...acc,
                [q.id]: q
              };
            },
            {}
          )
        }
      };
    }

    case Analytics.ActionTypes.TRIGGER_PANEL_LOCK: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
}
