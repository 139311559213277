import { createSelector } from 'reselect';
import { State } from '../../../main/reducers/rootReducer';
import { argsSelectorCreator } from '../../utils/selectors/agrSelector';
import { ITable } from '../../ui/models/table';
import { Log } from '../models/logger';
import { sortTable } from '../../utils/helper/sorter';
import { getClientLogLevel } from '../../../requests/selectors/requests';

export const getLogState = (state: State) => state.logs;

export const getAllLogs = createSelector(getLogState, s => s.logs);

export const getLogsForClientLevel = createSelector(
  [getAllLogs, getClientLogLevel],
  (logs, level) => logs.filter(e => e.level <= level)
);

export const getLogRows = argsSelectorCreator(
  [getLogsForClientLevel, (state: State, logTable: ITable | undefined) => logTable],
  (logs, logTable) => {
    if (logTable) {
      const _logs = logs.map((log: Log) => {
        return logTable.columns.reduce((result: any, col) => {
          return Object.assign(result, {
            [col.name]: log[col.originalName]
          });
        }, { id: log.id });
      });
      return sortTable(_logs, logTable.sorting, logTable.hiddenColumnNames);
    }
    return [];
  }
);

export const getVersion = createSelector(getLogState, s => s.version);