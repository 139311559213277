import { StompService, StompClient } from '../../main/services/stompService';
import { PriceAlarmCreateRequest, PriceAlarmModifyRequest, PriceAlarmDeleteRequest, PriceAlarmResponse } from '../models/priceAlarms';
import * as Rx from 'rxjs';
import { Observable } from 'rxjs';
import RestService from '../../main/services/restService';

export default class PriceAlarmsService extends StompService {
  private restService: RestService;

  constructor() {
    super(StompClient);
    this.restService = new RestService();
  }

  public sendPriceAlarmCreateRequest(message: PriceAlarmCreateRequest): Observable<any> {
    return Rx.of(
      this.sendMessage('/app/im/pricealarms/create', JSON.stringify(message))
    );
  }

  public sendPriceAlarmModifyRequest(message: PriceAlarmModifyRequest): Observable<any> {
    return Rx.of(
      this.sendMessage('/app/im/pricealarms/modify', JSON.stringify(message))
    );
  }

  public sendPriceAlarmDeleteRequest(message: PriceAlarmDeleteRequest): Observable<any> {
    return Rx.of(
      this.sendMessage('/app/im/pricealarms/delete', JSON.stringify(message))
    );
  }

  public subscribePriceTriggerAlarm(): Observable<any> {
    return this.subscribe('/user/topic/im/sub/pricealarms/trigger');
  }

  public subscribePriceAlarms(): Observable<any> {
    return this.subscribe('/user/topic/im/sub/pricealarms');
  }

  public getPriceAlarms(): Observable<PriceAlarmResponse> {
    return this.restService.restFetch('/data/pricealarms');
  }

  public subscribeDeletePriceAlarm(): Observable<any> {
    return this.subscribe('/user/topic/im/sub/pricealarms/delete');
  }
}