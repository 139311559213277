import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import * as Rx from 'rxjs';
import OrdersService from '../services/orders';
import {
  ActionTypes,
  ordersLoadSuccess,
  ordersLoad,
  ordersLoadFailure,
  orderEnter,
  orderEnterComplete,
  orderFailure,
  orderCancelComplete,
  orderModifyComplete,
  orderModify,
  orderCancel,
  ordersRemove,
  ordersRemoveAll
} from '../actions/orders';
import * as OrderForm from '../actions/orderForm';
import { State } from '../../main/reducers/rootReducer';
import { ActionTypes as Authentication } from '../../authentication/actions/authentication';
import { ActionTypes as Contracts } from '../../orderbook/actions/contracts';
import { GenericRequest } from '../../main/models/application';
import {
  EnterOrderRequest,
  CancelOrderRequest,
  ModifyOrderRequest,
  IOrder,
  State as OrderState
} from '../models/orders';
import { ParseBulkOrdersRequest } from '../../bulkOrders/models/model';
import OrderFormData, { OrderFormMode } from '../models/formData';
import ValidationService from '../../shared/validation/service';
import { Validation } from '../../shared/validation/model';
import { receiveMessage } from '../../shared/messenger/actions/messenger';
import * as Connection from '../../authentication/actions/connection';
import { ActionTypes as BulkActions, parseSuccess, parseFailure } from '../../bulkOrders/actions/bulkOrders';
import { filter, mergeMap, takeUntil, map, switchMap, catchError } from 'rxjs/operators';
import orderBookStore from '../../orderbook/store/orderbooks';
import { createOrderRequest } from '../helper/orders';
import { orderbookPreviewLoadSuccess, orderFormSanityCheck } from '../actions/orderForm';
import { getOrderbookContracts } from '../../orderbook/selectors/contracts';
const ordersService = new OrdersService();
const validationService = new ValidationService();

export const connection: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Contracts.STATIC_DATA_LOADED ||  action.type === Authentication.RELOGIN_SUCCESS),
    map(action => action.payload),
    mergeMap(() => {
      return Rx.of(ordersLoad()).pipe(takeUntil(
        actions$.pipe(filter(action => action.type === Authentication.AUTHENTICATION_LOGOUT))));
    })
  );
};

export const sendOrderSubscritpion: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    // has to be LOAD to have own orders after relogin => 
    // leads to error message due to duplicate subscription with SubscribeProductResponse
    filter(action => action.type === ActionTypes.LOAD),
    map(action => action.payload),
    switchMap(() => {
      ordersService.sendOrdersInquiry(<GenericRequest> {
        requestType: 'SUBSCRIBE_ORDERS_REQUEST'
      });
      return Rx.empty();
    }),
    takeUntil(
      actions$.pipe(filter(action => action.type === Connection.ActionTypes.DISCONNECT || action.type === Connection.ActionTypes.CONNECTION_LOST))
    ),
    catchError(error => {
      return Rx.of(receiveMessage('', error, true));
    })
  );
};

export const loadOrders: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.LOAD),
    map(action => action.payload),
    switchMap(() => {
      return ordersService
        .inquireOrders().pipe(
        mergeMap((content: any) => {
          let orders = content.orders;
          return Rx.of(ordersRemoveAll(), ordersLoadSuccess(orders));
        }),
        catchError(error => {
          return Rx.of(ordersLoadFailure(error));
        }),        
        takeUntil(
          actions$.pipe(filter(action => action.type === Connection.ActionTypes.DISCONNECT  
            || action.type === Connection.ActionTypes.CONNECTION_LOST))
        ));
    })
  );
};

export const subscribeOrders: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.LOAD),
    map(action => action.payload),
    switchMap(() => {
      return ordersService
        .subscribeOrders().pipe(
        mergeMap((content: any) => {
          let orders = content.orders ? content.orders : [content.order];
          const canceledOrders = orders.filter((order: IOrder) => { return order.state === OrderState.CANCELED; });
          const createdOrders = orders.filter((order: IOrder) => {  return order.state === OrderState.OPEN; });
          const fullyExecutedOrders = orders.filter((order: IOrder) => { return order.state === OrderState.FULLY_EXECUTED; });
          return Rx.of(ordersLoadSuccess(createdOrders), ordersRemove(canceledOrders), ordersRemove(fullyExecutedOrders));
        }),
        catchError(error => {
          return Rx.of(ordersLoadFailure(error));
        }),
        takeUntil(
          actions$.pipe(filter(action => action.type === Connection.ActionTypes.DISCONNECT  
            || action.type === Connection.ActionTypes.CONNECTION_LOST))
        ));
    })
  );
};

export const submitForm: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === OrderForm.ActionTypes.ORDER_FORM_SUBMIT),
    map(action => {
      return { payload: action.payload, correlationId: action.correlationId, validations: action.validations };
    }),
    switchMap((action: { payload: OrderFormData; correlationId: string, validations: Validation }) => {
      const { payload, correlationId, validations } = action;
      
      return validationService.validate(payload, validations).pipe(
          map(() => {
            const request = createOrderRequest(payload, correlationId);
            if (payload.mode === OrderFormMode.ENTER) {
              return orderEnter(request);
    
            } else if (payload.mode === OrderFormMode.MODIFY) {
                return orderModify(request, payload.contractId);
            } else if (payload.mode === OrderFormMode.MATCH) {
              return orderEnter(request);
      
            } else if (payload.mode === OrderFormMode.CANCEL) {
              return orderCancel(request);
            }
            return Rx.empty();
          }),
          catchError(error =>  Rx.of(receiveMessage(correlationId, error, true)))
        );
    }),
  );
};

export const enterOrder: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.ENTER_ORDER),
    map(action => action.payload),
    switchMap((action: EnterOrderRequest) => {
      return ordersService
        .sendEnterOrder(action).pipe(
        map((content: any) => {
          return orderEnterComplete(content, action.correlationId, action);
        }),
        catchError(error => {
          return Rx.of(orderFailure(error, action.correlationId, action));
        }));
    })
  );
};

export const sendParseBulkOrderFile: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === BulkActions.PARSE_REQUEST),
    map(action => action.payload),
    switchMap((action: ParseBulkOrdersRequest) => {
      return ordersService
        .sendParseBulkOrdersFile(action).pipe(
          map((content: any) => {
            if (content.orders) {
              return parseSuccess(content);
            } else {
              return parseFailure(content.message);
            }
          }),
          catchError(error => {
            return Rx.of(parseFailure(error.message ? error.message : error));
          })
        );
    })
  );
};

export const modifyOrder: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.MODIFY_ORDER),
    map(action => action.payload),
    switchMap((action: ModifyOrderRequest) => {
      return ordersService
        .sendModifyOrder(action).pipe(
          map((content: any) => {
            return orderModifyComplete(content, action);
          }),
          catchError(error => {
            return Rx.of(orderFailure(error, action.correlationId, action));
          })
        );
    })
  );
};

export const cancelOrder: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.CANCEL_ORDER),
    map(action => action.payload),
    switchMap((action: CancelOrderRequest) => {
      return ordersService
        .sendCancelOrder(action).pipe(
        map((content: any) => {
          return orderCancelComplete(content, action);
        }),
        catchError(error => {
          return Rx.of(orderFailure(error, action.correlationId, action));
        }));
    })
  );
};

export const subscribeOrderbookPreviews: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === OrderForm.ActionTypes.ORDER_FORM_SUBSCRIBE_ORDERBOOKS),
    map(action => action.payload),
    switchMap((payload) => {
      
      ordersService.sendSubscribePreviewRequest({contractId: payload});
      
      return ordersService.subscribeOrderbookPreview()
      .pipe(
        mergeMap((content: any) => {
          const contract = getOrderbookContracts(orderBookStore.getState())[content.orderbook.contractId];
          return Rx.of(orderbookPreviewLoadSuccess(content, contract));
        }),
        catchError(() => {
          return Rx.empty();
        }),        
        takeUntil(
          actions$.pipe(filter(action => action.type === Connection.ActionTypes.DISCONNECT  
            || action.type === Connection.ActionTypes.CONNECTION_LOST 
            || action.type === OrderForm.ActionTypes.ORDER_FORM_UNSUBSCRIBE_ORDERBOOKS))
        ));
    }),
    catchError(error => {
      return Rx.of(receiveMessage('', error, true));
    })
  );
};

export const subscribeSanityCheck: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === OrderForm.ActionTypes.ORDER_FORM_SUBSCRIBE_ORDERBOOKS),
    map(action => action.payload),
    switchMap((payload) => {
      
      return ordersService.getSanityLimits({contractId: payload}).pipe(
        switchMap((content: any) => {
          return Rx.of(orderFormSanityCheck(content.sanityChecks));
        }),
        catchError(() => {
          return Rx.empty();
        }),        
        takeUntil(
          actions$.pipe(filter(action => action.type === Connection.ActionTypes.DISCONNECT  
            || action.type === Connection.ActionTypes.CONNECTION_LOST 
            || action.type === OrderForm.ActionTypes.ORDER_FORM_UNSUBSCRIBE_ORDERBOOKS))
        ));
    }),
    catchError(error => {
      return Rx.of(receiveMessage('', error, true));
    })
  );
};

export const unsubscribeOrderbookPreviews: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === OrderForm.ActionTypes.ORDER_FORM_UNSUBSCRIBE_ORDERBOOKS),
    map(action => action.payload),
    switchMap(() => {
      
      ordersService.sendUnsubscribePreviewRequest();
      return Rx.empty();
    })
  );
};

export const updatePreviewWithOrder: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === OrderForm.ActionTypes.UPDATE_PREVIEW_WITH_ORDER),
    map(action => action.payload),
    switchMap((action: {request: CancelOrderRequest | ModifyOrderRequest | EnterOrderRequest, mode: OrderFormMode}) => {
      ordersService.sendOrderToUpdatePreview(action);
      return Rx.empty();  
    })
  );
};

export const ordersEpic = combineEpics(
  connection,
  loadOrders,
  subscribeOrders,
  submitForm,
  enterOrder,
  modifyOrder,
  cancelOrder,
  sendParseBulkOrderFile,
  sendOrderSubscritpion,
  subscribeOrderbookPreviews,
  updatePreviewWithOrder,
  unsubscribeOrderbookPreviews,
  subscribeSanityCheck
);
