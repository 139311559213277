import { config } from '../../main/config';
import {
  ITable,
  ITableColumn,
  ITableSort,
  TableAction,
  ITableSearchTag,
  prepareColumns,
} from '../../shared/ui/models/table';
import { ComponentType } from '../../shared/ui/models/component';

export class OwnTradeTable implements ITable {
  static index = 1;

  id: string;
  parentId: string;
  type: ComponentType = ComponentType.Owntrade;
  actions: TableAction[] = [
    TableAction.SEARCH,
    TableAction.DOWNLOAD_CSV,
    TableAction.HIDDING_ROWS,
    TableAction.PINNING_ROWS,
    TableAction.LIMIT_TRADES
  ];
  columns: ITableColumn[] = [];
  sorting: ITableSort[];
  hiddenColumnNames: string[];
  hiddenRows: any[] = [];
  tags: ITableSearchTag[] = [];
  showHeadlines: boolean = true;
  showingHidden: boolean = false;
  filters = {};
  pinnedRows = [];
  externallyPaginated = true;
  
  constructor(parentId: string, customId?: string) {
    this.id = customId
      ? customId
      : new Date().getUTCMilliseconds().toString() + OwnTradeTable.index++;
    this.parentId = parentId;
    this.columns = config.ui.ownTradeTable.columns.map(c => { return {...c}; });

    if (customId) {
      this.hiddenColumnNames = prepareColumns(config.ui.ownTradeTable, this.columns, true);
      this.sorting = config.ui.ownTradeTable.recentDefaultSorting;
    } else {
      this.hiddenColumnNames = prepareColumns(config.ui.ownTradeTable, this.columns, false);
      this.sorting = config.ui.ownTradeTable.defaultSorting;
    }
  }
}
