import { IChartData, OhlcAggregate } from '../models/charts';
import { LogAction } from '../../main/middlewares/logger';
import { LogLevel } from '../../shared/logger/models/logger';
import { I18n } from 'react-redux-i18n';

export enum ActionTypes {
  LOAD_CHART_DATA_SUCCESS = '[Chart Data] Load success',
  LOAD_CHART_DATA_FAILURE = '[Chart Data] Load failure',
  SET_CHART_PERIOD = '[Chart Data] Period',
  UPDATE_CHART_DATA = '[Chart Data] Update chart data'
}

export interface ChartDataLoadActionSuccess extends LogAction {
  type: ActionTypes.LOAD_CHART_DATA_SUCCESS;
  payload: { [contractId: string]: IChartData };
  dockId: string;
}

export interface ChartDataLoadActionFailure extends LogAction {
  type: ActionTypes.LOAD_CHART_DATA_FAILURE;
  payload: string;
}

export interface ChartDataUpdateAction {
  type: ActionTypes.UPDATE_CHART_DATA;
  ohlc: number[];
  volume: number[];
  dockId: string;
  contract: string;
  period: number;
}

export type Action =
  | ChartDataLoadActionSuccess
  | ChartDataLoadActionFailure
  | ChartDataUpdateAction;

export function chartDataLoadSuccess(
  payload: { [contractId: string]: IChartData },
  dockId: string
): ChartDataLoadActionSuccess {
  return {
    type: ActionTypes.LOAD_CHART_DATA_SUCCESS,
    payload: payload,
    dockId: dockId,
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('charts.loadSuccess')
  };
}

export function chartDataLoadFailure(payload: string
): ChartDataLoadActionFailure {
  return {
    type: ActionTypes.LOAD_CHART_DATA_FAILURE,
    payload: payload,
    logLevel: LogLevel.ERROR,
    logMessage: I18n.t('charts.loadFailure')
  };
}

export function chartDataUpdate(aggregate: number[], volume: number[], dockId: string, contract: string, period: number): ChartDataUpdateAction {
  return {
    type: ActionTypes.UPDATE_CHART_DATA,
    ohlc: aggregate,
    volume: volume,
    dockId: dockId,
    contract: contract,
    period: period
  };
}
