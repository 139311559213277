import { Position, Size } from '../models/grid';
import { DockType } from '../../dock/models/dock';

export enum ColSizes {
  FULL = 24,
  TEN = 10,
  SIX = 6,
  FIVE = 5,
  FOUR = 4,
  TWO = 2
}

export class Grid {
  static widthOffset: number = 55;
  static heightOffset: number = 35;
  static cols: number = 24;
  static gutter: number = 15;
  static allowedSize: ColSizes;

  fullWidth: number;
  fullHeight: number;
  colWidth: number;
  minColWidth: number;
  maxColWidth: number;

  constructor(fullWidth?: number, fullHeight?: number) {
    this.fullWidth = (fullWidth) ? fullWidth : window.innerWidth - Grid.widthOffset;
    this.fullHeight = (fullHeight) ? fullHeight : window.innerHeight - Grid.heightOffset;
    this.colWidth = Math.round(this.fullWidth / Grid.cols);
    this.minColWidth = this.colWidth * ColSizes.FOUR;
    this.maxColWidth = this.fullWidth = this.colWidth * ColSizes.FULL;
  }

  getDockMinSize(): Size {
    return {
      width: this.colWidth * ColSizes.FOUR,
      height: 200
    };
  }

  getDockSizeByColSize(colSize: ColSizes): Size {
    return this.getDockSizeByColSizeAndHeight(colSize, 200);
  }

  getDockSizeByColSizeAndHeight(colSize: ColSizes, height: number): Size {
    return {
      width: this.colWidth * colSize,
      height: height
    };
  }

  getDockPosition(position: Position, size: Size): Position {
    const x =
      position.x >= 0
        ? position.x + size.width <= this.fullWidth
          ? position.x
          : this.fullWidth - size.width
        : 0;
    const y =
      position.y >= 0
        ? position.y + size.height <= this.fullHeight
          ? position.y
          : this.fullHeight - size.height
        : 0;

    return {
      x: x,
      y: y
    };
  }

  getDockWidth(colSize: ColSizes) {
    return this.colWidth * colSize;
  }

  convertDockSizeToPx(size: Size): Size {
    const width = Math.round((this.fullWidth / 100) * size.width);
    const height = Math.round((this.fullHeight / 100) * size.height);
    return {
      width: width,
      height: height,
      relative: false
    };
  }

  convertDockSizeToPercentage(size: Size): Size {
    const width = ((size.width / this.fullWidth) * 10000) / 100;
    const height = ((size.height / this.fullHeight) * 10000) / 100;
    return {
      width: width,
      height: height,
      relative: true
    };
  }

  convertDockPositionToPx(position: Position): Position {
    const x = Math.round((this.fullWidth / 100) * position.x);
    const y = Math.round((this.fullHeight / 100) * position.y);
    return { x: x, y: y };
  }
  convertDockPositionToPercentage(position: Position): Position {
    const x = ((position.x / this.fullWidth) * 10000) / 100;
    const y = ((position.y / this.fullHeight) * 10000) / 100;
    return { x: x, y: y };
  }

  recalculateDockHeightOnResize(position: Position, height: number): number {
    const maxY = Math.round(position.y + height);
    if (maxY > this.fullHeight) {
      const overlapY = maxY - this.fullHeight;
      return height - overlapY;
    } else {
      return height;
    }
  }
  
  recalculateDockWidthOnResize(position: Position, width: number, type: DockType): number {
    const minWidth = this.getDockSizeByColSize(
      type === DockType.Tabs || type === DockType.ChartMarket ? ColSizes.FOUR : ColSizes.TWO
    ).width;
    if (width < minWidth) {
      return minWidth;
    }
    const maxX = Math.round(position.x + width);
    if (maxX > this.fullWidth) {
      const overlapX = maxX - this.fullWidth;
      return width - overlapX;
    } else {
      return width;
    }
  }
}
