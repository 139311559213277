import { Action, ActionTypes } from '../actions/translations';
import { I18nState, i18nReducer, TranslationObjects } from 'react-redux-i18n';

export interface State extends I18nState {
    locale: string;
    locales: string[];
    translations: TranslationObjects;
}

export const initialState: State = {
    translations: {},
    locale: '',
    locales: []
};

export function reducer(state: State = initialState, action: Action) {
    switch (action.type) {
        case ActionTypes.LOAD_LOCALES: {
            return {
                ...state,
                locales: action.payload
            };
        }
        case ActionTypes.SET_APP_LOCALE:
        case ActionTypes.LOAD_TRANSLATIONS_SUCCESS: {
            return {
                ...state,
                ...i18nReducer(state, action)
            };
        }
        default: {
            return state;
        }
    }
}