import { State } from '../../main/reducers/rootReducer';
import { createSelector } from 'reselect';
import { PriceAlarm } from '../models/priceAlarms';
import { ITable } from '../../shared/ui/models/table';
import { sortTable } from '../../shared/utils/helper/sorter';
import { argsSelectorCreator } from '../../shared/utils/selectors/agrSelector';

const getPriceAlarmState = (state: State) => state.priceAlarms;

export const getPriceAlarmEntities = createSelector(
  getPriceAlarmState,
  s => s.priceAlarms
);

export const getAllPriceAlarms = createSelector(
  getPriceAlarmState,
  s => s.ids.map(id => s.priceAlarms[id])
);

export const getPriceAlarmTableRows = argsSelectorCreator(
  [getAllPriceAlarms, (state: State, priceAlarmTable: ITable | undefined) => priceAlarmTable],
  (priceAlarms, priceAlarmTable) => {
    if (priceAlarmTable) {
      const _priceAlarms = priceAlarms.map((priceAlarm: PriceAlarm) => {
      
        return priceAlarmTable.columns.reduce((result: any, col) => {
          return Object.assign(result, {
            [col.name]: priceAlarm[col.originalName]
          });
        }, { id: priceAlarm.id });
      });
      return sortTable(_priceAlarms, priceAlarmTable.sorting, priceAlarmTable.hiddenColumnNames, priceAlarmTable.cmpFunctions);
    }
    return [];
  }
);

export const isPriceAlarmFormVisible = createSelector(
  getPriceAlarmState,
  s => s.formVisible
);

export const getPriceAlarmFormData = createSelector(
  getPriceAlarmState,
  s => s.formData
);