import { LogAction } from '../../main/middlewares/logger';
import { LogLevel } from '../../shared/logger/models/logger';
import { I18n } from 'react-redux-i18n';
import { BasketOrder, BulkOrderParsingResponse } from '../models/model';

export enum ActionTypes {
    PARSE_REQUEST = '[Bulk Order Upload] Send parse request to server',
    PARSE_SUCCESS = '[Bulk Order Upload] Received parsing success',
    PARSE_FAILURE = '[Bulk Order Upload] Parsing failed',
    SELECT_UPLOADED_ORDER = '[Bulk Order Upload] Selected uploaded bulk order',
    SUBMIT_BULK_ORDERS = '[Bulk Order Upload] Submit bulk orders',
    BASKET_ORDER_SUCCESS = '[Bulk Order Submit] Basket order successfully entered.',
    BASKET_ORDER_BULK_SUCCESS = '[Bulk Order Submit] Basket orders bulk successfully entered.',
    BASKET_ORDER_FAILURE = '[Bulk Order Submit] Entering basket order failed.',
    BASKET_ORDER_BULK_FAILURE = '[Bulk Order Submit] Basket orders bulk failed.',
    CLEAR_BASKET_ORDERS = '[Bulk Order Clear] Clear basket orders.',
    MODIFY_UPLOADED_ORDER = '[Bulk Order Modify] Modify basket order',
    REQUEST_SAMPLE_BASKET_FILE = '[Bulk Order Sample File] Requesting sample file for basket order upload.',
    INVALID_SAMPLE_BASKET_FILE = '[Bulk Order Sample File] No or invalid sample file for basket order upload provided.',
    DOWNLOAD_SAMPLE_BASKET_FILE_FINISHED = '[Bulk Order Sample File] Downloading sample basket file finished.'
}

export interface ParseRequestAction extends LogAction {
    type: ActionTypes.PARSE_REQUEST;
    payload: {
        fileAsBinaryString: any;
        correlationId: string;
    };
}

export interface ParseSuccessAction extends LogAction {
    type: ActionTypes.PARSE_SUCCESS;
    orders: BasketOrder[];
    rowsWithError: { [row: number]: string[] };
}

export interface ParseFailureAction extends LogAction {
    type: ActionTypes.PARSE_FAILURE;
    payload: any;
}

export interface SelectBulkOrderAction extends LogAction {
    type: ActionTypes.SELECT_UPLOADED_ORDER;
    id: string;
    key: string;
}

export interface ModifyBulkOrderAction extends LogAction {
    type: ActionTypes.MODIFY_UPLOADED_ORDER;
    payload: BasketOrder;
}

export interface SubmitBulkOrdersAction extends LogAction {
    type: ActionTypes.SUBMIT_BULK_ORDERS;
    orders: BasketOrder[];
}

export interface BasketOrderSuccessAction extends LogAction {
    type: ActionTypes.BASKET_ORDER_SUCCESS;
    id: string;
    message: string;
}

export interface BasketOrderBulkSuccessAction extends LogAction {
    type: ActionTypes.BASKET_ORDER_BULK_SUCCESS;
    acks: {
        correlationId: string;
        message: string;
    }[];
}

export interface BasketOrderFailureAction extends LogAction {
    type: ActionTypes.BASKET_ORDER_FAILURE;
    id: string;
    message: string;
}

export interface BasketOrderBulkFailureAction extends LogAction {
    type: ActionTypes.BASKET_ORDER_BULK_FAILURE;
    acks: {
        correlationId: string;
        message: string;
    }[];
}

export interface BasketOrdersClearAction extends LogAction {
    type: ActionTypes.CLEAR_BASKET_ORDERS;
}

export interface RequestSampleBasketFileAction extends LogAction {
    type: ActionTypes.REQUEST_SAMPLE_BASKET_FILE;
}

export interface InvalidSampleBasketFileAction extends LogAction {
    type: ActionTypes.INVALID_SAMPLE_BASKET_FILE;
}

export interface FinishedDownloadSampleBasketFileAction extends LogAction {
    type: ActionTypes.DOWNLOAD_SAMPLE_BASKET_FILE_FINISHED;
}

export function parseBinaryStringFromFile(fileAsBinaryString: any, correlationId: string): ParseRequestAction {
    return {
        type: ActionTypes.PARSE_REQUEST,
        payload: {
            fileAsBinaryString: fileAsBinaryString,
            correlationId: correlationId
        },
        logLevel: LogLevel.INFO,
        logMessage: I18n.t('bulk.parsing'),
    };
}

export function parseFailure(payload: any): ParseFailureAction {
    return {
        type: ActionTypes.PARSE_FAILURE,
        payload: payload,
        logLevel: LogLevel.ERROR,
        logMessage: I18n.t('bulk.failure')
    };
}

export function parseSuccess(payload: BulkOrderParsingResponse): ParseSuccessAction {
    return {
        type: ActionTypes.PARSE_SUCCESS,
        orders: payload.orders,
        rowsWithError: payload.rowsWithError,
        logLevel: LogLevel.INFO,
        logMessage: I18n.t('bulk.uploaded')
    };
}

export function selectBulkOrder(correlationId: string, key: string): SelectBulkOrderAction {
        return {
            type: ActionTypes.SELECT_UPLOADED_ORDER,
            id: correlationId,
            key: key,
            logLevel: LogLevel.DEBUG,
            logMessage: I18n.t('bulk.select')
        };
}

export function basketOrderModify(payload: BasketOrder): ModifyBulkOrderAction {
    return {
        type: ActionTypes.MODIFY_UPLOADED_ORDER,
        payload: payload,
        logMessage: I18n.t('bulk.modify'),
        logLevel: LogLevel.DEBUG
    };
}

export function submitBulkOrders(orders: BasketOrder[]): SubmitBulkOrdersAction {
    return {
        type: ActionTypes.SUBMIT_BULK_ORDERS,
        orders: orders,
        logLevel: LogLevel.DEBUG,
        logMessage: I18n.t('bulk.submit')
    };
}

export function basketOrderSuccess(message: any): BasketOrderSuccessAction {
    return {
        type: ActionTypes.BASKET_ORDER_SUCCESS,
        message: message.message,
        id: message.correlationId,
        logLevel: LogLevel.INFO,
        logMessage: I18n.t('bulk.order.success', {count: 1})
    };
}

export function basketOrderBulkSuccess(messages: any[]): BasketOrderBulkSuccessAction {
    return {
        type: ActionTypes.BASKET_ORDER_BULK_SUCCESS,
        acks: messages,
        logLevel: LogLevel.INFO,
        logMessage: I18n.t('bulk.order.success', {count: messages.length})
    };
}

export function basketOrderFailure(message: any): BasketOrderFailureAction {
    return {
        type: ActionTypes.BASKET_ORDER_FAILURE,
        message: message.message,
        id: message.correlationId,
        logLevel: LogLevel.ERROR,
        logMessage: I18n.t('bulk.order.failure', {count: 1})
    };
}

export function basketOrderBulkFailure(messages: any[]): BasketOrderBulkFailureAction {
    return {
        type: ActionTypes.BASKET_ORDER_BULK_FAILURE,
        acks: messages,
        logLevel: LogLevel.ERROR,
        logMessage: I18n.t('bulk.order.failure', {count: messages.length})
    };
}

export function clearBasketOrders(): BasketOrdersClearAction {
    return {
        type: ActionTypes.CLEAR_BASKET_ORDERS,
        logLevel: LogLevel.DEBUG,
        logMessage: I18n.t('bulk.clearing')
    };
}

export function requestSampleBasketFile(): RequestSampleBasketFileAction {
    return {
        type: ActionTypes.REQUEST_SAMPLE_BASKET_FILE,
        logLevel: LogLevel.DEBUG,
        logMessage: I18n.t('bulk.requestSample')
    };
}

export function invalidSampleBasketFile(): InvalidSampleBasketFileAction {
    return {
        type: ActionTypes.INVALID_SAMPLE_BASKET_FILE,
        logLevel: LogLevel.ERROR,
        logMessage: I18n.t('bulk.invalidSample')
    };
}

export function finishedDownloadSampleBasketFile(): FinishedDownloadSampleBasketFileAction {
    return {
        type: ActionTypes.DOWNLOAD_SAMPLE_BASKET_FILE_FINISHED,
        logLevel: LogLevel.INFO,
        logMessage: I18n.t('bulk.finishedDownload')
    };
}

export type Action =
    | ParseRequestAction
    | ParseFailureAction
    | ParseSuccessAction
    | SelectBulkOrderAction
    | SubmitBulkOrdersAction
    | BasketOrderFailureAction
    | BasketOrderSuccessAction
    | BasketOrdersClearAction
    | ModifyBulkOrderAction
    | RequestSampleBasketFileAction
    | InvalidSampleBasketFileAction
    | FinishedDownloadSampleBasketFileAction
    | BasketOrderBulkFailureAction
    | BasketOrderBulkSuccessAction
;
