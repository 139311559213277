import { State } from '../../../main/reducers/rootReducer';
import { createSelector } from 'reselect';
import { getClientLogLevel } from '../../../requests/selectors/requests';
import { convertNotificationTypeToLogLevel } from '../../logger/helper/logger';

export const getNotificationsState = (state: State) => state.notifications;
export const getNotificationConfig = createSelector(
  getNotificationsState,
  s => s.config
);
export const getNotificationIds = createSelector(
  getNotificationsState,
  s => s.ids
);
export const getNotificationEntities = createSelector(
  getNotificationsState,
  s => s.entities
);

export const getAllNotifications = createSelector(
  getNotificationEntities,
  getNotificationIds,
  (entities, ids) => ids.map(id => entities[id])
);

export const getNotificationsForClientLevel = createSelector(
  [getAllNotifications, getClientLogLevel],
  (notifications, level) => notifications.filter(e => convertNotificationTypeToLogLevel(e.type) <= level)
);