import { StompService, StompClient } from '../../main/services/stompService';
import { Observable, of } from 'rxjs';
import { SubscribeChartDataRequest, UnsubscribeChartDataRequest, SubscribedChartsMovedRequest, InquireChartDataRequest } from '../models/charts';
import RestService from '../../main/services/restService';

export default class ChartService extends StompService {
  private restService: RestService;
  
  constructor() {
    super(StompClient);
    this.restService = new RestService();
  }

  public subscribeCharts(): Observable<any> {
    return this.subscribe('/user/topic/im/chart');
  }

  public subscribeChart(contractId: string, periodSeconds: number): Observable<any> {
    return this.subscribe('/user/topic/im/chart/partial/' + contractId.replace(/\s/g,'_') + '/' + periodSeconds );
  }

  public unsubscribeChartData(message: UnsubscribeChartDataRequest): Observable<any> {
    return of(this.sendMessage('/app/im/unsub/chart', JSON.stringify(message)));
  }

  public subscribeChartData(message: SubscribeChartDataRequest): Observable<any> {
    return of(this.sendMessage('/app/im/chart', JSON.stringify(message)));
  }

  public chartMoved(message: SubscribedChartsMovedRequest): Observable<any> {
    return of(this.sendMessage('/app/im/chart/moved', JSON.stringify(message)));
  }

  public subscribePartialUpdates(): Observable<any> {
    return this.subscribe('/user/topic/im/chart/partial');
  }

  public inquireChartData(request: InquireChartDataRequest): Observable<any> {
    return this.restService.restPost('/data/trades/chart', request);
  }

}