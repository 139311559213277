import * as Tab from '../actions/tab';
import { ITab } from '../models/tab';

export interface State {
  ids: string[];
  entities: { [id: string]: ITab };
}

export const initialState: State = {
  ids: [],
  entities: {}
};

export function reducer(state: State = initialState, action: Tab.Action) {
  switch (action.type) {
    case Tab.ActionTypes.LOAD: {
      const tabs = action.payload;
      const staticTabs = state.ids
        .map((id: string) => state.entities[id])
        .filter((tab: ITab) => tab.id.indexOf('recent-') === 0);

      const newTabEntities = tabs.concat(staticTabs).reduce(
        (entities: { [id: string]: ITab }, tab: ITab) => {
          return {
            ...entities,
            [tab.id]: tab
          };
        },
        {}
      );
      
      return {
        ...state,
        ids: Object.keys(newTabEntities),
        entities: newTabEntities
      };
    }

    case Tab.ActionTypes.CREATE: {
      const tab: ITab = action.payload;
      const newIds = [...state.ids, tab.id].filter(
        (v, i, a) => a.indexOf(v) === i  // only unique tab ids
      );
      const maxOrdering = state.ids.reduce((currentMax: number, id: string) => Math.max(currentMax, state.entities[id].meta.ordering), 0);
      return {
        ...state,
        ids: newIds,
        entities: {
          ...state.entities,
          [tab.id]: {
            ...tab,
            meta: {
              ...tab.meta,
              ordering: maxOrdering + 1
            }
          }
        }
      };
    }

    case Tab.ActionTypes.MOVE: {
      const { ids, toDockId } = action.payload;
      const movedEntities = ids.reduce(
        (entities: { [id: string]: ITab }, id: string) => {
          return {
            ...entities,
            [id]: {
              ...state.entities[id],
              dockId: toDockId
            }
          };
        },
        {}
      );

      return {
        ...state,
        entities: {
          ...state.entities,
          ...movedEntities
        }
      };
    }

    case Tab.ActionTypes.REMOVE: {
      const { id } = action.payload;
      let nextActiveTab: ITab | null = null;
      let toBeRemoved: ITab = state.entities[id];
      if (toBeRemoved.meta.isActive) {
        const tabsForDock = state.ids.map(key => state.entities[key]).filter(t => t.dockId === toBeRemoved.dockId);
        let indexCurrentTab = tabsForDock.findIndex(t => t.id === id);
        nextActiveTab = tabsForDock.length > 0 && indexCurrentTab > 0 ? tabsForDock[indexCurrentTab - 1] : tabsForDock[0];
        nextActiveTab.meta.isActive = true;
      }
      const newTabIds = state.ids.filter(tabId => tabId !== id);
      const newTabEntities = newTabIds.reduce(
        (entities: { [id: string]: ITab }, tabId: string) => {
          return {
            ...entities,
            [tabId]: !nextActiveTab || tabId !== nextActiveTab.id ? state.entities[tabId] : nextActiveTab
          };
        },
        {}
      );

      return {
        ...state,
        ids: newTabIds,
        entities: newTabEntities
      };
    }

    case Tab.ActionTypes.TRIGGER_FILTER: {
      const { id, displayFilter } = action.payload;
      const newEntities = {
        ...state.entities,
        [id]: {
          ...state.entities[id],
          meta: {
            ...state.entities[id].meta,
            isActive: state.entities[id].meta.isActive,
            displayFilter: displayFilter
          }
        }
      };
      return {
        ...state,
        entities: newEntities
      };
    }

    case Tab.ActionTypes.TRIGGER_SEARCH: {
      const { id, displaySearch } = action.payload;
      const newEntities = {
        ...state.entities,
        [id]: {
          ...state.entities[id],
          meta: {
            ...state.entities[id].meta,
            isActive: state.entities[id].meta.isActive,
            displaySearch: displaySearch
          }
        }
      };
      return {
        ...state,
        entities: newEntities
      };
    }

    case Tab.ActionTypes.CHANGE_TAB: {
      const { selectedTabId, unselectedTabId } = action.payload;
      
      const newEntities = Object.keys(state.entities).reduce(
        (acc: {[id: string]: any}, key: string) => {
          acc[key] = {
            ...state.entities[key],
            meta: {
              ...state.entities[key].meta,
              isActive: (state.entities[key].dockId !== state.entities[selectedTabId].dockId && state.entities[key].meta.isActive) 
                    || key === selectedTabId,
              displayFilter: state.entities[key].meta.displayFilter
            }
          };
          return acc;
        }, {}
      );

      return {
        ...state,
        entities: newEntities
      };
    }

    default:
      return state;
  }
}
