import { Action, ActionTypes } from '../actions/authentication';
import * as PasswordActions from '../../shared/passwordChange/actions/passwordChange';

export interface State {
  user: any;
  loginData: any;
  authorized: boolean;
  error: any;
  loginPending: boolean;
  permissions: string[];
  modificationAllowed: boolean;
  colorModificationAllowed: boolean;
  isTradeReportingUser: boolean;
  isProfileAdmin: boolean;
  isPasswordExpired: boolean;
  timeUntilNextLoginAttempt: number;
}

export const initialState: State = {
  user: null,
  loginData: null,
  authorized: false,
  error: null,
  loginPending: false,
  permissions: [],
  modificationAllowed: false,
  colorModificationAllowed: false,
  isTradeReportingUser: false,
  isProfileAdmin: false,
  isPasswordExpired: false,
  timeUntilNextLoginAttempt: 0
};

export function reducer(state: State = initialState, action: any) {
  switch (action.type) {
    case PasswordActions.ActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isPasswordExpired: false
      }; 
    }
    case ActionTypes.AUTHENTICATION_REQUEST: {
      return {
        ...state,
        loginData: {...action.payload, password: undefined},
        loginPending: true,
        isButtonTimeoutRunning: true
      };
    }

    case ActionTypes.AUTHENTICATION_SUCCESS: {
      const user = action.payload;
      return {
        ...state,
        user: user,
        authorized: true,
        error: null,
        loginPending: false,
        isButtonTimeoutRunning: false,
        permissions: action.payload.userPermissions.userPermissions,
        modificationAllowed: action.payload.modificationAllowed,
        colorModificationAllowed: action.payload.colorModificationAllowed,
        isTradeReportingUser: action.payload.tradeReportingUser,
        isProfileAdmin: action.payload.profileAdmin,
        isPasswordExpired: action.payload.passwordExpired,
        passwordComplexity: action.payload.passwordComplexity,
        passwordComplexityErrorMessage: action.payload.passwordComplexityErrorMessage
      };
    }

    case ActionTypes.AUTHENTICATION_FAILURE: {
      return {
        ...state,
        error: action.error,
        loginPending: false,
        isButtonTimeoutRunning: false,
        loginData: initialState.loginData
      };
    }

    case ActionTypes.LOGIN_DELAY: {
      return {
        ...state,
        timeUntilNextLoginAttempt: action.loginDelayTime,
        loginPending: action.loginDelayTime > 0,
        pending: false
      };
    }

    case ActionTypes.AUTHENTICATION_LOGOUT: {
      return {
        ...state,
        authorized: false,
        user: null,
        error: action.error,
        loginPending: false,
        isButtonTimeoutRunning: false
      };
    }

    case ActionTypes.REFRESH_TOKEN: {
      return {
        ...state,
        loginData: {...state.loginData, token: action.payload.jwt}
      };
    }

    case ActionTypes.IM_AUTHENTICATION_REQUEST: {
      return {
        ...state,
        loginData: action.payload
      };
    }

    default:
      return state;
  }
}
